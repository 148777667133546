<!-- FILTROS -->
<div class="filters">
  <div nz-col [nzSpan]="7">
    <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large" class="search-input">
      <input id="txtPesquisar"
             nz-input
             placeholder="Pesquisar..."
             [(ngModel)]="searchValue"
             (input)="getTasks()"/>
    </nz-input-group>
    <ng-template #prefixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
  </div>
  <div nz-col [nzSpan]="17">
    <div nz-row class="selects">
      <div nz-col [nzSpan]="5" class="optionSelects">
        <div class="select-title">Projeto</div>
        <nz-select nzAllowClear
                   [nzBorderless]="true"
                   name="project"
                   id="project"
                   [(ngModel)]="filterByProject"
                   (ngModelChange)="getTasks()"
                   [nzPlaceHolder]="'Todos'">
          <nz-option nzValue="{{ project.projectId }}" nzLabel="{{ project.projectName }}"
                     *ngFor="let project of filterByProjects"></nz-option>
        </nz-select>
      </div>

      <div nz-col [nzSpan]="5" class="optionSelects">
        <div class="select-title">{{ 'licensing-process.title' | translate }}</div>
        <nz-select nzAllowClear
                   [nzBorderless]="true"
                   name="licensing"
                   id="licensing"
                   class="licensing"
                   [(ngModel)]="filterByLicensing"
                   (ngModelChange)="getTasks()"
                   [nzPlaceHolder]="'Todos'">
          <nz-option nzValue="{{ licence.projectLicenceId }}" nzLabel="{{ licence.licenceName }}"
                     *ngFor="let licence of filterByLicensings"></nz-option>
        </nz-select>
      </div>

      <div nz-col [nzSpan]="5" class="optionSelects">
        <div class="select-title">Prioridade</div>
        <nz-select [nzAllowClear]="true"
                   [nzBorderless]="true"
                   [(ngModel)]="filterByPriority"
                   name="priority"
                   id="priority"
                   class="priority"
                   (ngModelChange)="getTasks()"
                   *ngIf="priorityList"
                   [nzPlaceHolder]="'Todas'">
          <nz-option nzValue="{{ priority.value }}" nzLabel="{{ priority.name }}"
                     *ngFor="let priority of priorityList"></nz-option>
        </nz-select>
      </div>

      <div nz-col [nzSpan]="6" class="optionSelects">
        <div class="select-title">Data/Período</div>
        <nz-range-picker id="datePickerDeadline"
                         #datePickerFilter
                         class="formatDatePickerRange"
                         [(ngModel)]="filterByDate"
                         (ngModelChange)="filterDateOnChange(datePickerFilter)"

                         [nzFormat]="dateFormat"></nz-range-picker>

      </div>
    </div>
  </div>
</div>

<div class="content-wrapper">
  <nz-collapse nzGhost>
    <!-- CONTEÚDO EM EXECUÇÃO -->
    <nz-collapse-panel [nzActive]="getActiveTask('section')" [nzHeader]="panelTitle" [nzDisabled]="false">
      <ng-template #panelTitle>
        <div nz-row>
          <div nz-col nzFlex="auto">
            <span class="tituloSecao">Em execução</span>
            <span class="totalTarefas">{{ totalTasksEXE }} tarefa{{ totalTasksEXE !== 1 ? 's' : '' }}</span>
          </div>
        </div>
      </ng-template>
      <div nz-row style="width: 98%; margin-left: 20px">
        <div nz-col nzSpan="24" class="textBody pr-10">
          <div nz-row class="columnsTable">
            <div nz-col nzSpan="9">
              <p class="subtitle">Título da tarefa</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">Projeto</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">{{ 'licensing-process.title' | translate }}</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Prazo</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Status</p>
            </div>
            <div nz-col nzSpan="1">
              <p class="subtitle">Prioridade</p>
            </div>
          </div>

          <nz-divider class="divider-item"></nz-divider>
          <ng-container *ngIf="taskListEXE">
            <div nz-row class="columnsTableData" *ngFor="let task of taskListEXE; let i = index">
              <div nz-col nzSpan="9">
                <button
                  (click)="goToActivity(task.url)"
                  [disabled]="!task.url"
                  nzTooltipTitle="{{replaceItalicTag(sanitizeInputAllowOnlyItalicTag(task.description))}}"
                  nzTooltipPlacement="bottomLeft"
                  nz-button
                  nz-tooltip
                  [ngClass]="'popoverFormat'"
                  [innerHTML]="checkLengthString(sanitizeInputAllowOnlyItalicTag(task.description))"
                  style="width: 95%; text-align: left; overflow-x: hidden; cursor: pointer;"
                  class="statusSection">
                </button>
                <span *ngIf="sanitizeInputAllowOnlyItalicTag(task.description).length > 108"
                      style="cursor: none;">...</span>
              </div>

              <div nz-col nzSpan="4">
                <p class="projectSection">
                  {{ task.projectName }}
                </p>
              </div>
              <div nz-col nzSpan="4">
                <p class="statusSection">
                  <app-short-text-hint [originalText]="task.licenceName" [maxChars]="40"></app-short-text-hint>
                </p>
              </div>
              <div nz-col nzSpan="3">
                <p class="statusSection">
                  <nz-date-picker class="formatDatePicker" nzMode="date" nzFormat="dd/MM/yyyy" [nzAllowClear]="false"
                                  nzSuffixIcon
                                  [(ngModel)]="task.deadline" (ngModelChange)="onChangeDeadline($event, task)"
                                  [ngClass]="
                    task.dateToShowColor >= 1 && task.dateToShowColor < 6
                      ? 'colorYellow'
                      : null || task.dateToShowColor < 1
                      ? 'colorRed'
                      : null
                  "></nz-date-picker>
                </p>
              </div>

              <div nz-col nzSpan="3">
                <button class="button" id="popover-{{ task.id }}" nz-popover nzPopoverTrigger="click"
                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottom" [ngClass]="
                  task.status === 'EXE' || task.status === 'AND'
                    ? 'primary'
                    : '' || task.status === 'AFA'
                    ? 'gray'
                    : '' || task.status === 'CON'
                    ? 'success'
                    : ''
                " [nzPopoverOverlayStyle]="customStyle">
                  {{ task.status === "AFA" ? "A fazer" : null }}
                  {{
                    task.status === "EXE" || task.status === "AND"
                      ? "Em execução"
                      : null
                  }}
                  {{ task.status === "CON" ? "Concluido" : null }}
                </button>
                <ng-template #contentTemplate>
                  <div class="quadroBotoes">
                    <div class="botoesPopover">
                      <button (click)="alteraStatusTask(task, 'AFA')" class="botaoPopoverFazer" nz-button>
                        A fazer
                      </button>
                      <button (click)="alteraStatusTask(task, 'CON')" class="botaoPopoverConcluido" nz-button>
                        Concluído
                      </button>
                      <button (click)="alteraStatusTask(task, 'EXE')" class="botaoPopoverExecucao" nz-button>
                        Em execução
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div nz-col nzSpan="1" style="text-align: center">
                <p class="statusSection" (click)="priorityChange(task)" style="cursor: pointer">
                  <svg *ngIf="!task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#D9D9D9"/>
                  </svg>

                  <svg *ngIf="task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20"
                       fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#007E7A"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#007E7A"/>
                  </svg>
                </p>
              </div>

              <nz-divider class="divider-item"></nz-divider>
            </div>
          </ng-container>
        </div>
      </div>
    </nz-collapse-panel>

    <!-- CONTEUDO EM A FAZER -->
    <nz-collapse-panel [nzActive]="getActiveTask('section')" [nzHeader]="panelTitleAFA" [nzDisabled]="false">
      <ng-template #panelTitleAFA>
        <div nz-row style="width: 100%" class="sectionTitle">
          <div nz-col nzSpan="12" style="margin-top: -2px">
            <span class="tituloSecao">A fazer</span>
            <span class="totalTarefas">{{ totalTasksAFA }} tarefa{{ totalTasksAFA !== 1 ? 's' : '' }}</span>
          </div>
        </div>
      </ng-template>
      <div nz-row style="width: 98%; margin-left: 20px">
        <div nz-col nzSpan="24" class="textBody pr-10">
          <div nz-row class="columnsTable">
            <div nz-col nzSpan="9">
              <p class="subtitle">Título da tarefa</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">Projeto</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">{{ 'licensing-process.title' | translate }}</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Prazo</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Status</p>
            </div>
            <div nz-col nzSpan="1">
              <p class="subtitle" style="margin-right: 5px">Prioridade</p>
            </div>
          </div>

          <nz-divider class="divider-item"></nz-divider>
          <ng-container *ngIf="taskListAFA && taskListAFA.length > 0">
            <div nz-row class="columnsTableData" *ngFor="let task of taskListAFA; let i = index">
              <div nz-col nzSpan="9" style="font-weight: bold;  overflow-x: hidden;width: 90%;padding-right: 10px;">
                <button
                  (click)="goToActivity(task.url)"
                  [disabled]="!task.url"
                  nzTooltipPlacement="bottomLeft" nzType="primary" nz-button nz-tooltip
                  nzTooltipTitle="{{replaceItalicTag(sanitizeInputAllowOnlyItalicTag(task.description))}}"
                  [ngClass]="'popoverFormat'"
                  class="statusSection"
                  [innerHTML]="checkLengthString(sanitizeInputAllowOnlyItalicTag(task.description))"
                  style="width: 95%; text-align: left; overflow-x: hidden;cursor:pointer;">
                </button>
                <span *ngIf="checkWindowResolution(sanitizeInputAllowOnlyItalicTag(task.description))"
                      style="cursor: none;">...</span>
              </div>

              <div nz-col nzSpan="4">
                <p class="projectSection">
                  {{ task.projectName }}
                </p>
              </div>
              <div nz-col nzSpan="4">
                <p class="statusSection">
                  <app-short-text-hint [originalText]="task.licenceName" [maxChars]="40"></app-short-text-hint>
                </p>
              </div>
              <div nz-col nzSpan="3">
                <p class="statusSection">
                  <nz-date-picker class="formatDatePicker" nzMode="date" nzShowTime="false" nzFormat="dd/MM/yyyy"
                                  [nzAllowClear]="false"
                                  nzSuffixIcon [(ngModel)]="task.deadlinetToShow"
                                  (ngModelChange)="onChangeDeadline($event, task)"
                                  [ngClass]="
                    task.dateToShowColor >= 1 && task.dateToShowColor < 6
                      ? 'colorYellow'
                      : null || task.dateToShowColor < 1
                      ? 'colorRed'
                      : null
                  "></nz-date-picker>
                </p>
              </div>

              <div nz-col nzSpan="3">
                <button class="button" id="popover-{{ task.id }}" nz-popover nzPopoverTrigger="click"
                        [nzPopoverContent]="contentTemplate" [nzPopoverOverlayStyle]="customStyle"
                        nzPopoverPlacement="bottom"
                        [ngClass]="
                  task.status === 'EXE' || task.status === 'AND'
                    ? 'primary'
                    : '' || task.status === 'AFA' || task.status === 'INI'
                    ? 'gray'
                    : '' || task.status === 'CON'
                    ? 'success'
                    : ''
                ">
                  {{
                    task.status === "AFA" || task.status === "INI"
                      ? "A fazer"
                      : null
                  }}
                  {{
                    task.status === "EXE" || task.status === "AND"
                      ? "Em execução"
                      : null
                  }}
                  {{ task.status === "CON" ? "Concluido" : null }}
                </button>

                <ng-template #contentTemplate>
                  <div class="quadroBotoes">
                    <div class="botoesPopover">
                      <button (click)="alteraStatusTask(task, 'AFA')" class="botaoPopoverFazer" nz-button>
                        A fazer
                      </button>
                      <button (click)="alteraStatusTask(task, 'CON')" class="botaoPopoverConcluido" nz-button>
                        Concluído
                      </button>
                      <button (click)="alteraStatusTask(task, 'EXE')" class="botaoPopoverExecucao" nz-button>
                        Em execução
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div nz-col nzSpan="1" style="text-align: center">
                <p class="statusSection" (click)="priorityChange(task)" style="cursor: pointer">
                  <svg *ngIf="!task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#D9D9D9"/>
                  </svg>

                  <svg *ngIf="task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20"
                       fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#007E7A"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#007E7A"/>
                  </svg>
                </p>
              </div>

              <nz-divider class="divider-item"></nz-divider>
            </div>
          </ng-container>
        </div>
      </div>
    </nz-collapse-panel>

    <!-- CONTEÚDO EM CONCLUÍDO -->
    <nz-collapse-panel [nzActive]="getActiveTask('section')" [nzHeader]="panelTitleCON" [nzDisabled]="false">
      <ng-template #panelTitleCON>
        <div nz-row style="width: 100%" class="sectionTitle">
          <div nz-col nzSpan="12" style="margin-top: -2px">
            <span class="tituloSecao">Concluído</span>
            <span class="totalTarefas">{{ totalTasksCON }} tarefa{{ totalTasksCON !== 1 ? 's' : '' }}</span>
          </div>
        </div>
      </ng-template>
      <div nz-row style="width: 98%; margin-left: 20px">
        <div nz-col nzSpan="24" class="textBody pr-10">
          <div nz-row class="columnsTable">
            <div nz-col nzSpan="9">
              <p class="subtitle">Título da tarefa</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">Projeto</p>
            </div>
            <div nz-col nzSpan="4">
              <p class="subtitle">{{ 'licensing-process.title' | translate }}</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Prazo</p>
            </div>
            <div nz-col nzSpan="3">
              <p class="subtitle">Status</p>
            </div>
            <div nz-col nzSpan="1">
              <p class="subtitle" style="margin-right: 5px">Prioridade</p>
            </div>
          </div>

          <nz-divider class="divider-item"></nz-divider>
          <ng-container *ngIf="taskListCON">
            <div nz-row class="columnsTableData" *ngFor="let task of taskListCON; let i = index">
              <div nz-col nzSpan="9" style="font-weight: bold;  overflow-x: hidden;width: 90%;padding-right: 10px;">
                <button
                  (click)="goToActivity(task.url)"
                  [disabled]="!task.url"
                  nzTooltipTitle="{{replaceItalicTag(sanitizeInputAllowOnlyItalicTag(task.description))}}"
                  nzTooltipPlacement="bottomLeft" nzType="primary" nz-button nz-tooltip
                  [ngClass]="'popoverFormat'"
                  class="statusSection"
                  [innerHTML]="checkLengthString(sanitizeInputAllowOnlyItalicTag(task.description))"
                  style="width: 95%; text-align: left; overflow-x: hidden; cursor:pointer;">
                </button>
                <span *ngIf="sanitizeInputAllowOnlyItalicTag(task.description).length > 108"
                      style="cursor: none;">...</span>
              </div>

              <div nz-col nzSpan="4">
                <p class="projectSection">
                  {{ task.projectName }}
                </p>
              </div>
              <div nz-col nzSpan="4">
                <p class="statusSection">
                  <app-short-text-hint [originalText]="task.licenceName" [maxChars]="40"></app-short-text-hint>
                </p>
              </div>
              <div nz-col nzSpan="3">
                <p class="statusSection">
                  <nz-date-picker class="formatDatePicker" nzMode="date" nzFormat="dd/MM/yyyy" [nzAllowClear]="false"
                                  nzSuffixIcon
                                  [(ngModel)]="task.deadline"
                                  (ngModelChange)="onChangeDeadline($event, task)"></nz-date-picker>
                </p>
              </div>

              <div nz-col nzSpan="3">
                <button class="button" id="popover-{{ task.id }}" nz-popover nzPopoverTrigger="click"
                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottom" [ngClass]="
                  task.status === 'EXE' || task.status === 'AND'
                    ? 'primary'
                    : '' || task.status === 'AFA'
                    ? 'gray'
                    : '' || task.status === 'CON'
                    ? 'success'
                    : ''
                ">
                  {{ task.status === "AFA" ? "A fazer" : null }}
                  {{
                    task.status === "EXE" || task.status === "AND"
                      ? "Em execução"
                      : null
                  }}
                  {{ task.status === "CON" ? "Concluido" : null }}
                </button>
                <ng-template #contentTemplate>
                  <div class="quadroBotoes">
                    <div class="botoesPopover">
                      <button (click)="alteraStatusTask(task, 'AFA')" class="botaoPopoverFazer" nz-button>
                        A fazer
                      </button>
                      <button (click)="alteraStatusTask(task, 'CON')" class="botaoPopoverConcluido" nz-button>
                        Concluído
                      </button>
                      <button (click)="alteraStatusTask(task, 'EXE')" class="botaoPopoverExecucao" nz-button>
                        Em execução
                      </button>
                    </div>
                  </div>
                </ng-template>
              </div>

              <div nz-col nzSpan="1" style="text-align: center">
                <p class="statusSection" (click)="priorityChange(task)" style="cursor: pointer">
                  <svg *ngIf="!task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#D9D9D9"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#D9D9D9"/>
                  </svg>

                  <svg *ngIf="task.priority" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                       viewBox="0 0 20 20"
                       fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4 4C4 3.44772 4.44772 3 5 3H16C16.3635 3 16.6984 3.19728 16.8746 3.51523C17.0509 3.83319 17.0407 4.22173 16.848 4.53L14.6792 8L16.848 11.47C17.0407 11.7783 17.0509 12.1668 16.8746 12.4848C16.6984 12.8027 16.3635 13 16 13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H14.1958L12.652 8.53C12.4493 8.20573 12.4493 7.79427 12.652 7.47L14.1958 5H5C4.44772 5 4 4.55228 4 4Z"
                          fill="#007E7A"/>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5 2C5.55228 2 6 2.44772 6 3V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V3C4 2.44772 4.44772 2 5 2Z"
                          fill="#007E7A"/>
                  </svg>
                </p>
              </div>

              <nz-divider class="divider-item"></nz-divider>
            </div>
          </ng-container>
        </div>
      </div>
    </nz-collapse-panel>

  </nz-collapse>
</div>

<!-- REGISTRAR NOVA TAREFA -->
<div class="legend">
  <button id="btnNovatask" (click)="showAddTaskModal()" class="btn-sl btn-sl-green btn-demand">
    <span>+ Nova tarefa</span>
  </button>
</div>
