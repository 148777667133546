<div class="shadow">
  <div nz-row class="menu-container ">
    <div nz-col class="left-menu">
      <ul nz-menu nzMode="horizontal" class="menu-nlp">
        <li nz-menu-item
            routerLinkActive="nzSelected"
            routerLink="termo-referencia"
            nzMatchRouter>

          <svg width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 9H15M19 5V20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H6C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20V4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3H17L19 5ZM17 3V5H19L17 3ZM15 17H9H15ZM9 13H15H9Z"
              stroke="currentColor"
              stroke-linejoin="round"/>
          </svg>

          Termo de Referência
        </li>

        <nz-divider nzType="vertical"></nz-divider>
        <li nz-menu-item
            routerLinkActive="nzSelected"
            routerLink="analise-resultados"
            nzMatchRouter>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               fill="none"
               stroke="currentColor"
               stroke-width="2"
               stroke-linejoin="round">
            <path d="M6 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-3" stroke-linecap="round"/>
            <path
              d="M15 10v5-5zm-4 5v-2 2zM1 13l2.83-2.83L1 13zm5-8a3 3 0 0 0-2.772 1.852 3 3 0 0 0 .65 3.269 3 3 0 0 0 3.269.65A3 3 0 0 0 9 8a3 3 0 0 0-3-3z"/>
          </svg>
          Análise dos Resultados
        </li>
        <nz-divider nzType="vertical"></nz-divider>
        <li nz-menu-item
            routerLinkActive="nzSelected"
            routerLink="relatorio"
            nzMatchRouter>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               fill="#969696"
               fill-rule="evenodd"
               class="nlp-report-icon" >
            <path
              d="M5 1a1 1 0 0 1 1-1h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-3a1 1 0 1 1 2 0v3h12V2H6a1 1 0 0 1-1-1zm3 14a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1zm0-5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1zm2-5a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1zm-2.393.205a1 1 0 0 1 .188 1.402l-3.82 5a1 1 0 0 1-1.548.05l-2.18-2.5a1 1 0 0 1 1.507-1.315L3.129 9.42l3.077-4.027a1 1 0 0 1 1.402-.188z"/>
          </svg>
          Relatório
        </li>
      </ul>
    </div>
    <div nz-col class="right-menu">
      <ul nz-menu nzMode="horizontal" class="menu-nlp mr-10">
        <li
          *ngIf="showRefreshButton"
          class="mr-10"
          (click)="getNlpResultStatus()"
          nz-tooltip
          nzTooltipTitle="{{ getStatus(resultNlp?.status) | translate  }}"
          nzTooltipPlacement="bottom">
          <span
            *ngIf="resultNlp?.analysisDate"
            class="mr-10">
            Atualizado em {{ this.updateDateTime | date: 'dd/MM/yyyy HH:mm'  }} - {{ getStatus(resultNlp?.status) | translate }}
          </span>
          <img src="assets/icons/icon_update.svg" alt="Atualizar Nlp">
        </li>
        <li (click)="openNlpSideBar()">
          <img src="assets/icons/icon_caution_circle.svg" alt="Informações" style="transform: rotate(180deg)">
        </li>
      </ul>
    </div>
  </div>
</div>
