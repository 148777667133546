import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { CommunicationsListComponent } from './communications-list/communications-list.component';
import { CommunicationsFormComponent } from './communications-form/communications-form.component';

const routes: Routes = [
  { path: '', component: CommunicationsListComponent },
  { path: 'new', component: CommunicationsFormComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommunicationsRoutingModule { }
