<div class="app-modal-cancel-process">
    <div class="content">
        <div class="content__img">
            <img class="plus__ico" src="/assets/icons/icon_caution_circle_warning_red.svg" alt="" />
            <h2> 
                {{ "projects.licensing-process.modal-cancel.title" | translate }}
            </h2>
        </div>

        <div class="content__text">
            <h3>
                {{ "projects.licensing-process.modal-cancel.subtitle" | translate }}
            </h3>
       
            <p>
                {{ "projects.licensing-process.modal-cancel.text-info" | translate }}
            </p>
        </div>

        <div class="content__buttons">
            <app-button-icon-secondary
                customClassBtn="padding: 0 20px; margin-right: 15px;"
                [id]="'btn-icon-secondary'"
                [text]="'projects.licensing-process.modal-cancel.button-cancel' | translate"
                altText="Não"
                (click)="onCancel()">
            </app-button-icon-secondary>

            <app-button-icon-primary
                customClassBtn="padding: 0 20px;"
                [id]="'btn-icon'"
                [text]="'projects.licensing-process.modal-cancel.button-ok' | translate"
                altText="Sim, cancelar"
                (click)="onHidenBanner()">
            </app-button-icon-primary>
        </div>
       
    </div>
</div>
