<div nz-row>
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
    class="pl-20 pt-20"
  >
    <app-documents-list></app-documents-list>
  </div>
</div>
