import {Component, Input} from '@angular/core';
import {ProjectSuspension} from "../../../../../shared/dto/project-suspension.dto";

@Component({
  selector: 'app-env-licensing-opinion-summary',
  templateUrl: './env-licensing-opinion-summary.component.html',
  styleUrls: ['./env-licensing-opinion-summary.component.scss']
})
export class EnvLicensingOpinionSummaryComponent {
  @Input() suspension!: ProjectSuspension;

  constructor() { }

  getTitle() {
    return this.suspension.type === 'SUSPENSION' ? 'projects.suspension-cancellation.env-licensing-opinion.title-suspension' : 'projects.suspension-cancellation.env-licensing-opinion.title-cancellation';
  }
}
