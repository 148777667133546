export const PROJECT_SUCCESS_SAVE = 'Salvo com sucesso!';
export const PROJECT_ERROR_SAVE = 'Erro ao salvar projeto:';
export const PROJECT_PHASE_SUCCESS_UPDATE = 'Fase do projeto atualizada com sucesso!';
export const GENERIC_ERROR = "Ocorreu um erro inesperado";

//Constantes para salvar na tabela de Anexos
export const PROJECT_ENTITY_NAME = 'PROJECT';
export const SUB_PROJECT_ENTITY_NAME = 'SUB_PROJECT';
export const SUB_PROJECT_GEO = 'SUB_PROJECT_GEO';
export const SUB_PROJECT_PARECER_FINAL = 'PARECER_FINAL';
export const PROJECT_ASSESSMENT_ENTITY_NAME = 'DISC_ASSESSMENT';
export const PRODUCT_DEMAND_PROTOCOL_ENTITY_NAME = 'PROD_CONSOLI';
export const PRODUCT_DEMAND_REVIEW_ENTITY_NAME = 'PROD_DEMAND_REVIEW';
export const PRODUCT_DEMAND_ENTITY_NAME = 'PRODUCT_DEMAND';
export const TEMP_PROJECT_ENTITY_NAME = 'PROJECT_TEMP';
export const FINAL_ANALYSIS_ENTITY_NAME = 'FINAL_ANALYSIS';
export const SCOPE_CHANGE_ENTITY_NAME = 'SCOPE_CHANGE';
export const SCOPE_CHANGE_FINAL_ENTITY_NAME = 'SCOPE_CHANGE_FINAL';

export type ParamDemandEiaModalFlow = 'NEW' |  'EDIT' | 'DELETE'
export type ParamLicensingModelCreate = 'NEW' | 'EDIT';
export const DEMAND_ENTITY_NAME = 'DEMAND';
export const DEMAND_ENTITY_PROTOCOL = 'DEMAND_PROTOCOL';
export const DEMAND_TASK_ENTITY_NAME = 'DEMAND_TASK';
export const ACTION_PHASE_ENTITY_NAME = 'DEMAND_ACTION_PHASE';

export const PROJECT_LICENSING_ENTITY_NAME = "PROJ_LICENSING";

export const ACTIVITY_ENTITY_NAME = "PROCESS_ACTIVITY";
export const ACTIVITY_ENTITY = "ACTIVITY";
export const COMMUNICATION_ENTITY_NAME = "COMMUNICATION";

export const STATUS_WAITING_REVIEW = "Aguardando Avaliação";
export const STATUS_IN_REVIEW = "Em revisão";
export const STATUS_FINISHED = "Concluído";
export const PROJECT_LICENSING_NOTIFICATION_ENTITY_NAME = 'PROJ_LIC_RETURN';
export const PROJECT_LICENSING_RETURN_REJECTED = "LIC_RETURN_REJECT"
export const PROJECT_LICENSING_RETURN_SUSPENDED = "LIC_RETURN_SUSP"
export const PROJECT_LICENSING_RETURN_ACCEPTED = "LIC_RETURN_ACCEPTED"
export const PROJECT_LICENSING_RETURN_COMPL_INFO = "LIC_RETURN_COMPL_INFO"
export const DEMAND_TASK_EVIDENCE = "DEMAND_TASK_EVIDENCE";
