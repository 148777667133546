import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { DocumentItemOrAttachments, DocumentstListItemModel } from '../../../../shared/models/documents-list-item.model';
import { DOCUMENTS_MOCK } from '../mock/documents';
import { DocumentListService } from '../services/documents-list.service';
import {UtilService} from "../../../../shared/util.service";

export interface Breadcrumb {
  label: string;
  id: number;
  userid: number;
}

@Component({
  selector: 'app-documents-table-grouped',
  templateUrl: './documents-table-grouped.component.html',
  styleUrls: ['./documents-table-grouped.component.scss'],
})
export class DocumentsTableGroupComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Output() public checkClick = new EventEmitter<Breadcrumb>();

  listingdata: Array<any> = [];

  value!: string;

  dataList: DocumentstListItemModel[] = [];
  documentsList: DocumentstListItemModel[] = [];

  groupedTable: boolean = false;
  groupedBy: string = '';
  dataTableGroup: DocumentstListItemModel[] = [];

  checked = false;
  favoriteDocuments: boolean = false;
  dataMock = DOCUMENTS_MOCK;

  listOfData: DocumentstListItemModel[] = [];
  setOfCheckedId = new Set<number>();
  listOfCurrentPageData: readonly DocumentstListItemModel[] = [];

  listOfColumn = [
    {
      title: 'Projeto',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.name.localeCompare(b.name),
      priority: true,
    },
    {
      title: 'Complexo',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.complexName.localeCompare(b.complexName),
      priority: 3,
    },
    {
      title: 'Data/Hora da última atualização',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.updateDate.localeCompare(b.updateDate),
      priority: 2,
    },
    {
      title: 'Data/Hora da criação',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.creationDate.localeCompare(b.creationDate),
      priority: 1,
    },
    {
      title: 'Responsável',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.user.name.localeCompare(b.user.name),
      priority: 1,
    },
  ];

  breadcrum: Breadcrumb[] = [{ label: 'Todos', id: 0, userid: 0 }];

  userLogged: number;

  constructor(private service: DocumentListService, private readonly util: UtilService) {}

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange($event: readonly DocumentstListItemModel[]): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id)
    );
  }

  ngOnInit(): void {
    //RECUPERA ID DO USUARIO LOGADO
    this.favoriteDocuments = this.util.getAuthorization("insert", "DOCUMENTS")
    let userLogged = sessionStorage.getItem("loggedUser")
    this.userLogged = userLogged ?  JSON.parse(userLogged).id : null;
    this.listOfData = this.data;
  }

  ngOnChanges() {
    this.listOfData = this.data;
  }

  filterByGroupElements() {
    const groupedArray = this.groupBy(this.dataList, this.groupedBy);
    this.dataTableGroup = groupedArray;
    this.groupedTable = this.groupedBy != '' ? true : false;
  }

  groupBy(array, key) {
    return array.reduce((hash, obj) => {
      if (key === 'user') {
        return Object.assign(hash, {
          [obj.user.name]: (hash[obj.user.name] || []).concat(obj),
        });
      } else {
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }
    }, {});
  }

  searchFilter(): void {
    this.dataList = this.documentsList.filter(
      (element) =>
        element.name?.toLowerCase().includes(this.value.toLowerCase()) ||
        element.complexName?.toLowerCase().includes(this.value.toLowerCase()) ||
        element.user?.name.toLowerCase().includes(this.value.toLowerCase()) ||
        element.creationDate
          ?.toLowerCase()
          .includes(this.value.toLowerCase()) ||
        element.updateDate?.toLowerCase().includes(this.value.toLowerCase())
    );
  }

  getEnterFolder(pasta: DocumentItemOrAttachments | Breadcrumb) {
    this.service.getDocumentsListNivel(pasta?.id, this.userLogged).subscribe((data) => {
      this.groupedTable = false;
      let bread: Breadcrumb = {
        id: data.id,
        label: data.name,
        userid: data?.user?.id,
      };
      this.checkClick.emit(bread);
    });
  }
}
