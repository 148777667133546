import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd/modal';
import {
  NlpModalNewSectionTermReferenceComponent
} from './nlp-modal-new-section-term-reference/nlp-modal-new-section-term-reference.component';
import {
  NlpModalSelectTermReferenceComponent
} from './nlp-modal-select-term-reference/nlp-modal-select-term-reference.component';
import {UserService} from 'src/app/shared/services/user.service';
import {Router} from '@angular/router';
import {take} from "rxjs";
import {ProductNlpService} from "../services/product-nlp.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {BreadCumbNlpService} from "../services/bread-cumb-nlp.service";

@Component({
  selector: 'app-nlp-reference-term',
  templateUrl: './nlp-reference-term.component.html',
  styleUrls: ['./nlp-reference-term.component.scss'],
})
export class NlpReferenceTermComponent implements OnInit {
  flagDisableTRButtons: boolean = true;
  modalClosedSuccessfully = false;
  flagNeedToCreateDocReferenceTerm = true;

  projectName: string | null = '';
  projectId: string | null = '';
  licenseId: string | null = '';
  licenseName: string | null = '';
  demandId: string | null = '';
  productNlpId: string | null = '';

  constructor(
    private userService: UserService,
    private modalService: NzModalService,
    private readonly viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private productNlpService: ProductNlpService,
    private readonly msg: NzMessageService,
    private breadCumbNlpService: BreadCumbNlpService
  ) {
  }

  ngOnInit() {

    this.getProductNlp();

    this.hasRegisteredProject();

  }



  private getProductNlp() {
    let prodNlpId: string | null = localStorage.getItem('productNlpId');
    if (typeof prodNlpId !== 'undefined' && prodNlpId !== null) {

      this.productNlpService.getProductNlpById(+prodNlpId).subscribe(productNlpUpdated => {
          this.userService.updateProductNlp(productNlpUpdated);
          this.userService.updateValueTopMenu(true);

        },
        (error) => this.msg.error(`Erro ao selecionar produto NLP: ${error.message}`),
        () => this.checkNlpData()
      );
    } else {
      this.checkNlpData();
    }

  }

  private checkNlpData() {
    this.userService.productNlpObservable$.pipe(take(1))
      .subscribe((data) => {
        if (Object.keys(data).length === 0) {
          this.router.navigate([`nlp`]);
        }
        this.flagDisableTRButtons = Object.keys(data).length === 0;

        this.flagNeedToCreateDocReferenceTerm = data.idDocReference === null || data.idDocReference === undefined;

      });
  }

  hasRegisteredProject(): void {
    //to-do Habilitar botoes atraves da variavel flagDisableTRButtons, quando projeto temporario cadastrado for identificado.
  }

  addNewSection(): void {
    this.userService.isFlagNlpNewSection = true;
    const modal = this.modalService.create<NlpModalNewSectionTermReferenceComponent>({
      nzTitle: 'Cadastrar nova seção do Termo de Referência',
      nzWidth: 900,
      nzContent: NlpModalNewSectionTermReferenceComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzCentered: true,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      },
    })

    modal.getContentComponent();
    modal.afterClose.subscribe(() => {
      if (this.modalClosedSuccessfully) {
        this.userService.updateFlagReferenceTerm(true);
        this.flagNeedToCreateDocReferenceTerm = true;
        this.cdr.detectChanges();
      } else {
        this.userService.updateFlagReferenceTerm(false);
        this.flagNeedToCreateDocReferenceTerm = false;
        this.cdr.detectChanges();
      }
      this.modalClosedSuccessfully = true;
    });
  }

  selectTermReference(): void {
    const modal = this.modalService.create<NlpModalSelectTermReferenceComponent>({
      nzTitle: 'Selecionar Termo de Referência',
      nzWidth: 780,
      nzContent: NlpModalSelectTermReferenceComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      },
    })

    modal.getContentComponent();
    modal.afterClose.subscribe(() => {
      if (this.modalClosedSuccessfully) {
        this.flagNeedToCreateDocReferenceTerm = true;
        this.cdr.detectChanges();
      } else {
        this.flagNeedToCreateDocReferenceTerm = false;
        this.cdr.detectChanges();
      }
      this.modalClosedSuccessfully = false;
    });
  }
}
