import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// ZORRO MODULES
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';

//COMPONENTS
import { SelectMultipleTagComponent } from './select-multiple-tag/select-multiple-tag.component';
import { SelectSimpleComponent } from './select-simple/select-simple.component';
import { SelectSearchComponent } from './select-search/select-search.component';
import { DropdownModule } from 'primeng/dropdown';

const COMPONENTS = [
  SelectMultipleTagComponent,
  SelectSimpleComponent,
  SelectSearchComponent
]

const MODULES = [
  NzSelectModule,
  NzFormModule,
  DropdownModule
]

@NgModule({
  declarations: [...COMPONENTS,],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ...MODULES
  ]
})
export class SelectsLicensingModule { }
