import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';


// ZORRO MODULES


import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from "ng-zorro-antd/table";
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzWaveModule } from "ng-zorro-antd/core/wave";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';

// SMART LICENSING MODULES
import { SharedModule } from 'src/app/shared/shared.module';
import { InputsLicensingModule } from 'src/app/shared/components/inputs/inputs-licensing.module';
import { ButtonsLicensingModule } from 'src/app/shared/components/buttons/buttons-licensing.module';
import { SelectsLicensingModule } from 'src/app/shared/components/selects/selects-licensing.module';

// COMPONENTS

import { SuspensionCancellationModalComponent } from './suspension-cancellation-modal.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";

const MODULES = [
  SharedModule,
  NzGridModule,
  NzFormModule,
  NzWaveModule,
  NzMenuModule,
  NgxMaskDirective,
  NgxMaskPipe,
  NzInputModule,
  NzRadioModule,
  NzTableModule,
  NzButtonModule,
  NzSelectModule,
  NzPopoverModule,
  NzDividerModule,
  NzToolTipModule,
  NzCheckboxModule,
  NzDropDownModule,
  NzDatePickerModule,
  NzAutocompleteModule,
  InputsLicensingModule,
  ButtonsLicensingModule,
  SelectsLicensingModule,
  NzUploadModule,
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [SuspensionCancellationModalComponent],
  exports: [SuspensionCancellationModalComponent],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    [...MODULES],
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [ provideNgxMask()],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SuspensionCancellationModule { }
