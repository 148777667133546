import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';

@Component({
  selector: 'app-filter-notifications',
  templateUrl: './filter-notifications.component.html',
  styleUrls: ['./filter-notifications.component.scss']
})
export class FilterNotificationsComponent implements OnInit {
  @Output() filteredList: EventEmitter<any> = new EventEmitter<any>();

  originalList!: Array<any>;
  filterByProject;
  uniqueProjectNames;
  stringToSearch;

  constructor(
    private projectsService: ProjectsV2Service,
  ) { }

  ngOnInit() {
    this.getProjectList();
  }

  getProjectList() {
    this.projectsService.getProjects(100)
    .subscribe({
      next: (response) => {
        this.originalList = response.content;
      }
    })
  }

  filterByProjectElements(){
    if (!this.filterByProject || this.originalList.length === 0) {
      this.filteredList.emit([]);
      return;
    }

    const filtered = this.originalList.filter(item => item.projectId == this.filterByProject);
    this.filteredList.emit(filtered);
  }

  searchFilter(){
    let filtered = this.originalList;

    this.stringToSearch = this.stringToSearch.toLowerCase();
    if (this.stringToSearch.length === 0) {
      this.filteredList.emit(this.originalList);
    }

    filtered = this.originalList.filter((element) => {
      const name = element.type || '';
      const project = element.entityName || '';
      const userName = element.sentTo || '';
      const date = element.dateTimeNotification || '';

      return (
        name.toLowerCase().includes(this.stringToSearch) ||
        project.toLowerCase().includes(this.stringToSearch) ||
        userName.toLowerCase().includes(this.stringToSearch) ||
        date.toLowerCase().includes(this.stringToSearch)
      );
    });
    this.filteredList.emit(filtered);
  }

  getUniqueProjectNames() {
    const projects = this.originalList
      .map((item) => [
        item.projectName,
      ])
      .flat();
    return (this.uniqueProjectNames = [...new Set(projects)]);
  }
}
