import { Injectable } from '@angular/core'
import {
  type ActivatedRouteSnapshot,
  Router,
  type RouterStateSnapshot
} from '@angular/router'
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular'
import { UserService } from 'src/app/shared/services/user.service'
import { AuthService } from "../service/auth.service";
import { RoutesEnum } from "../../../shared/enum/routes.enum";
import { UtilService } from "../../../shared/util.service";
import { AlertMessageService } from 'src/app/shared/services/alert-message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly userService: UserService,
    protected readonly auth: AuthService,
    protected readonly util: UtilService,
    private alertMessageService: AlertMessageService,
  ) {
    super(router, keycloak)
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    if (!this.authenticated) {
      sessionStorage.clear();
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      })
      sessionStorage.clear();
    }
    let isLogged = false;

    await this.auth.isLoggedIn().then(value => {
      isLogged = value;
    })
    if (isLogged) {
      this.auth.getRoles();
      this.auth.getLoggedUser();
      await this.auth.getApiUser();
      await this.auth.getConfigRole();
    } else {
      sessionStorage.clear()
      this.auth.login()
      return false;
    }

    const requiredRoles = route.data['roles'];

    if (state.url.includes(RoutesEnum.SMART_DOCS) && !this.util.getAuthorization("visible", "CADEIAVSTR")) {
      this.router.navigate([RoutesEnum.TASKS]);
      this.alertMessageService.send(`O usuário não tem autorização para acessar a tela de SMART DOCS.`, 'error');
      return false;
    }

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true
    }


    return requiredRoles.every((role) => this.roles.includes(role))
  }
}
