import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { finalize, Subscription, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzTableFilterFn, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { Communication, CommunicationsService } from '../communications.service';
import moment from 'moment';
import {UtilService} from "../../../shared/util.service";
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { setPages } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { Page } from 'src/app/shared/ngrx/breadcrumb/pages.model';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<Communication> | null;
  visible: boolean;
  sortDirections: NzTableSortOrder[];
  width: string | null;
  filterMultiple: boolean;
  listOfFilter: Array<{ value: string }>;
  filterFn: NzTableFilterFn<Communication> | null;
}

@Component({
  selector: 'app-communications-list',
  templateUrl: './communications-list.component.html',
  styleUrls: ['./communications-list.component.scss']
})
export class CommunicationsListComponent implements OnInit, OnDestroy, AfterViewInit   {
  @Input() processId!: number;
  @Input() projectId!: number;
  @ViewChild('filterTable', { static: false, read: ElementRef }) tableElement!: ElementRef;

  filterOptionId = 0;
  listOfColumns: ColumnItem[] = [];
  communicationsList$: Subscription;
  communicationsList: Communication[] = [];
  communicationsListFiltered: Communication[] = [];
  currentPage = 0;
  totalPages = 1;
  isLoading = false;
  popOverNotification: boolean = false;
  popoverOverlayStyle = {
    background: 'white',
    'font-size': '12px',
    'font-family': 'Roboto, sans-serif',
    'color': '#2E2E2E',
    'border-radius': '8px',
    'cursor': 'pointer',
  };

  filterByProject: any[] = [];
  filterByProcess: any[] = [];
  filterByOrgan: any[] = [];
  filterByProcessNumber: any[] = [];
  filterByDate: any[] = [];
  filterByDateLimit: any[] = [];
  filterByTitle: any[] = [];
  cadCommunicationsInsert: boolean = false;
  cadCommunicationsEdit: boolean = false;
  screenWidth: number = 0;

  constructor(
    private _router: Router,
    private _datePipe: DatePipe,
    private _communicationsService: CommunicationsService,
    private activatedRoute: ActivatedRoute,
    private _utilService: UtilService,
    private store: Store<BreadcrumbState>
  ) { }

  ngOnInit(): void {
    this.cadCommunicationsInsert = this._utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.cadCommunicationsEdit = this._utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.initColumns();
    this.getCommunicationsList(0);
    this.dispatchProjectOnStore()
  }

  ngOnDestroy(): void {
    if (this.communicationsList$) {
      this.communicationsList$.unsubscribe();
    }
  }
  ngAfterViewInit() {
    this.addScrollListener();
  }

  addScrollListener() {
    const scrollableDiv = this.tableElement.nativeElement.querySelector('.ant-table-body');
    
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', () => this.onScroll(scrollableDiv));
    }
  }

  setFilterOptionId(id: number) {
    this.filterOptionId = id;
  }

  initColumns() {
    this.listOfColumns = [
      {
        name: 'icon',
        sortOrder: null,
        sortFn: null,
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '4%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: null
      },
      {
        name: 'projects.communication.title',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.title.localeCompare(b.title),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '15%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
      {
        name: 'projects.communication.project-name',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.projectName.localeCompare(b.projectName),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '13%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1),
      },
      {
        name: 'projects.communication.process-name',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.processName.localeCompare(b.processName),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '14%',
        filterMultiple: false,
        listOfFilter: [{ value: 'teste' }, { value: 'value' }],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
      {
        name: 'projects.communication.organ',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.organ.localeCompare(b.organ),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '8%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
      {
        name: 'projects.communication.process-number',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.processNumber.toString().localeCompare(b.processNumber.toString()),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '10%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
      {
        name: 'projects.communication.dates',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.date.localeCompare(b.date),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '13%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
      {
        name: 'projects.communication.limit-date',
        sortOrder: null,
        sortFn: (a: Communication, b: Communication) => a.answerLimitDate.localeCompare(b.answerLimitDate),
        sortDirections: ['ascend', 'descend', null],
        visible: false,
        width: '13%',
        filterMultiple: false,
        listOfFilter: [],
        filterFn: (list: string[], item: Communication) => list.some(name => item.title.indexOf(name) !== -1)
      },
    ];
  }

  getCommunicationsList(page: number) {
    if (this.isLoading || page >= this.totalPages) return;
    this.isLoading = true; 
    this._communicationsService.getCommunicationsList(page, 100).pipe(
      tap((response) => {
        this.totalPages = response.totalPages;
        this.communicationsList = [...this.communicationsList, ...response.content];
        this.communicationsListFiltered = this.communicationsList;
        this.createFilters();
        this.createFiltersNew();
      }),
      finalize(() => this.isLoading = false) 
    ).subscribe();
  }
  
  onScroll(scrollableDiv: HTMLElement) {
    if (this.isLoading || this.currentPage >= this.totalPages) return;
    const isBottom = Math.abs(scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight) < 1;
    if (isBottom) {
      this.currentPage++;
      this.getCommunicationsList(this.currentPage);
    }
  }

  createFilters() {
    const propertiesToCheck = ['title', 'projectName', 'processName', 'organ', 'processNumber', 'date', 'answerLimitDate'];

    const filteredList = this.communicationsList.filter(item =>
      propertiesToCheck.every(prop => this.isValidValue(item[prop]))
    );

    propertiesToCheck.forEach((prop, index) => {
      this.listOfColumns[index + 1].listOfFilter = this.createFilterList(filteredList.map(item => item[prop]), prop);
    });
  }

  private isValidValue(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  createFiltersNew() {
    const addToFilterIfNotExist = (filterArray, element, property) => {
      const newElement = {
        label: element[property],
        value: element[property],
      };
      const existing = filterArray.some(result => result.label === element[property]);
      if (!existing && element[property]) {
        filterArray.push(newElement);
      }
    };
    this.communicationsList.forEach(element => {
      addToFilterIfNotExist(this.filterByProject, element, 'projectName');
      addToFilterIfNotExist(this.filterByProcess, element, 'processName');
      addToFilterIfNotExist(this.filterByOrgan, element, 'organ');
      addToFilterIfNotExist(this.filterByProcessNumber, element, 'processNumber');
      addToFilterIfNotExist(this.filterByDate, element, 'date');
      addToFilterIfNotExist(this.filterByDateLimit, element, 'answerLimitDate');
      addToFilterIfNotExist(this.filterByTitle, element, 'title');
    });

    //FORMATA DATA RECEBIMENTO
    let newArrayDate: any[] = []
    this.filterByDate.map(element => {
      if(element.label){
        newArrayDate.push({ label: moment(element.label).format("DD/MM/YYYY"), value: element.value })
      }
    })
    this.filterByDate = newArrayDate

    //FORMATA DATA LIMITE
    let newArrayDateLimit: any[] = []
    this.filterByDateLimit.map(element => {
      if(element.label){
        newArrayDateLimit.push({ label: moment(element.label).format("DD/MM/YYYY"), value: element.value })
      }
    })
    this.filterByDateLimit = newArrayDateLimit

  }

  private createFilterList(values: any[], type: string): { label: string, value: string, checked: boolean, type: string }[] {
    const uniqueValues = Array.from(new Set(values));
    return uniqueValues.map(value => ({
      label: this.getLabel(value, type),
      value: value.toString() || '',
      checked: false,
      type: type
    }));
  }

  private getLabel(value: any, type: string): string {
    if (type === 'date' || type === 'answerLimitDate') {
      return this._datePipe.transform(value, 'dd/MM/YYYY') ?? '';
    }
    return value.toString();
  }

  filterBy(checkboxArray: any, type: string) {
    const itemsToFind = checkboxArray.filter((item: { checked: any; }) => { return item.checked }).map((item: { value: any; }) => { return item.value });

    if (itemsToFind.length === 0) {
      this.communicationsListFiltered = this.communicationsList;
      return;
    }
    if (type === 'title') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.title }) });
    }
    if (type === 'projectName') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.projectName }) })
    }
    if (type === 'processName') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.processName }) })
    }
    if (type === 'organ') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.organ }) })
    }
    if (type === 'processNumber') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: number) => { return iFind === item.processNumber }) })
    }
    if (type === 'date') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.date }) })
    }
    if (type === 'answerLimitDate') {
      this.communicationsListFiltered = this.communicationsList.filter((item) => { return itemsToFind.some((iFind: string) => { return iFind === item.answerLimitDate }) })
    }
  }

  newCommunication(type: string) {
    let navigationParams = { queryParams: { projectId: this.projectId, processId: this.processId, type } };
    this._router.navigate([`/${RoutesEnum.COMMUNICATION}/new`], navigationParams);
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name;
  }

  resetSearch(filter: string) {
    this.communicationsListFiltered = this.communicationsList;
    if (filter === 'title') {
      this.filterByTitle = this.filterByTitle.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'projectName') {
      this.filterByProject = this.filterByProject.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'processName') {
      this.filterByProcess = this.filterByProcess.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'organ') {
      this.filterByOrgan = this.filterByOrgan.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'processNumber') {
      this.filterByProcessNumber = this.filterByProcessNumber.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'date') {
      this.filterByDate = this.filterByDate.map(item => ({
        ...item,
        checked: false
      }));
    }
    if (filter === 'answerLimitDate') {
      this.filterByDateLimit = this.filterByDateLimit.map(item => ({
        ...item,
        checked: false
      }));
    }
  }

  goToCommunication(id, processName){
    let navigationParams = { queryParams: { communicationId: id, processName: processName } };
    this._router.navigate([`/${RoutesEnum.COMMUNICATION}/new`], navigationParams);
  }


  // INICIO BREADCRUMB

  private dispatchProjectOnStore(): void {
    const pagesArray: Page[] = [
      {
        name: "Comunicações com órgãos",
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }
  // FINAL BREADCRUMB

  returnHeightOnResolution(): string {
    let width = window.screen.width;
    this.screenWidth = width
    if (width > 1366) {
      return '620px'
    } else {
      return '500px'
    }
  }
}
