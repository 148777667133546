import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsInformationsService {
  baseUrl: string = environment.apiUrl;
  constructor(private readonly http: HttpClient) {}

  getProjectsInformations(projectId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/projects/${projectId}/info`);
  }
}
