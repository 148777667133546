import {DatePipe} from "@angular/common";
import {Injectable} from "@angular/core";
import {NlpResultStatusData} from "../../modules/nlp/interface/nlp-result-status";

@Injectable({
  providedIn: 'root',
})
export class DateCalcHelper{

  constructor(private datePipe: DatePipe) {
  }

  public addDaysToDate(date: string, days: number): String | null {
    if (date !== null && date !== '' && date !== undefined) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return this.datePipe.transform(result, 'dd/MM/YYYY');
    }

    return '';
  }

  isDateMoreThanXDays(dateString: string, days:number): boolean {
    const date = new Date(dateString);
    const today = new Date();
    const diffTime = Math.abs(date.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > days;
  }

  getTimeZoneDate(date: string) {
    var utcDate = new Date(date);
    return new Date(Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      utcDate.getHours(),
      utcDate.getMinutes(),
      utcDate.getSeconds()
    ));
  }
}
