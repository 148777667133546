import {
  Component,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent  {
  readonly #modal = inject(NzModalRef);
  @Input() dataToAnalisys: any;
  @Input() projectId: number;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isSuspension: string;
  @Input() buttonText: string = "Ir para o projeto";
  @Input() buttonAction: any;

  arrayImageDelete: any[] = [];
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  bodyFormat: any;

  constructor(
  ) {
  }
  

  executeButtonAction() {
    this.buttonAction()
    this.#modal.destroy();
  }
  close(){
    this.#modal.destroy();
  }

}
