<h2>Últimos 7 dias</h2>

<div
  class="content-line"
  *ngFor="let item of top7Days"
  id="notification-all7-{{ item.id }}"
>
  <a [href]="item.link" target="_blank" class="title-notification link" [title]="'Clique para acessar'">
    <div class="title-notification" [innerHtml]="sanitizeInputAllowOnlyItalicTag(item.message)" ></div>
  </a>
  <div class="project-notification">{{ item.projectName }}</div>
  <div class="date-notification">{{ item.dateTimeNotification | date : "dd/MM/yyyy" }}</div>
</div>

<h2>Mais antigas</h2>

<div
  class="content-line"
  *ngFor="let item of offsetDays"
  id="notification-all-{{ item.id }}"
>
  <div class="title-notification" [innerHtml]="sanitizeInputAllowOnlyItalicTag(item.message)"></div>
  <div class="project-notification">{{ item.projectName }}</div>
  <div class="date-notification">{{ item.dateTimeNotification | date : "dd/MM/yyyy" }}</div>
</div>
