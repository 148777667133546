import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {ScopeModel} from '../../../shared/models/scopeModel';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {UserModel} from '../../../shared/models/user.model';
import {AttachmentsModel} from '../../../shared/models/attachments.model';
import {UtilService} from '../../../shared/util.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {AttachmentSaveDto} from '../../../shared/dto/attachment-save.dto';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {Observable, take} from 'rxjs';
import {PROJECT_SUCCESS_SAVE} from '../../projects/constants/projectsNewConstants';
import {UserService} from '../../../shared/services/user.service';
import {AttachmentsService} from '../../../shared/services/attachments.service';
import {CompetentOrgan} from 'src/app/shared/models/competent-organ.model';
import {
  ProjectsRiplaReportService
} from '../../projects/projects-ripla/projects-ripla-report/projects-ripla-report.service';
import {DemandService} from 'src/app/shared/services/demand.service';
import {StudyTypeModel} from 'src/app/shared/models/study-type.model';
import {ProductNlpDto} from 'src/app/shared/dto/product-nlp.dto';
import {NlpTempProjectService} from './nlp-add-proj-informations.service';
import {NlpTemporaryProjectModel} from 'src/app/shared/models/nlp-temporary-project.model';
import {NlpTemporaryProjectItemModel} from 'src/app/shared/models/nlp-temporary-project-item.model';
import {ProductNlp} from '../interface/nlp-interface';
import {Router} from '@angular/router';
import {PRODUCT_NLP_ENTITY_NAME} from '../constants/nlpConstants';
import { FileValidationService } from 'src/app/shared/services/file-validation.service';

@Component({
  selector: 'app-nlp-add-proj-informations',
  templateUrl: './nlp-add-proj-informations.component.html',
  styleUrls: ['./nlp-add-proj-informations.component.scss'],
  providers: [],
})
export class NlpAddProjInformationsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('uploadInput') divClick!: ElementRef;

  @Input() project!: NlpTemporaryProjectModel;
  @Input() editMode: boolean = false;
  @Input() isEditProjectVisible: boolean = false;
  @Input() data: any;

  form!: UntypedFormGroup;
  scopes: ScopeModel[] = [];
  users: UserModel[] = [];
  organs: CompetentOrgan[] = [];
  tempProjects: NlpTemporaryProjectItemModel[] = [];
  studyTypes: StudyTypeModel[] = [];
  setOrganId = null;
  setStudyTypeId = null;
  setUserResponsibleId = null;
  setTempProjectName = null;
  anexo!: Observable<any[]>;
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  arrayImageDelete: string[] = [];
  loggedUser: any;
  rbProjectExists: boolean = false;
  isUsersLoading: boolean = false;
  isProjectTypesLoading: boolean = false;
  isStudyTypeLoading: boolean = false;
  isFormInit: boolean = true;
  isDataEmpty: boolean = false;
  selectedProductNlpId: number | undefined = 0;
  showAttachmentWarning = false;
  idProject!: number | undefined;

  readonly #modal = inject(NzModalRef);
  projectSelectedExists: boolean = false;

  isInvalidFile: boolean = false;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly i18n: NzI18nService,
    private readonly attachmentsService: AttachmentsService,
    private readonly userService: UserService,
    private readonly message: MessageService,
    private readonly utilService: UtilService,
    private readonly modal: NzModalService,
    private readonly reportService: ProjectsRiplaReportService,
    private readonly demandService: DemandService,
    private readonly nlpTempProjectService: NlpTempProjectService,
    private fileValidationService: FileValidationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const userRecuperado = sessionStorage.getItem('loggedUser');
    this.loggedUser = userRecuperado ? JSON.parse(userRecuperado) : null
    if(!this.loggedUser){
      this.getLoggedUser()
    }
    this.initForm();
    this.userService.isFlagCancelModalProj = false;
    if (this.editMode) {
      this.userService.productNlpObservable$.subscribe(data => {
        this.idProject = data.id;
      })
    }

  }

  private getLoggedUser() {
    this.userService.getLoogedUser().subscribe({
      next: value => {
        this.loggedUser = value;
        this.getUsersLoading()
      },
      error: err => {}
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['isEditProjectVisible']?.currentValue === true
      ? this.patchRevisionForm()
      : '';
  }


  ngAfterViewInit() {
    setTimeout(() => {
      if (this.editMode) {

        const Arrayfile: NzUploadFile[] = [];
        this.attachmentsService
          .getAttachmentsByEntityAndEntityId(
            this.idProject,
            'PRODUCT_NLP'
          )
          .subscribe((data) => {
            for (const image of data) {
              const file: NzUploadFile = {
                description: image.description,
                name: image.name,
                filename: image.name,
                uid: image.id?.toString() || '',
                thumbUrl: this.utilService.getIconFromFileName(image.name),
              };
              Arrayfile.push(file);
            }
            this.fileList = Arrayfile;
            this.fileListStorageRepite = Arrayfile;
          });
      }
    });
  }

  private updateProductNlp(tempProject: ProductNlpDto): void {
    if (this.arrayImageDelete.length > 0) {
      this.deleteAttachment(this.arrayImageDelete);
    }
    this.nlpTempProjectService.updateProductNlp(tempProject).subscribe(
      (tempProject) => {
        this.onSuccessCreateOrEditProject(tempProject);
      },
      (error) => {
        this.onErrorCreateOrEditProductNlp(error.message);
      }
    );
  }

  submitForm(): void {
    const tempProject: ProductNlpDto = this.buildPayload();
    if (!this.checkIfHasAttachment()) {
      return;
    }

    if (this.editMode) {
      this.updateProductNlp(tempProject);
    } else {
      this.createProductNlp(tempProject);
    }
  }

  private createProductNlp(tempProject: ProductNlpDto) {
    this.nlpTempProjectService.createProductNlp(tempProject).subscribe(
      (tempProject) => {
        this.onSuccessCreateOrEditProject(tempProject);
      },
      (error) => {
        this.onErrorCreateOrEditProductNlp(error.message);
      }
    );
  }

  initForm(): void {

    this.i18n.setLocale(pt_BR);

    let tempProjectName = '';
    let tempProjectId = '';
    let competentOrganId = '';
    let studyTypeId = '';
    let userResponsibleId = '';
    let projectExists = '';

    if (this.editMode) {

      this.fillSelectsExistingTempProject();
    }

    this.form = this.fb.group({
      tempProjectName: [tempProjectName, [Validators.required]],
      tempProjectId: [tempProjectId],
      competentOrganId: [competentOrganId, [Validators.required]],
      studyTypeId: [studyTypeId, [Validators.required]],
      userResponsibleId: [userResponsibleId, [Validators.required]],
      projectExists: [projectExists, [Validators.required]],
    });


    if (this.editMode) {
      this.form = this.fb.group({
        tempProjectName: [{value: tempProjectName, disabled: true}],
        tempProjectId: [tempProjectId],
        competentOrganId: [competentOrganId],
        studyTypeId: [studyTypeId],
        userResponsibleId: [userResponsibleId],
        projectExists: [projectExists],
      });

      this.chooseProjectSelectOption(true);
    }
    if(this.loggedUser){
      this.getUsersLoading();
    }
    this.getCompetentOrgans();
    this.getStudyTypes();
    this.getTemporaryProjects();
    this.isFormInit = false;
  }


  onBrowserFilesToUpload(): void {
    this.divClick.nativeElement.click();
  }

  async verifyIfIsValidFile(file) {    
    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.verifyIfIsValidFile(file).then(result => {
      if( this.isInvalidFile === false){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileList = this.fileList.concat(file);
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    return false;

    if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
      file.thumbUrl = this.utilService.getIconFromFileName(file.name);
      this.fileList = this.fileList.concat(file);
    }
    return false;
  };

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 350 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  showCancelConfirm(): void {
    if (this.form.dirty) {
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => {
          this.userService.isFlagCancelModalProj = true;
          this.router.navigate([`nlp/welcome`]);
          this.close();
        },
      });
    } else if (this.editMode) {
      this.userService.isFlagCancelModalProj = true;
      this.close();
    } else {
      this.userService.isFlagCancelModalProj = true;
      this.router.navigate([`nlp/welcome`]);
      this.close();
    }
  }

  handleCancel(): void {
    this.showCancelConfirm();
  }

  private close() {
    this.#modal.close(true);
  }

  private patchRevisionForm(): void {
    this.initForm();
  }

  private getUsersLoading() {
    this.isUsersLoading = true;
    this.loggedUser.complexes.forEach(complex => {
      this.userService
        .getUsersByComplex(complex.id)
        .pipe(take(1))
        .subscribe((users) => {
          this.users = this.users.concat(users);
          this.isUsersLoading = false;
        });
    })
  }

  private getCompetentOrgans(): void {
    this.reportService.getCompetentOrgans().subscribe((items) => {
      this.organs = items;
    });
  }

  private getStudyTypes(): void {
    this.demandService.getStudyTypes().subscribe((studies) => {
      this.studyTypes = studies;
      this.isStudyTypeLoading = false;
    });
  }

  private getTemporaryProjects(): void {
    this.nlpTempProjectService.getNlpTempProjectList().subscribe((items) => {
      this.tempProjects = items;
    });
  }

  private buildPayload(): ProductNlpDto {
    if (this.form?.value?.tempProjectId) {
      let projectSelected = this.tempProjects?.filter(
        (project) => project.id === +this.form?.value?.tempProjectId
      )[0];
      this.form.value.tempProjectName = projectSelected?.description;
    }

    return {
      name: this.form?.value?.tempProjectName,
      id: this.editMode
        ? this.selectedProductNlpId
        : this.form?.value?.tempProjectId,
      competentOrganId: this.form?.value?.competentOrganId,
      productId: this.form?.value?.studyTypeId,
      userRespStudyId: this.form?.value?.userResponsibleId,
    };
  }

  private onSuccessCreateOrEditProject(projectSaved: any) {
    this.message.showSucessMessage(`Produto NLP salvo com sucesso!`);
    this.updateTemporaryProjectValue(projectSaved);

    this.userService.isFlagCancelModalProj = false;
    this.userService.setProductNlp = projectSaved;

    const fileListToSave: AttachmentsModel[] = this.removeFilesRepite();

    if (projectSaved.id && fileListToSave.length) {
      this.uploadFiles(projectSaved.id);
    }
    this.message.showSucessMessage(
      `${projectSaved.name} ${PROJECT_SUCCESS_SAVE}`
    );
  }

  private uploadFiles(entityId) {


    if (this.fileList.length) {
      const diferencia = this.fileListStorageRepite
        .filter(
          (item1) =>
            !this.fileList.some(
              (item2) => item1.uid === item2.uid && item1.name === item2.name
            )
        )
        .concat(
          this.fileList.filter(
            (item2) =>
              !this.fileListStorageRepite.some(
                (item1) => item2.uid === item1.uid && item2.name === item1.name
              )
          )
        );

      for (const file of diferencia) {
        const attachment: AttachmentSaveDto = {
          name: file.name,
          description: file.name,
          entityId,
          entityName: PRODUCT_NLP_ENTITY_NAME,
          contextInfo: {
            phase: 'Cadastro'
          },
          file,
        };

        this.attachmentsService
          .uploadAttachment(attachment)
          .subscribe({
            next: () => {
              this.message.showSucessMessage(
                `Arquivo enviado com sucesso: ${attachment.name}`
              );
            },
            error: () => {
              this.message.showErrorMessage(
                `Erro ao salvar o arquivo: ${attachment.name}`
              );
            },
          });
      }
    }
    this.close();
  }

  private onErrorCreateOrEditProductNlp(error: any) {
    this.message.showErrorMessage(
      `Erro ao alterar produto NLP: ${error.message}`
    );
    this.close();
  }

  chooseProjectSelectOption(option: boolean): void {
    let checkProjectExists = option;
    let labels = Array.from(
      document.getElementsByClassName('temp-project-infos')
    ) as HTMLLIElement[];

    if (checkProjectExists) {
      labels.map((element) => element.classList.add('disabled'));
    } else {
      labels.map((element) => element.classList.remove('disabled'));
      this.setOrganId = null;
      this.setStudyTypeId = null;
      this.setUserResponsibleId = null;
    }
  }

  fillSelectsExistingTempProject(value?: any): void {
    let projectId;

    if (this.form != null) {
      projectId = this.form.value.tempProjectId;
    } else if (this.editMode) {
      projectId = this.getProjectIdFromUserService(projectId);
    }
    this.projectSelectedExists = false;

    this.getNlpTempProjectById(projectId);
  }

  private getNlpTempProjectById(projectId) {
    this.nlpTempProjectService.getNlpTempProjectById(projectId).subscribe({
      next: (tempProject) => {
        this.project = tempProject.data;
        this.setTempProjectName = tempProject.data.name;
        this.setOrganId = tempProject.data.competentOrganId;
        this.setStudyTypeId = tempProject.data.studyTypeId;
        this.setUserResponsibleId = tempProject.data.responsibleStudyId;

        this.clearValidatorWhenUsingSelectedProject();
      },
      error: (error) => {
        this.message.showErrorMessage(`Erro: ${error.message}`);
      }
    });
  }

  private getProjectIdFromUserService(projectId) {
    this.userService.productNlpObservable$.subscribe((data) => {
      if (Object.keys(data).length === 0) {
        this.isDataEmpty = true;
      } else {
        this.isDataEmpty = false;
        projectId = data.id;
        this.selectedProductNlpId = data.id;
      }
    });
    return projectId;
  }

  private clearValidatorWhenUsingSelectedProject() {
    this.form.controls["tempProjectName"].clearValidators();
    this.form.controls["tempProjectName"].updateValueAndValidity();
    this.form.controls["competentOrganId"].clearValidators();
    this.form.controls["competentOrganId"].updateValueAndValidity();
    this.form.controls["studyTypeId"].clearValidators();
    this.form.controls["studyTypeId"].updateValueAndValidity();
    this.form.controls["userResponsibleId"].clearValidators();
    this.form.controls["userResponsibleId"].updateValueAndValidity();
    this.form.controls["projectExists"].clearValidators();
    this.form.controls["projectExists"].updateValueAndValidity();
  }

  updateTemporaryProjectValue(newValue: ProductNlp) {
    this.userService.updateProductNlp(newValue);
    localStorage.setItem('productNlpId', String(newValue.id));
    this.userService.updateValueTopMenu(true);
  }

  checkIfHasAttachment() {
    this.showAttachmentWarning = false;

    if (this.fileList.length === 0) {
      this.showAttachmentWarning = true;
      return false;
    }

    return true;
  }


  deleteAttachment(fileListToDelete: string[]): void {
    for (const file of fileListToDelete) {
      this.attachmentsService
        .deleteAttachment(file)
        .pipe(take(1))
        .subscribe(() => {
          this.onSuccesfullMessage("Arquivo deletado com sucesso")
        });
    }
  }

  private onSuccesfullMessage(message?: string) {
    this.message.showSucessMessage(` ${message}`);
    this.close();
  }

  removeFiles = (file: NzUploadFile): boolean => {
    this.arrayImageDelete.push(file.uid);
    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListStorageRepite = this.fileListStorageRepite.filter(
      (item) => item !== file
    );
    return false;
  };

  removeFilesRepite() {
    const fileListToSave: AttachmentsModel[] = [];
    const diferencia = this.fileListStorageRepite
      .filter(
        (item1) =>
          !this.fileList.some(
            (item2) => item1.uid === item2.uid && item1.name === item2.name
          )
      )
      .concat(
        this.fileList.filter(
          (item2) =>
            !this.fileListStorageRepite.some(
              (item1) => item2.uid === item1.uid && item2.name === item1.name
            )
        )
      );
    diferencia.forEach((file) => {
      const attachment: AttachmentsModel = {
        description: file.name,
        name: file.name,
      };
      fileListToSave.push(attachment);
    });
    return fileListToSave;
  }


}
