import { Subject } from "rxjs";
import { Location } from "@angular/common";
import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from "ng-zorro-antd/modal";
import { MessageService } from "src/app/shared/common/services/message.service";
import { CommunicationByIdResponse } from "src/app/shared/dto/communication-by-id-response.dto";
import {UtilService} from "../../../shared/util.service";
import { BreadcrumbState } from "src/app/shared/ngrx/breadcrumb/breadcrumb.state.model";
import { Store } from "@ngrx/store";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { AttachmentsModel } from "src/app/shared/models/attachments.model";
import { setPages } from "src/app/shared/ngrx/breadcrumb/breadcrumb.actions";
import { Page } from "src/app/shared/ngrx/breadcrumb/pages.model";
import { RoutesEnum } from "src/app/shared/enum/routes.enum";
import { AttachmentsService } from "src/app/shared/services/attachments.service";
import { COMMUNICATION_ENTITY_NAME } from "../../projects/constants/projectsNewConstants";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-communications-form',
  templateUrl: './communications-form.component.html',
  styleUrls: ['./communications-form.component.scss']
})
export class CommunicationsFormComponent implements AfterContentInit, AfterContentChecked, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();

  pageTitle: string = '';
  pageId: string = '';
  communicationID: string = '';
  isSaving: Subject<boolean> = new Subject();
  communication: Subject<CommunicationByIdResponse> = new Subject();
  communicationAttachments: AttachmentsModel[] = [];
  cadCommunicationsInsert: boolean = false;
  cadCommunicationsEdit: boolean = false;
  disabledSave: boolean = false;
  newTitle: string | null = '';

  communicationAttachmentsServ: AttachmentsModel[] = [];
  constructor(
    private location: Location,
    private cdref: ChangeDetectorRef,
    private modal: NzModalService,
    private message: MessageService,
    private utilService: UtilService,
    private store: Store<BreadcrumbState>,
    private attachmentsService: AttachmentsService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.pageTitle = '';
    this.pageId = '';
    this.communicationID = '';
  }

  ngAfterContentChecked(): void {
    this.cadCommunicationsInsert = this.utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.cadCommunicationsEdit = this.utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.cdref.detectChanges();
  }

  setTitle(e) {
    this.newTitle = e
  }

  changeData(communication: CommunicationByIdResponse) {
    this.communication.next(communication);
  }

  attachmentsUpdated(attachments: NzUploadFile[]) {
    this.communicationAttachments = attachments.map(attach => {
      return this.utilService.buildAttachmentModelFromNzUpload(attach);
    })
  }

  save() {
    this.disabledSave = true;
    this.isSaving.next(true);
  }

  goBack() {
    this.location.back();
  }

  ngAfterContentInit(): void {
    this.getAttachments();
    this.communication.subscribe(c => {
      this.pageTitle = c.title + '';
      this.pageId = c.identificationNumber !== undefined ? c.identificationNumber : '';
      this.dispatchProjectOnStore();
    });
  }

  setTitleFromCommunicationNew(event){
    this.pageTitle = event
  }

  showCancelConfirm(): void {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.goBack();
      },
    });
  }

  onRemoveFocusInput() {
    document.body.click();
  }


  // INICIO BREADCRUMB

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }


  private dispatchProjectOnStore(): void {
    const pagesArray: Page[] = [
      {
        name: "Comunicações com órgãos",
        url: `/${RoutesEnum.COMMUNICATION}`
      },
      {
        name: `${this.pageTitle.replace(' enviada', '').replace(' recebida', '')}`,
        url: ''
      },
    ];

    this.store.dispatch(setPages({pages: pagesArray}));
  }
  // FINAL BREADCRUMB

  private getAttachments(): void {
    const communicationId = this._activatedRoute?.snapshot?.queryParams['communicationId'];

    if(!communicationId) return;

    this.attachmentsService.getAttachmentsByEntityAndEntityId(communicationId, COMMUNICATION_ENTITY_NAME).subscribe({
      next: (attachments) => {
        this.communicationAttachmentsServ = attachments;
      }
    });
  }

  updateDisabledSave(disabled: boolean): void {
    this.disabledSave = disabled;
  }

}
