import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ScopeChangeResponse} from "../../../../scope-change/interface/scope-change-request";
import {ActionsService} from "../../../../../shared/services/actions.service";
import {take} from "rxjs";
import {OptionsDto} from "../../../../../shared/dto/options.dto";
import {ProjectSuspension} from "../../../../../shared/dto/project-suspension.dto";
import {ImpactsService} from "../../../../../shared/services/impacts.service";
import {UserService} from "../../../../../shared/services/user.service";
import {TranslateService} from "@ngx-translate/core";
import {UtilService} from "../../../../../shared/util.service";

@Component({
  selector: 'app-env-licensing-opinion',
  templateUrl: './env-licensing-opinion.component.html',
  styleUrls: ['./env-licensing-opinion.component.scss']
})
export class EnvLicensingOpinionComponent implements OnInit, AfterViewInit {
  @Output() handleFormChanges = new EventEmitter<any>();
  @Input() checkValidateForm: boolean = false;
  @Input() suspension!: ProjectSuspension;
  form: FormGroup;
  scopeChange: ScopeChangeResponse | null;
  actionsList: OptionsDto[];
  impactsList: OptionsDto[];
  isRoleGestor: boolean = false;

  constructor(private readonly formBuilder: FormBuilder,
              private actionsService: ActionsService,
              private impactsService: ImpactsService,
              private util: UtilService,
              private userService: UserService,
              private translationService: TranslateService,
              private renderer: Renderer2, private el: ElementRef) {
  }

  ngOnInit(): void {
    this.onInitForm();

    this.getActionsList();

    this.getImpactsList();

    this.isRoleGestor = this.util.getAuthorization("insert", "ANALYSISSUSPENSION")
  }

  onInitForm(): void {
    this.form = this.formBuilder.group({
      actions: [this.suspension ? this.suspension.actions : null, Validators.required],
      impacts: [this.suspension ? this.suspension.impacts : null],
      comments: [this.suspension ? this.suspension.impactComments : null]
    });
    this.handleFormChanges.emit(this.form);
  }

  checkInputForm(controlName: string):
    string {
    if (!this.form.controls[controlName].valid && this.checkValidateForm) {
      return 'error'
    }
    return ' '
  }

  getActionsList() {
    return this.actionsService.getActionsListByType(this.suspension.type)
    .pipe(take(1))
    .subscribe({
      next: (actions) => {
        this.actionsList = actions;
      }
    })
  }

  getImpactsList() {
    return this.impactsService.getImpactsListByType(this.suspension.type)
    .pipe(take(1))
    .subscribe({
      next: (reasons) => {
        this.impactsList = reasons;
      }
    })
  }

  getImpactsLabel() {
    if (this.suspension.type === 'CANCEL') {
      return this.translationService.instant('projects.suspension-cancellation.env-licensing-opinion.impacts-label-cancel');
    }
    return this.translationService.instant('projects.suspension-cancellation.env-licensing-opinion.impacts-label-suspension');
  }

  getActionsLabel() {
    if (this.suspension.type === 'CANCEL') {
      return this.translationService.instant('projects.suspension-cancellation.env-licensing-opinion.actions-label-cancel');
    }
    return this.translationService.instant('projects.suspension-cancellation.env-licensing-opinion.actions-label-suspension');
  }

  onHandleFormChanges(): void {
    this.handleFormChanges.emit(this.form);
  }

  getTitle() {
    return this.suspension.type === 'SUSPENSION' ? 'projects.suspension-cancellation.env-licensing-opinion.title-suspension' : 'projects.suspension-cancellation.env-licensing-opinion.title-cancellation';
  }

  getWarning() {
    let warning = 'projects.suspension-cancellation.env-licensing-opinion.'
    return this.suspension.type === 'SUSPENSION' ? warning+'warning-suspension' : warning+'warning-cancellation';
  }

  private wrapTextWithSpan(textToWrap: string, querySelectorClass: string, elementIndex: number) {

    // Get the parent element where the text is located
    const parentElement = this.el.nativeElement.querySelectorAll(querySelectorClass);

    if(parentElement[elementIndex] == undefined)
      return;
    // Get the HTML of the parent element
    const parentHTML = parentElement[elementIndex].innerHTML;

    // Create a new HTML string where the text is wrapped in a span tag
    const newHTML = parentHTML.replace(new RegExp(textToWrap, 'g'), `<span style="text-decoration: underline" >${textToWrap}</span>`);

    // Use the renderer to set the new HTML of the parent element
    this.renderer.setProperty(parentElement[elementIndex], 'innerHTML', newHTML);
  }

  ngAfterViewInit(): void {
    this.wrapTextWithSpan('principais ações','.sl__form__title',0);
    this.wrapTextWithSpan('principais impactos','.sl__form__title', 1);
  }
}
