<section class="app-env-licensing-opinion-summary sl__simpleLayout">
  <div class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        {{ getTitle() | translate }}
      </h2>
    </header>
    <div class=" summary__wrapper">


      <div class="summary__wrapper__items">
        <div class="summary__wrapper__items__item" id="resumoEscopo">

          <!--Medidas necessárias-->
          <div class="summary__wrapper__items__item_row">
            <p class="summary__wrapper__items__item_row__title">{{ 'projects.suspension-cancellation.env-licensing-opinion.necessary-measures' | translate }}</p>
            <ul>
              <li *ngFor="let actions of suspension?.actions">
                <span>&#x2022;</span>
                {{ actions.description }}
              </li>
            </ul>
          </div>

          <!-- Impactos mapeados -->
          <div class="summary__wrapper__items__item_row">
            <p class="summary__wrapper__items__item_row__title">{{ 'projects.suspension-cancellation.env-licensing-opinion.mapped-impacts' | translate }}</p>
            <ul>
              <li *ngFor="let impact of suspension?.impacts">
                <span>&#x2022;</span>
                {{ impact.description }}
              </li>
            </ul>
          </div>

          <!-- Observações -->
          <div class="summary__wrapper__items__item_row">
            <p class="summary__wrapper__items__item_row__title">Observações</p>
            <p class="summary__wrapper__items__item_row__description"> {{ suspension?.impactComments }}</p>
          </div>


        </div>

      </div>
    </div>

  </div>
</section>
