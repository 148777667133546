import { type KeycloakOptions, type KeycloakService } from 'keycloak-angular'
import { environment } from '../../../environments/environment'

export function initializer(keycloak: KeycloakService): () => Promise<boolean> {
  const options: KeycloakOptions = {
    config: environment.keycloak,
    loadUserProfileAtStartUp: true,
    initOptions: {
      // onLoad: 'check-sso',
      onLoad: 'login-required',
      checkLoginIframe: false
    },
    bearerExcludedUrls: []
  }

  return async() => await keycloak.init(options)
}
