import {Component, EventEmitter, Output, inject } from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss']
})
export class ProgressModalComponent {
  @Output() closeEvent = new EventEmitter<void>();
  titulo: string;
  subtitulo: string;
  progress: number;
  readonly #modal = inject(NzModalRef);

  constructor() { }

  handleCancel() {
    this.closeEvent.emit();
    this.#modal.destroy();
  }
}
