import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import {delay, Observable, retry} from "rxjs";
import {
  ATTACHMENT_API,
  ATTACHMENT_UPLOAD_API,
  ATTACHMENT_UPLOAD_MANDADORY_DOC
} from "../../modules/projects/constants/apiEndpointsConstants";
import {AttachmentsModel} from "../models/attachments.model";
import {CommentRequestDto} from "../../modules/documents/documents-list/comment-modal/CommentRequestDto";

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(private readonly http: HttpClient) { }
  queryParams = "";

  getAttachmentsByEntityAndEntityId(entityId: number | undefined, entityName: string): Observable<AttachmentsModel[]> {
    return this.http.get<AttachmentsModel[]>(`${ATTACHMENT_API}/${entityId}/${entityName}`)
  }

  uploadAttachment(attachment: any) { //: Observable<AttachmentsModel>
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    return this.http.post<AttachmentsModel>(`${ATTACHMENT_UPLOAD_API}?entityId=${attachment.entityId}&entityName=${attachment.entityName}${this.queryParams}`, formData)
      .pipe(retry(3));
  }

  uploadAttachmentsWithProgress(attachment: any) {
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_API}?entityId=${attachment.entityId}&entityName=${attachment.entityName}${this.queryParams}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    );

    return this.http.request(req).pipe(retry(3))
  }

  uploadAttachmentMandadoryDock(attachment: any) { //: Observable<AttachmentsModel>
    this.queryParams = "";
    if(attachment.contextInfo){
      for (const key in attachment.contextInfo) {
        if (Object.prototype.hasOwnProperty.call(attachment.contextInfo, key)) {
          const value = attachment.contextInfo[key];
          this.queryParams += `&${key}=${value}`;
        }
      }
    }

    const headers = new HttpHeaders();
    const formData: FormData = new FormData();
    formData.append('file', attachment.file);
    formData.append('description', attachment.name);

    const req = new HttpRequest('POST', `${ATTACHMENT_UPLOAD_MANDADORY_DOC}?entityId=${attachment.entityId}&entityName=${attachment.entityName}&idMandatoryDoc=${attachment.idMandatoryDoc || null}${this.queryParams}`,
      formData,
      {
        headers,
        reportProgress: true
      }
    )

    return this.http.request(req).pipe(retry(3));
  }

  deleteAttachment(id?:string ): Observable<string> {
    return this.http.delete<string>(`${ATTACHMENT_API}/${id}`)
  }

  deleteEntityAttachment(entityId:string, entityName:string): Observable<string> {
    return this.http.delete<string>(`${ATTACHMENT_API}/${entityId}/${entityName}`)
  }


  updateComment(attachmentId: number, commentRequest: CommentRequestDto): Observable<AttachmentsModel> {

    return this.http.patch<AttachmentsModel>(`${ATTACHMENT_API}/${attachmentId}/comments`, commentRequest);
  }

  downloadFile(data: Blob, name: string) {
    const blobs = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blobs);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove;
  }

  getDownloadFile(idfile: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(
      `${ATTACHMENT_API}/download/${idfile}`,
      options
    );
  }

  getAttachmentsByEntityIdEntityNameAndContextInfo(entityId: number | undefined,
                                                   entityName: string,
                                                   contextInfo: string): Observable<AttachmentsModel[]> {
    return this.http.get<AttachmentsModel[]>(`${ATTACHMENT_API}/${entityId}/${entityName}?contextInfo=${contextInfo}`)
  }
}
