<form nz-form [formGroup]="form" class="new-manual-task-form">
  <div nz-row>
    <div nz-col [nzSpan]="24">
      <nz-form-item class="sl-form-item">
        <nz-form-label nzFor="taskName" nzRequired [nzSm]="24" [nzXs]="24">
          <span class="sl-form-label">Título da tarefa:</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira a tarefa">
          <input nz-input id="txtProjectName" class="new-manual-activity-input" formControlName="taskName"
            placeholder="Descreva a tarefa"
            nzBorderless />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="12">
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="taskDeadline" nzRequired>
          <span class="sl-form-label">Prazo:</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira a data">
          <nz-date-picker id="txtDeadmatLine" nzFormat="dd/MM/YYYY" (ngModelChange)="onChange($event)"
            nzBorderless class="new-manual-activity-input" nzSuffixIcon=""
            nzPlaceHolder="Informe a data"
            formControlName="taskDeadline" [nzDisabledDate]="disabledDate"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="11" nzOffset="1">
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="relatedProject">
          <span class="sl-form-label">Projeto Relacionado:</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-select id="selRelatedProject" nzBorderless nzShowSearch nzAllowClear nzPlaceHolder="Selecione"
            formControlName="relatedProject" [nzSize]="'small'" (ngModelChange)="selectProject($event)">
            <nz-option *ngFor="let project of projectsList" nzLabel="{{ project.name }}"
              nzValue="{{ project.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col [nzSpan]="12">
      <nz-form-item>
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="relatedLicensing">
          <span class="sl-form-label">Licenciamento Relacionado:</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-select id="selRelatedLicensing" nzBorderless nzShowSearch nzAllowClear nzPlaceHolder="Selecione"
            formControlName="relatedLicensing" [nzSize]="'small'" [nzDisabled]="licensingDisabled">
            <nz-option *ngFor="let licensing of licensingList" nzLabel="{{ licensing.title }}"
              nzValue="{{ licensing.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="11" nzOffset="1">
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="userResponsible" nzRequired>
          <span class="sl-form-label">Status:</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24">
          <nz-select id="selstatus" nzBorderless nzShowSearch nzAllowClear nzPlaceHolder="Selecione"
            formControlName="taskStatus" [nzSize]="'small'">
            <nz-option *ngFor="let status of statusList" nzLabel="{{ status.name }}"
              nzValue="{{ status.value }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row nzJustify="end" class="action-buttons-container">
    <button id="btnCancelar" class="btn-sl btn-sl-grey btn-cancel" (click)="showCancelConfirm()">
      Cancelar
    </button>
    <button id="btnCriar" type="button" class="btn-sl btn-sl-green btn-save" (click)="submitForm()"
      [disabled]="!form.valid">
      Criar
    </button>
  </div>
</form>
