<div class="app-select-simple sl__form">
    <div class="sl__form__label">
        <span class="sl__form__required" *ngIf="required">*</span>
        <span class="sl__form__title">{{ name }}</span>
    </div>

    <nz-select
        [nzBorderless]="true"
        [nzStatus]="status"
        [nzId]="id"
        nzAllowClear
        [nzPlaceHolder]="placeHolder"
        [formControl]="controls"
        [nzShowSearch]="showSearch"
        (ngModelChange)="onModelChange($event)"
        [nzDisabled]="isDisabled">
        <nz-option
            *ngFor="let item of listOfOption"
            [nzLabel]="item.label"
            [nzValue]="item.value">
        </nz-option>
    </nz-select>

    <div class="error" *ngIf="(errorMessage !== '')">
        {{ errorMessage }}
    </div>
</div>
