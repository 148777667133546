import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-icon-secondary',
  templateUrl: './button-icon-secondary.component.html',
  styleUrls: ['./button-icon-secondary.component.scss']
})
export class ButtonIconSecondaryComponent {
  @Input() id?: string = '';
  @Input() icon: string = '';
  @Input() fontSize?: string = '12px';
  @Input() text: string = '';
  @Input() width?: string = '';
  @Input() altText?: string = '';
  @Input() customClassBtn?: string = '';
  @Input() customClass?: string = '';
  @Input() border?: boolean = true;
  iconPath: string = '';
  constructor() { }

  getIconPath(iconName: string): string {
    return `./assets/icons/${iconName}.svg`;
  }
}
