import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductNlpDto } from 'src/app/shared/dto/product-nlp.dto';
import { NlpTemporaryProjectModel } from 'src/app/shared/models/nlp-temporary-project.model';
import { environment } from 'src/environments/environment';
import { NLP_PRODUCT_API, NLP_TEMPORARY_PROJECT_LIST_API } from "../../projects/constants/apiEndpointsConstants";

@Injectable({
  providedIn: 'root'
})
export class NlpTempProjectService {

  baseUrl: string = environment.apiUrl
  constructor(private readonly http: HttpClient) {
  }

  createProductNlp(nlpTemporaryProjectDto: ProductNlpDto): Observable<any> {
    return this.http.post<ProductNlpDto>(`${NLP_PRODUCT_API}`, nlpTemporaryProjectDto)
  }

  updateProductNlp(nlpTemporaryProjectDto: ProductNlpDto): Observable<any> {
    return this.http.patch<ProductNlpDto>(`${NLP_PRODUCT_API}/${nlpTemporaryProjectDto.id}`, nlpTemporaryProjectDto)
  }

  getNlpProductById(id: number): Observable<any> {
    return this.http.get<NlpTemporaryProjectModel>(`${NLP_PRODUCT_API}/${id}`)
  }

  getNlpTempProjectById(tempProjectId: number): Observable<any> {
    return this.http.get<NlpTemporaryProjectModel>(`${NLP_PRODUCT_API}/${tempProjectId}/info`)
  }

  getNlpTempProjectList(): Observable<any> {
    return this.http.get<any>(`${NLP_TEMPORARY_PROJECT_LIST_API}`)
  }
}
