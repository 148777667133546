import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dotConverter'
})
export class DotConverterPipe implements PipeTransform {
  transform(value: string, screenWith: string) {
    if(value) {
      if (value.length > 40 && screenWith === '1366') {
        return value.slice(0, 39) + '...';
      } else if (value.length > 70 && screenWith === '1920'){
        return value.slice(0, 69) + '...';
      }
      return value;
    }
    return '-';
  }
}
