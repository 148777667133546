<ng-template #referenceTerm>
  <div>
    <div nz-row class="nlp-reference-term">
      <div nz-col class="nlp-label-add-new-section" nz-template nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
        <div>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3431 2.34315C11.8434 0.842855 13.8783 0 16 0H64C66.1217 0 68.1566 0.842852 69.6569 2.34315C71.1572 3.84344 72 5.87828 72 8V44C72 46.2091 70.2091 48 68 48C65.7909 48 64 46.2091 64 44V8H16L16 72H44C46.2091 72 48 73.7909 48 76C48 78.2091 46.2091 80 44 80H16C13.8783 80 11.8434 79.1572 10.3431 77.6569C8.84285 76.1566 8 74.1217 8 72V8C8 5.87827 8.84286 3.84344 10.3431 2.34315Z"
                  fill="#007E7A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M24 4C24 1.79086 25.7909 0 28 0H52C54.2091 0 56 1.79086 56 4V16C56 18.1217 55.1571 20.1566 53.6569 21.6569C52.1566 23.1571 50.1217 24 48 24H32C29.8783 24 27.8434 23.1571 26.3431 21.6569C24.8429 20.1566 24 18.1217 24 16V4ZM32 8V16H48V8H32ZM24 44C24 41.7909 25.7909 40 28 40H52C54.2091 40 56 41.7909 56 44C56 46.2091 54.2091 48 52 48H28C25.7909 48 24 46.2091 24 44ZM24 60C24 57.7909 25.7909 56 28 56H44C46.2091 56 48 57.7909 48 60C48 62.2091 46.2091 64 44 64H28C25.7909 64 24 62.2091 24 60ZM68 56C70.2091 56 72 57.7909 72 60V64H76C78.2091 64 80 65.7909 80 68C80 70.2091 78.2091 72 76 72H72V76C72 78.2091 70.2091 80 68 80C65.7909 80 64 78.2091 64 76V72H60C57.7909 72 56 70.2091 56 68C56 65.7909 57.7909 64 60 64H64V60C64 57.7909 65.7909 56 68 56Z"
                  fill="#007E7A"/>
          </svg>
        </div>
        <div class="nlp-label">Para cadastrar as seções do Termo de<br>Referência, clique no botão abaixo:</div>
        <div>
          <button nz-button
                  id="addNewSection"
                  type="button"
                  (click)="addNewSection()"
                  class="btn-sl btn-sl-green btn-sl-nlp"
                  [disabled]="flagDisableTRButtons">
            + Nova Seção
          </button>
        </div>
      </div>
      <div nz-col class="nlp-label-select-tr" nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
        <div>
          <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.3431 2.34315C11.8434 0.842855 13.8783 0 16 0H64C66.1217 0 68.1566 0.842852 69.6569 2.34315C71.1572 3.84344 72 5.87828 72 8V44C72 46.2091 70.2091 48 68 48C65.7909 48 64 46.2091 64 44V8H16L16 72H44C46.2091 72 48 73.7909 48 76C48 78.2091 46.2091 80 44 80H16C13.8783 80 11.8434 79.1572 10.3431 77.6569C8.84285 76.1566 8 74.1217 8 72V8C8 5.87827 8.84286 3.84344 10.3431 2.34315Z"
                  fill="#007E7A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M24 4C24 1.79086 25.7909 0 28 0H52C54.2091 0 56 1.79086 56 4V16C56 18.1217 55.1571 20.1566 53.6569 21.6569C52.1566 23.1571 50.1217 24 48 24H32C29.8783 24 27.8434 23.1571 26.3431 21.6569C24.8429 20.1566 24 18.1217 24 16V4ZM32 8V16H48V8H32ZM24 44C24 41.7909 25.7909 40 28 40H52C54.2091 40 56 41.7909 56 44C56 46.2091 54.2091 48 52 48H28C25.7909 48 24 46.2091 24 44ZM24 60C24 57.7909 25.7909 56 28 56H44C46.2091 56 48 57.7909 48 60C48 62.2091 46.2091 64 44 64H28C25.7909 64 24 62.2091 24 60ZM68 56C70.2091 56 72 57.7909 72 60C72 69.5115 72 68.8811 72 76C72 78.2091 70.2091 80 68 80C65.7909 80 64 78.2091 64 76C64 66.365 64 67.1563 64 60C64 57.7909 65.7909 56 68 56Z"
                  fill="#007E7A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M57.1716 65.1716C58.7337 63.6095 61.2663 63.6095 62.8284 65.1716L68 70.3431L73.1716 65.1716C74.7337 63.6095 77.2663 63.6095 78.8284 65.1716C80.3905 66.7337 80.3905 69.2663 78.8284 70.8284L70.8284 78.8284C69.2663 80.3905 66.7337 80.3905 65.1716 78.8284L57.1716 70.8284C55.6095 69.2663 55.6095 66.7337 57.1716 65.1716Z"
                  fill="#007E7A"/>
          </svg>
        </div>
        <div class="nlp-label">Para selecionar um Termo de Referência<br>pré-cadastrado, clique no botão abaixo:</div>
        <div>
          <button nz-button
                  id="selectTermReference"
                  type="button"
                  (click)="selectTermReference()"
                  class="btn-sl btn-sl-green btn-sl-nlp"
                  [disabled]="flagDisableTRButtons"
          >
            Selecionar TR
          </button>
        </div>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #contentReferenceTerm>
  <app-nlp-content-reference-term></app-nlp-content-reference-term>
</ng-template>


<ng-template
  [ngTemplateOutlet]="flagNeedToCreateDocReferenceTerm  ? referenceTerm : contentReferenceTerm"></ng-template>

