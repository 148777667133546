import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';

import { Activity } from 'src/app/shared/dto/activities.dto';
import { UtilService } from '../../../../../../shared/util.service';
import { LicensingService } from '../../../service/licensing.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

export interface TreeNodeInterface {
  key: string;
  id: string;
  level?: number;
  expand?: boolean;
  title: string;
  responsibleUser?: string;
  dueDate?: string;
  status: string;
  product?: string;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}
@Component({
  selector: 'app-activities-list',
  templateUrl: './activities-list.component.html',
  styleUrls: ['./activities-list.component.scss'],
})
export class ActivitiesListComponent implements OnInit {
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() activities!: Activity[];
  @Input() hasActivityParent?: boolean = false;
  @Input() projectId?: number;
  @Input() processId?: number;
  @Input() activitySubId?: number;
  @Input() titleResponsibleFor: 'edit-processv2' | 'project-view';

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  listOfMapData: TreeNodeInterface[] = [];

  title: string = '';
  route: string = '';
  cadActivityDelete: boolean = false;

  constructor(
    private readonly _router: Router,
    private readonly _modal: NzModalService,
    private readonly _message: MessageService,
    private readonly _utilService: UtilService,
    private _activatedRoute: ActivatedRoute,
    private _licensingService: LicensingService
  ) {}

  ngOnInit() {
    this.cadActivityDelete = this._utilService.getAuthorization(
      'exclude',
      'CADACTIVITY'
    );
    this.title = this.hasActivityParent
      ? 'activities.activity-title'
      : 'activities.sub-activities-title';
  }

  isExistActivity(): boolean {
    return this.activities.length > 0;
  }

  toggle(activity: Activity): void {
    activity.expanded = !activity.expanded;
  }

  deleteActivity(id) {
    this._modal.confirm({
      ...this.buildCustomMessageDelete(),
      nzOnOk: () => {
        this._licensingService.deleteActivity(id).subscribe({
          next: () => {
            this._message.showSucessMessage('Atividade Removida');
            this.activities = this.activities.filter(activity => activity.id != id);
            this.reload.emit(true);
          },
          error: () => {},
        });
      },
    });
  }

  buildCustomMessageDelete(): any {
    return {
      nzTitle: 'Deseja mesmo excluir esta atividade?',
      nzContent:
        'As informações contidas na atividade e subatividades relacionadas serão perdidas e não poderão ser recuperadas.',
      nzOkText: 'Excluir atividade',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }
  goToActivity(id: number) {
    this.getActivityIdsRecursive(id, []);
  }

  private getActivityIdsRecursive(id: number, activityIds: number[]): void {
    this._licensingService.getActivityById(id).subscribe({
      next: (result) => {
        activityIds.push(result.id);
        if (result.activityParentId) {
          this.getActivityIdsRecursive(result.activityParentId, activityIds);
        } else {

          const routeEditActivity = buildRoute(RoutesEnum.EDIT_ACTIVITY, {
            projectId: `${this.projectId}`,
            processId: `${this.processId}`
          });

          const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
            projectId: `${this.projectId}`
          });

          // Ordena o array activityIds do menor para o maior
          activityIds.sort((a, b) => a - b);

          const route = `${routeProject}/${routeEditActivity}/${[...activityIds].join('/')}`;

          this._router.navigate([route], { queryParams: { mode: 'edit' } });

          setTimeout(() => {
            window.location.reload();
          }, 1);
        }
      },
      error: (err) => {
        console.error('Error fetching activity:', err);
      }
    });
  }


  getActivityChildCount(activity: Activity): number {
    if (this._activatedRoute.snapshot.url[2].path !== 'edit-processv2') {
      return activity.children ? activity.children.length - 1 : 0;
    } else {
      return 0;
    }
  }
}
