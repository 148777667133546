import { RoutesEnum } from 'src/app/shared/enum/routes.enum';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { UtilService } from "src/app/shared/util.service";
import { CommunicationsServices } from 'src/app/shared/services/communications.services';
import { CommunicationsListResponseDto } from 'src/app/shared/dto/communications-list-response.dto';

@Component({
  selector: 'app-communication-list',
  templateUrl: './communication-list.component.html',
  styleUrls: ['./communication-list.component.scss']
})
export class CommunicationListComponent implements OnInit {
  @Input() processId!: number;
  @Input() projectId!: number;
  @Input() activityId!: number;
  @Input() licenseId!: number;
  @Input() addingProcess: boolean = false;
  @Input() showResponsibleColumn: boolean = false;
  @Input() communicationsList: CommunicationsListResponseDto[] = [];
  @Input() enableCreation: boolean = true;

  popOverAddRipla2: boolean = false;
  emptyList = true;
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  cadCommunicationsInsert: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private readonly _utilService: UtilService,
    private _communicationsService: CommunicationsServices,
  ) { }

  ngOnInit(): void {
    this.cadCommunicationsInsert = this._utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    if (this.communicationsList.length == 0) {

      if (this.processId !== undefined && this.projectId !== undefined) {
        this.getCommunicationsByProcessId(this.processId);
        return;
      }

      if (this.projectId !== undefined && this.processId === undefined) {
        this.getCommunicationsByProjectId(this.projectId);
      }
    }
    this.checkEmptyList(this.communicationsList);

    if (!this.processId || !this.projectId) {
      this.isParams();
    }
  }

  private getCommunicationsByProjectId(projectId: number): void {
    this._communicationsService.getCommunicationList(projectId).subscribe({
      next: (communicationsList) => {
        this.communicationsList = communicationsList;
        this.checkEmptyList(communicationsList);
      }
    });
  }

  private checkEmptyList(communicationsList: CommunicationsListResponseDto[]) {
    this.emptyList = communicationsList.length <= 0;
  }

  private getCommunicationsByProcessId(processId: number): void {
    this._communicationsService.getCommunicationListByProcessId(processId).subscribe({
      next: (communicationsList) => {
        this.communicationsList = communicationsList.content;
        this.checkEmptyList(communicationsList.content);
      }
    });
  }

  newCommunication(type: string) {
    this.activityId = +window.location.pathname.split(`${RoutesEnum.ACTIVITIES}/`)[1];


    let navigationParams = {
      queryParams:
      {
        projectId: this.projectId,
        processId: this.processId,
        type: type,
        activityId: this.activityId
      }
    };

    this._router.navigate([`/${RoutesEnum.COMMUNICATION}/new`], navigationParams);
  }

  editCommunication(communication: CommunicationsListResponseDto) {
    let navigationParams = { queryParams: { communicationId: communication.id, projectId: this.projectId } };
    this._router.navigate([`/${RoutesEnum.COMMUNICATION}/new`], navigationParams);
  }

  isParams(): void {
    this._activatedRoute.parent?.params
      .subscribe({
        next: (params: Params) => {
          this.projectId = +params['projectId'];
          this.processId = +this._activatedRoute?.snapshot.params['processId'];
          this.activityId = +this._activatedRoute?.snapshot.params['activityId'];
        }
      });
  }

}
