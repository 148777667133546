import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/core/auth/service/auth.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ConfigRoleModel } from 'src/app/shared/models/configRole.model';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  private readonly destroy$: Subject<any> = new Subject();

  visible = false;
  placement: NzDrawerPlacement = 'right';
  roles: ConfigRoleModel[] = [];
  user :any;
  userRegionNames: any = [];

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
    private userService: UserService,
    private readonly message: MessageService,
  ){}

  ngOnInit(): void {
    const rolesRecuperado = sessionStorage.getItem('configRoles');
    const userRecuperado = sessionStorage.getItem('user');
    this.roles = rolesRecuperado ?  JSON.parse(rolesRecuperado): null;
    this.user =  userRecuperado ?  JSON.parse(userRecuperado): null;
    if (this.roles) {
      this.roles = this.roles.filter(function(role, index, self) {
        return self.findIndex(rolb => rolb.description === role.description) === index;
      })
      this.roles.forEach(role => {
        role.description = role.description?.replace('AREA', 'ÁREA')
      })

    }
    this.getUser();
  }


  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  getUser(): void {
    this.userService.getLoogedUser()
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (response) => {
        response.complexes?.forEach( element => {
          this.userRegionNames.push(element.region.description)
        });

        this.userRegionNames = Array.from(new Set(this.userRegionNames));
      },
      error: (err) => {
        this.message.showErrorMessage(err.error.errors.details);
      }
    })
  }

  logout(): void {
    this.auth.logout().subscribe({
      next: (data) => {
      sessionStorage.clear();
      this.auth.login();
      },
      error: (err) => {
        sessionStorage.clear();
        this.auth.login();
      }
    });
  }
}
