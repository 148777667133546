import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {ACTIVITIES_BY_PROJECT_LICENSE_ID_API, CREATE_ACTIVITY} from "../../modules/projects/constants/apiEndpointsConstants";
import {ActivitesByProcessidResponseDto} from "../dto/activites-by-processid-response.dto";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService{
  constructor(private readonly http: HttpClient) { }
  getActivitiesByProjectLicenceId(projectLicenceId: number):Observable<ActivitesByProcessidResponseDto[]> {
    return this.http.get<ActivitesByProcessidResponseDto[]>(`${ACTIVITIES_BY_PROJECT_LICENSE_ID_API}/${projectLicenceId}`)
  }
  
  getTrActivities(projectLicensingId: number): Observable<any> {
    return this.http.get<any>(`${CREATE_ACTIVITY}/tre-activities/${projectLicensingId}`)
  }
}
