<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      <div class="divider-ripla"></div>
      Informações gerais do projeto
      <div class="svg-edit">
        <svg width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             (click)="editProductNlp()">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M14.1395 3.69638L16.3034 5.86034C16.5693 5.48321 16.7068 5.15032 16.7508 4.87173C16.8114 4.48799 16.713 4.10607 16.3034 3.69643C15.8937 3.28679 15.5118 3.18845 15.1281 3.24903C14.8495 3.29302 14.5166 3.43054 14.1395 3.69638ZM15.0712 7.19258L12.8072 4.92857L3.96181 13.774L3.9618 16.038L6.22581 16.038L15.0712 7.19258ZM14.8453 1.4579C15.8653 1.29685 16.8132 1.64179 17.5856 2.41422C18.358 3.18665 18.703 4.13456 18.5419 5.15454C18.3895 6.11976 17.8039 7.02436 16.9946 7.83368L7.24247 17.5858C7.07243 17.7558 6.84182 17.8513 6.60136 17.8513L3.05514 17.8513C2.81468 17.8513 2.58407 17.7558 2.41404 17.5858C2.244 17.4158 2.14848 17.1851 2.14848 16.9447L2.14848 13.3985C2.14848 13.158 2.244 12.9274 2.41403 12.7574L12.1661 3.00525C12.9755 2.19593 13.8801 1.6103 14.8453 1.4579Z"
                fill="#007E7A"/>
        </svg>
      </div>
    </h3>
    <h4 class="card-subtitle" *ngIf="generalInformation?.name">{{generalInformation.name}}</h4>
  </div>
  <div class="card-content">
    <div class="row-content" *ngIf="generalInformation?.responsible">
      <label class="content-a">Responsável: </label>
      <label class="content-b">{{generalInformation.responsible}}</label>
    </div>
    <div class="row-content">
      <label class="content-a">Corredor: </label>
      <label class="content-b" *ngIf="generalInformation?.corridor">{{ generalInformation.corridor }}</label>
      <label class="content-b" *ngIf="!generalInformation?.corridor">{{ 'Não se aplica' }}</label>
    </div>
    <div class="row-content">
      <label class="content-a">Complexo: </label>
      <label class="content-b" *ngIf="generalInformation?.complex">{{generalInformation.complex }}</label>
      <label class="content-b" *ngIf="!generalInformation?.complex">{{ 'Não se aplica' }}</label>
    </div>
  </div>
</div>

<div class="card" *ngIf="generalInformation?.studyType">
  <div class="card-header">
    <h3 class="card-title">
      <div class="divider-ripla"></div>
      Informações gerais do estudo
    </h3>
    <h4 class="card-subtitle" *ngIf="generalInformation?.studyType">{{generalInformation.studyType}}</h4>
  </div>
  <div class="card-content">
    <div class="row-content" *ngIf="generalInformation?.responsibleStudy">
      <label class="content-a">Responsável: </label>
      <label class="content-b">{{generalInformation.responsibleStudy}}</label>
    </div>
    <div class="row-content" *ngIf="generalInformation?.competentOrgan">
      <label class="content-a">Órgão competente: </label>
      <label class="content-b">{{generalInformation.competentOrgan}}</label>
    </div>
  </div>

  <div class="card-content">
    <div class="row-content" *ngIf="generalInformation?.competentOrgan">
      <label class="content-a">Arquivos do estudo: </label>
    </div>
    <div class="anexo" *ngFor="let item of anexo |async ">
      <div class="row-content ">
        <div nz-row
             [nzGutter]="16"
             (click)="downloadFile(item.id, item.name)"
             class="link-download">
          <div nz-col class="gutter-row ">
            <img
              class="demand-file-icon"
              src=" {{ getIconFromFileName(item.name) }}"
              alt="some file"/>
          </div>
          <div nz-col
               class="gutter-row"
               style="cursor: pointer;">
            <div nz-row>
              <label class="content-b link-download">
                {{ item.name.length > 35 ? (item.name | slice:0:30) + '...' + item.name.split('.').pop() : item.name }}
                <!-- {{item.name}} -->
              </label>
            </div>
            <div nz-row>
              <label class="data-anexo link-download">
                {{ item.date | date: 'dd/MM/YYYY ' : brazilTimeZone }}
              </label>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="row-content" *ngIf="generalInformation?.competentOrgan">
      <label class="content-a">Relatórios salvos: </label>
    </div>
    <div class="anexo" *ngFor="let file of reportsFiles | async ">
      <div class="row-content ">
        <div nz-row
             [nzGutter]="16"
             (click)="downloadFile(file.id, file.name)"
             class="link-download">
          <div nz-col class="gutter-row ">
            <img
              class="demand-file-icon"
              src=" {{ getIconFromFileName(file.name) }}"
              alt="some file"/>
          </div>
          <div nz-col
               class="gutter-row"
               style="cursor: pointer;">
            <div nz-row>
              <label class="content-b link-download">
                {{ file.name.length > 35 ? (file.name | slice:0:30) + '...' + file.name.split('.').pop() : file.name }}
              </label>
            </div>
            <div nz-row>
              <label class="data-anexo link-download">
                {{ file.date | date: 'dd/MM/YYYY ' : brazilTimeZone }}
              </label>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
