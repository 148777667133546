import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, } from 'rxjs';

export interface ISelectSearch {
  id?: number;
  description: string;
  code: string;
  value: any;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnDestroy, OnChanges  {

  @Input() clearData: boolean = false;
  @Input() showClear: boolean = false;
  @Input() listOfOption: any[] | undefined;
  @Input() required: boolean = false;
  @Input() optionLabel: string = '';
  @Input() disabled: boolean = false;
  @Input() setManualValue?: any = null;
  
  selectedItem;

  private readonly destroy$: Subject<any> = new Subject();

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnChanges() {
    if(this.clearData === true){
      this.selectedItem = null
    }

    if(!!this.setManualValue) {
      this.selectedItem = this.setManualValue
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  selectItem(event) {
    this.changeValue.emit(event)
  }

}
