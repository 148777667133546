<div class="app-upload-files sl__up">
  <h2 class="h2 mb-10 mt-10">{{ title }}</h2>
  
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="form-col">
    <nz-upload
      *ngIf="!hideDrag" id="btnUploadFile"
      nzType="drag"
      [nzMultiple]="true"
      [nzBeforeUpload]="beforeUpload"
      [(nzFileList)]="fileList"
      [nzRemove]="removeFiles"
      nzListType="picture"
      [nzDownload]="downloadFile"
      [nzAction]="directUpload"
      class="uploadFilesComponent">
      <p class="ant-upload-drag-icon" #uploadInput>
        <img src="assets/icons/upload-v2-icon.svg" alt="upload" />
      </p>
      <p class="ant-upload-hint">
        <span class="text-upload">{{ 'projects.assessment.drag-drop' | translate }}</span>
      </p>
    </nz-upload>
  </div>
</div>