import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GET_SCOPE_ASSOCIATE_IMPACT,
  GET_SCOPE_CATEGORY,
  GET_SCOPE_CHANGE,
  PATCH_SCOPE_CHANGE, PATCH_SCOPE_CHANGE_ANALISIS,
  POST_SCOPE_CHANGE
} from 'src/app/shared/constants/scope-change/apiEndPointsConstants';

import { 
  ScopeCategoryResponse, 
  ScopeChangeConfirmationRequest, 
  ScopeChangeRequest, 
  ScopeChangeResponse, 
  ScopeChangeReviewRequest, 
  ScopeChangeSendReviewToAnalysis, 
  ScopeChangeUpdateReviewRequest } from '../../interface/scope-change-request';
import { ScopeChangeImpactsResponse, ScopeChangePublishFinalRequest } from '../../interface/scope-change-publish-final';
import { SUB_PROJECT_REVIEWS, SUB_PROJECT_REVIEWS_CANCEL, UPDATE_SUB_PROJECT_REVIEWS_ME } from 'src/app/modules/projects/constants/apiEndpointsConstants';
import { ReviewResponse } from 'src/app/shared/models/project-v2-ripla-review-request.model';

@Injectable({
  providedIn: 'root'
})
export class ScopeChangeService {
  baseUrl: string = environment.apiUrl;

  constructor(
    private readonly http: HttpClient
  ) {}

  getScopeCategory(): Observable<ScopeCategoryResponse[]> {
    return this.http.get<ScopeCategoryResponse[]>(`${GET_SCOPE_CATEGORY}`);
  }

  getScopeChange(scopeChangeId: number): Observable<ScopeChangeResponse> {
    return this.http.get<ScopeChangeResponse>(`${GET_SCOPE_CHANGE}/${scopeChangeId}`);
  }

  createScopeChange(request: ScopeChangeRequest): Observable<ScopeChangeResponse> {
    return this.http.post<ScopeChangeResponse>(`${POST_SCOPE_CHANGE}`, request);
  }

  getAssociateImpact(): Observable<ScopeChangeImpactsResponse[]> {
    return this.http.get<ScopeChangeImpactsResponse[]>(`${GET_SCOPE_ASSOCIATE_IMPACT}`);
  }

  scopeChangeConfirmation(request: ScopeChangeConfirmationRequest): Observable<any> {
    return this.http.patch<any>(`${POST_SCOPE_CHANGE}/complete`, request);
  }

  patchPublishFinal(request: ScopeChangePublishFinalRequest): Observable<ScopeChangeResponse> {
    return this.http.patch<ScopeChangeResponse>(`${PATCH_SCOPE_CHANGE}`, request);
  }

  getEstimateList(): Array<{value: string, label: string}> {
    return [
      {value: 'A3MES', label: 'Até 3 meses', },
      {value: 'A6MES', label: 'Até 6 meses'},
      {value: 'E6M1A', label: 'Entre 6 meses e 1 ano'},
      {value: 'E1A2A', label: 'Entre 1 e 2 anos'},
      {value: 'E2A5A', label: 'Entre 2 e 5 anos'},
    ]
  }

  createScopeChangeReview(request: ScopeChangeReviewRequest): Observable<any> {
    return this.http.post<any>(`${SUB_PROJECT_REVIEWS}/scope-change`, request);
  }

  updateScopeChangeReview(request: ScopeChangeUpdateReviewRequest): Observable<any> {
    return this.http.put<any>(`${UPDATE_SUB_PROJECT_REVIEWS_ME}`, request);
  }

  updateUserAnalisis(id, request: ScopeChangeRequest) {
    return this.http.patch<any>(`${PATCH_SCOPE_CHANGE_ANALISIS}/${id}`, request);
  }

  patchCancelSubProjectReview(id: number, request: ScopeChangeSendReviewToAnalysis): Observable<any> {
    return this.http.patch<ReviewResponse>(`${SUB_PROJECT_REVIEWS_CANCEL}/${id}`, request)
  }
}
