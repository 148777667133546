<ol class="breadcrumb">
  <li *ngFor="let page of pages; let i = index">
    <a (click)="emitBreadcrumbSelected(page.id)">
      <span [nz-tooltip]="page.name.length > 30 ? page.name : null">
        {{ page.name.length > 30 ? page.name.slice(0, 27) + '...' : page.name }}
      </span>
    </a>
    <span class="level" *ngIf="i !== pages.length - 1">> </span>
  </li>
</ol>
