import { Component, Input, OnInit, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DocumentListService } from '../../documents-list/services/documents-list.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-link-modal',
  templateUrl: './copy-link-modal.component.html',
  styleUrls: ['./copy-link-modal.component.scss'],
})
export class CopyLinkModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);

  @Input() fileId: any;
  @Input() fileName: any;

  uriLink: string = '';
  constructor(
    private documentsService: DocumentListService,
    private message: NzMessageService,
    private _clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    this.uriLink = this.fileName;
  }

  submitForm() {
  }

  private close() {
    this.#modal.close(true);
  }

  handleCancel(): void {
    this.close();
  }

  copiarLink() {
    const pending = this._clipboard.beginCopy(this.uriLink);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();

    this.message.success('Link copiado com sucesso!', {
      nzDuration: 1500,
    });
  }
}
