import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {UserService} from "./services/user.service";
import {AttachmentsModel} from './models/attachments.model';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import { Video } from './models/video.model';
import { VIDEOS } from 'src/assets/video/videos';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private readonly userService: UserService,
  ) {
  }

  private attachmentRefresh: BehaviorSubject<any> = new BehaviorSubject<any>({})
  attachmentRefresh$ = this.attachmentRefresh.asObservable();


  public updateAttachment(update: boolean) {
    this.attachmentRefresh.next({update})
  }

  public getIconFromFileName(fileName: string): string {
    const type = fileName.split('.')[1];
    const shpTypes = ['shp', 'kmz', 'shx', 'dbf', 'prj', 'dwg', 'sig', 'kml', 'dxf'];
    return shpTypes.includes(type) ? '/assets/icons/shp-icon.svg' : '/assets/icons/pdf-icon.svg';
  }

  public DateFormatter(date: any) {
    let newDate = new Date(date),
      day = newDate.getDate().toString(),
      dayF = (day.length == 1) ? '0' + day : day,
      month = (newDate.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      monthF = (month.length == 1) ? '0' + month : month,
      yearF = newDate.getFullYear(),
      hour = newDate.getHours().toString(),
      hourF = (hour.length == 1) ? '0' + hour : hour,
      minutes = newDate.getMinutes().toString(),
      minutesF = (minutes.length == 1) ? '0' + minutes : minutes,
      seconds = newDate.getSeconds().toString(),
      secondsF = (seconds.length == 1) ? '0' + seconds : seconds

    return yearF + "-" + monthF + "-" + dayF + "T" + hourF + ":" + minutesF + ":" + secondsF
  }

  public DateFormatterWithoutTime(date: any) {

    let newDate = new Date(date),
      day = newDate.getDate().toString(),
      dayF = (day.length == 1) ? '0' + day : day,
      month = (newDate.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      monthF = (month.length == 1) ? '0' + month : month,
      yearF = newDate.getFullYear()

    return yearF + "-" + monthF + "-" + dayF
  }

  blockPreviousDates = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current < currentDate;
  };

  blockFutureDates = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current > currentDate;
  };

  public getAuthorization(authorization: string, action: string): boolean {
    let configRoles = this.userService.getSessionConfigRoles;
    let auth: boolean = false;
    if (!configRoles) {
      this.userService.getConfigRoles().subscribe({
        next: (data) => {
          sessionStorage.setItem('configRoles', JSON.stringify(data));
          configRoles = data;
          auth = this.checkAuthorization(configRoles, action, authorization);
        }, error: (err) => {
          auth = false;
          console.error(err);
        }
      });
    } else {
      auth = this.checkAuthorization(configRoles, action, authorization);
    }
    return auth;
  }

  private checkAuthorization(configRoles, action: string, authorization: string) {
    const roles = configRoles.filter(configRole => configRole.action === action)
    let autorized: boolean = false;
    for (const role of roles) {
      if (role[authorization]) {
        if (role[authorization] === 'S' && !autorized) {
          autorized = true;
        }
      }
    }
    return autorized;
  }

  public getNestedObjectByString(object, path) {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    path = path.replace(/^\./, '');           // strip a leading dot
    var a = path.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (object == null) return;

      if (k in object) {
        object = object[k];
      } else {
        return;
      }
    }
    return object;
  }

  getStatusProcess(object, path) {
    if (object.status === 'INI') {
      return 'A iniciar'
    } else if (object.status === 'EAN') {
      return "Em andamento"
    } else if (object.status === 'ELA') {
      return "Em elaboração"
    } else if (object.status === 'PEM') {
      return "Parecer emitido"
    } else if (object.status === 'ARQ') {
      return "Arquivado"
    } else if (object.status === 'EST') {
      return "Em análise pelo orgão"
    } else if (object.status === 'INF') {
      return "Solicitada informação complementar"
    } else {
      return null
    }
  }

  getStatusLicense(object, path) {
    if (object.status === 'INI') {
      return 'A iniciar'
    } else if (object.status === 'EAN') {
      return "Em andamento"
    } else {
      return null
    }
  }

  formatString(text: string) {
    const tx = text.toLowerCase();
    return tx.charAt(0).toUpperCase() + tx.slice(1);
  }

  validateProjectCode(projectCode: string) {
    let regex = /^$|^[a-zA-z][0-9]{4}-[0-9]{2}$/;
    return regex.test(projectCode);
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]): NzUploadFile[] {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  buildAttachmentModelFromNzUpload(data: NzUploadFile): AttachmentsModel {
    return {
      id: +data.uid,
      description: data.filename || '',
      name: data.name,
      status: data.status
    }
  }

  buildNzUploadFileFromResponse(data: AttachmentsModel): NzUploadFile {
    return {
      description: data.description,
      name: data.name,
      filename: data.name,
      uid: data.id?.toString() || '',
      thumbUrl: this.getIconFromFileName(data.name),
      status: 'done',
    };
  }

  sanitizeInput(input: string): string {
    return input.replace(/<\/?[^>]+(>|$)/g, '');
  }

  // sanitizeInputAllowOnlyItalicTag(input: string): string {
  //   return input.replace(/<\/?(?!i\b)[^>]+(>|$)/g, '');
  // }

  sanitizeInputAllowOnlyItalicTag(input: string): string {
    let input1 = input.replace(/<i\b[^>]*>/g, '#italicstart#').replace(/<\/i>/g, '#italicend#');
    let input2 = this.sanitizeInput(input1);
    return input2.replace(/#italicstart#/g, '<i>').replace(/#italicend#/g, '</i>');
  }

  generateRandomHex(size: number): any {
    const array = new Uint8Array(size);
    window.crypto.getRandomValues(array);
    return Array.from(array).map(b => b.toString(16).padStart(2, '0')).join('');
  }


  returnListIds(states: Array<{  description: string, id: number  }>): number[] {
    return states?.map(state => state.id) || [];
  }

  getRichTextColors(): string[] {
    return [
      "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc",
      "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
      "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00",
      "#666600", "#003700", "#002966", "#3d1466"
    ];
  }

  getVideoByKey(key: string): Video | undefined {
    const videos: Video[] = VIDEOS;
    const video = videos.find(video => video.key === key);
    return video;
  }

  getQueryParams(param: string): string | null {
    if(!window) return '';
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }
}
