import {Component, OnInit} from '@angular/core';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-nlp',
  templateUrl: './nlp.component.html',
  styleUrls: ['./nlp.component.scss']
})



export class NlpComponent   implements OnInit {
  flagTopMenu = false;

  constructor(private service:UserService,
              private activePage:ActivePageService) { }

  ngOnInit(): void {

    this.service.flagTopMenu$.subscribe(flag => {
       this.flagTopMenu = flag;
    });

    this.activePage.setPage('nlp');

  }


}
