import { createReducer, on } from '@ngrx/store';
import { setPages } from './breadcrumb.actions';
import { BreadcrumbState } from './breadcrumb.state.model';

const initialState: BreadcrumbState = {
  pages: []
};

const _breadcrumbReducer = createReducer(
  initialState,
  on(setPages, (state, { pages }) => ({ ...state, pages }))
);

export function breadcrumbReducer(state, action) {
  return _breadcrumbReducer(state, action);
}
