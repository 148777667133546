<section class="app-analysis-about sl__simpleLayout">
  <div class="sl__simpleLayout__card">
    <header class="sl__simpleLayout__card__header">
      <h2 class="sl__simpleLayout__card__header__title">
        <!-- Sobre a requisição -->
        {{ "projects.scope-change.about" | translate }}
      </h2>
    </header>
    <nz-table nzTemplateMode nzSize="small">
      <thead>
        <tr>
          <!-- N˚ da requisição -->
          <th id="reqNumber" class="tableTh">
            {{ "projects.scope-change.request-number" | translate }}
          </th>

          <!-- Responsável pela análise -->
          <th id="responsible" class="tableTh">
            {{ "projects.scope-change.responsible-for-analysis" | translate }}
          </th>

          <!-- Data de cadastro -->
          <th id="registrationDate" class="tableTh">
            {{ "projects.scope-change.registration-date" | translate }}
          </th>

          <!-- Prazo para análise -->
          <th id="deadline" class="tableTh">
            {{ "projects.scope-change.deadline" | translate }}
          </th>

          <!-- Status -->
          <th id="status" class="tableTh" nzWidth="18%">
            {{ "projects.scope-change.status" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="editable-row" id="riplaInfo">
          <td class="tableTdDiscipline">
            <app-short-text-hint
              *ngIf="analysisInformations && analysisInformations.number"
              [originalText]="analysisInformations.number || ''"
              [maxChars]="22"
              class="scopeChangeValue"
            >
            </app-short-text-hint>
          </td>

          <td class="tableTdDiscipline">
            <div *ngIf="editingRow">
              <nz-select 
                class="sl__form__inputFieldSelect inputFieldSelect"
                [(ngModel)]="selectedUserResponsibleIdDiscipline" [compareWith]="compareFn" (ngModelChange)="updateScopeChangeUser($event)" (blur)="stopEdit()"
              >
                <nz-option *ngFor="let user of usersSpecialist" [nzValue]="user.id"
                  [nzLabel]="user.name !== undefined ? user.name : ''"></nz-option>
              </nz-select>
            </div>
            <span class="scopeChangeValue" *ngIf="!editingRow">
              {{ analysisInformations?.responsible?.name }}
            </span>
          </td>

          <td class="tableTd">
            <span class="scopeChangeValue">
              {{ analysisInformations?.registrationDate | date : "dd/MM/yyyy" }}
            </span>
          </td>

          <td class="tableTd">
            <span class="scopeChangeValue">
              {{ analysisInformations?.deadline | date : "dd/MM/yyyy" }}
            </span>
          </td>

          <td class="tableTd statusTd" colspan="2">
            <app-badge
                *ngIf="!!getScopeChangeStatus(analysisInformations)" [badgeText]="getScopeChangeStatus(analysisInformations)"
                [badgeColor]="isDeadLineDate(analysisInformations) ? 'red' : null"></app-badge>
          </td>

          <td>
            <a
              *ngIf="edit && !this.isClosedOrCancelled"
              nz-popover
              nzPopoverPlacement="bottom"
              nzPopoverTrigger="click"
              [nzPopoverContent]="popOverEditRiplaContent"
              [nzPopoverOverlayStyle]="customStyle"
              [(nzPopoverVisible)]="popOverEditRipla"
            >
              <img src="assets/icons/icon_more_vertical.svg" alt="" />
            </a>
          </td>
        </tr>
        <!-- FIM BLOCO PARA ADICIONAR NOVA DISCIPLINA -->
      </tbody>
    </nz-table>
  </div>
</section>

<ng-template #popOverEditRiplaContent class="popOverMargin">
  <div class="btnEditRipla" id="btnEditRipla" (click)="startEdit()">
    <img src="assets/icons/icon_edit.svg" class="mr-10" alt="" />
    {{ "projects.analysis.edit" | translate }}
  </div>
</ng-template>


<!-- <ng-template #popOverEditDisciplineContent class="popOverMarginDiscipline">
  <div class="btnEditRipla mb-10" id="btnEditDiscipline" (click)="startEditDiscipline(scopeChange?.id + '', null)">
    <img src="assets/icons/icon_edit.svg" class="mr-10" alt="" /> Editar
  </div>
</ng-template> -->
