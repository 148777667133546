import {
  Component,
  inject,
  Input,

} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { DisciplineAssessmentDto } from 'src/app/shared/dto/discipline-assessment.dto';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { UserService } from 'src/app/shared/services/user.service';
import { nlpAttachmentsAnalysis } from 'src/app/modules/projects/interface/project.interface';
import { Router } from '@angular/router';
import { NlpAnalysisResultService } from '../nlp-analysis-result.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import {take} from "rxjs";

@Component({
  selector: 'app-nlp-modal-update-tr-result',
  templateUrl: './nlp-modal-update-tr-result.component.html',
  styleUrls: ['./nlp-modal-update-tr-result.component.scss'],
})
export class NlpModalUpdateTRResultComponent {
  readonly #modal = inject(NzModalRef);

  @Input() selectedAssessment?: DisciplineAssessmentDto;
  form!: UntypedFormGroup;
  pathTr: any;
  pathAttachments: any;
  payload: any;
  productNlp;
  loading = false;
  retryCount = 0;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private nlpAnalysisResultService: NlpAnalysisResultService,
    private userService: UserService,
    private readonly msg: NzMessageService,
    private router: Router
  ) {}

  async submitForm() {
    try {
      this.userService.productNlpObservable$.pipe(take(1)).subscribe(async (data) => {
        this.productNlp = data;
        await this.getAttachmentsUrls();
        await this.getTrPath(data.idDocReference);
        this.buildPayload();
        this.loading = true;
      });
    } catch (error) {
      this.msg.error(`Erro ao cadastrar análise!`);
      this.close();
    }
  }

  showCancelConfirm(): void {
    if (this.form.dirty) {
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => this.close(),
      });
    } else {
      this.router.navigate([`nlp`]);
      this.close();
    }
  }

  private getAttachmentsUrls() {
    this.nlpAnalysisResultService
      .getNlpProductAttachmentsUrls(this.productNlp.id)
      .subscribe({
        next: (result) => {
          return (this.pathAttachments = result);
        },
        error: () => {
          this.message.showErrorMessage('Erro ao recuperar Anexos NLP');
        },
      });
  }

  private getTrPath(id) {
    this.nlpAnalysisResultService.getNlpTermSectionAndReference(id)
      .pipe(take(1))
      .subscribe({
      next: (result) => {
        return (this.pathTr = result.data);
      },
      error: () => {
        this.message.showErrorMessage('Erro ao recuperar Json');
      },
    });
  }

  private buildPayload() {
    const prodId = this.productNlp.id;
    const resultNlp = "null";
    if (this.pathTr && this.pathAttachments) {
      const body: nlpAttachmentsAnalysis  = {
        product_id: prodId,
        result_nlp_id: resultNlp,
        path_json_tr: this.pathTr,
        path_document_eia: this.pathAttachments.data,
      };
      this.sendNlpProduct(body);
    } else {
      this.retryPayload();
    }
  }

  retryPayload() {
    if (this.retryCount <= 3) {
      setTimeout(() => this.buildPayload(), 500);
    } else {
      this.loading = false;
      this.msg.error(`Erro ao enviar produto para análise`);
    }
    this.retryCount++;
  }

  private sendNlpProduct(param) {
    if (this.router.url === '/nlp/analise-resultados') {
      this.nlpAnalysisResultService.createNlpAnalysis(param)
        .pipe(take(1))
        .subscribe({
        next: () => {
          this.message.showSucessMessage(`Enviado para análise com sucesso!`);
        },
        error: () =>
          this.message.showErrorMessage(`Erro ao enviar produto para análise`),
      });
      this.loading = false;
      this.close();
    }
  }

  private close() {
    this.#modal.close(true);
  }

  handleCancel(): void {
    this.close();
  }
}
