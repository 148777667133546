import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NLP_SECTION_ANALYSIS_API } from 'src/app/modules/projects/constants/apiEndpointsConstants';
import { SectionEvaluationDto } from 'src/app/shared/dto/section-evaluation.dto';

@Injectable({
  providedIn: 'root'
})
export class NlpModalEvaluateSectionService {

  flagDocumentSection = BehaviorSubject
  documentFlagSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  message$ = this.documentFlagSubject.asObservable();

  baseUrl: string = environment.apiUrl

  constructor(private readonly http: HttpClient) {
  }

  postSectionEvaluation(sectionEvaluation: SectionEvaluationDto): Observable<any> {
    return this.http.post<any>(`${NLP_SECTION_ANALYSIS_API}`, sectionEvaluation)
  }
}
