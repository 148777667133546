import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-textarea-count',
  templateUrl: './input-textarea-count.component.html',
  styleUrls: ['./input-textarea-count.component.scss']
})
export class InputTextareaCountComponent implements OnInit {
  
  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();
  
  @Input() controls: any;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Input() status: any = ' ' || 'error' || 'warning';
  @Input() required: boolean = false;
  @Input() styleResize: string = '';
  @Input() rows: string = '5';
  @Input() maxCharacterCount: number = 1;
  
  constructor() { }

  ngOnInit(): void { return;}

  onChangeValue(event) {
    this.changeValue.emit(event.target.value)
  }

}
