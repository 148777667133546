import { SharedModule } from 'src/app/shared/shared.module';
import { PageLocal } from './../../../shared/ngrx/breadcrumb/pages.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'app-breadcrumb-local',
  templateUrl: './breadcrumb-local.component.html',
  styleUrls: ['./breadcrumb-local.component.scss']
})
export class BreadcrumbLocalComponent {

  @Input() pages!: PageLocal[];
  @Output() breadcrumbSelected: EventEmitter<any> = new EventEmitter();


  constructor() { }

  emitBreadcrumbSelected(id: string | number | undefined): void {
    if(!!id) this.breadcrumbSelected.emit(id);
  }
}
