<div class="content">
  <div class="filter-content">
    <div nz-row>
      <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12">
        <div class="input-group">
          <ng-template #prefixIconSearch>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 17L13.1422 13.1422M15.2222 8.11111C15.2222 9.51756 14.8052 10.8924 14.0238 12.0618C13.2424
            13.2312 12.1318 14.1427 10.8324 14.6809C9.53303 15.2191 8.10322 15.36 6.7238 15.0856C5.34438 14.8112
            4.07731 14.1339 3.0828 13.1394C2.08829 12.1449 1.41102 10.8778 1.13664 9.49842C0.862257 8.119 1.00308
            6.68919 1.5413 5.38981C2.07953 4.09042 2.99098 2.97982 4.16039 2.19844C5.32981 1.41706 6.70467 1 8.11111
            1C9.9971 1 11.8058 1.7492 13.1394 3.0828C14.473 4.41639 15.2222 6.22513 15.2222 8.11111Z"
                stroke="#007E7A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </ng-template>
          <nz-input-group class="search-input" [nzPrefix]="prefixIconSearch">
            <input
              type="text"
              id="txtSearch"
              nz-input
              [(ngModel)]="value"
              (input)="searchFilter()"
              placeholder="Pesquisar..."
            />
          </nz-input-group>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      <div nz-row>
        <div nz-col [nzSpan]="22">
          <h3 class="card-title">Conteúdo do Termo de Referência</h3>
          <h4 class="card-subtitle">
            Quantidade de sessões: <b>{{ sectionsNumbers }}</b>
          </h4>
        </div>
        <div nz-col [nzSpan]="2">
          <span
            style="display: flex; justify-content: flex-end; margin: 15px 0px"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.58579 0.585786C2.96086 0.210714 3.46957 0 4 0H16C16.5304 0 17.0391 0.210713 17.4142 0.585786C17.7893 0.960861 18 1.46957 18 2V11C18 11.5523 17.5523 12 17 12C16.4477 12 16 11.5523 16 11V2H4L4 18H11C11.5523 18 12 18.4477 12 19C12 19.5523 11.5523 20 11 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V2C2 1.46957 2.21071 0.960859 2.58579 0.585786Z"
                fill="#007E7A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 1C6 0.447715 6.44772 0 7 0H13C13.5523 0 14 0.447715 14 1V4C14 4.53043 13.7893 5.03914 13.4142 5.41421C13.0391 5.78929 12.5304 6 12 6H8C7.46957 6 6.96086 5.78929 6.58579 5.41421C6.21071 5.03914 6 4.53043 6 4V1ZM8 2V4H12V2H8ZM6 11C6 10.4477 6.44772 10 7 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H7C6.44772 12 6 11.5523 6 11ZM6 15C6 14.4477 6.44772 14 7 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H7C6.44772 16 6 15.5523 6 15ZM17 14C17.5523 14 18 14.4477 18 15C18 17.3779 18 17.2203 18 19C18 19.5523 17.5523 20 17 20C16.4477 20 16 19.5523 16 19C16 16.5912 16 16.7891 16 15C16 14.4477 16.4477 14 17 14Z"
                fill="#007E7A"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.2929 16.2929C14.6834 15.9024 15.3166 15.9024 15.7071 16.2929L17 17.5858L18.2929 16.2929C18.6834 15.9024 19.3166 15.9024 19.7071 16.2929C20.0976 16.6834 20.0976 17.3166 19.7071 17.7071L17.7071 19.7071C17.3166 20.0976 16.6834 20.0976 16.2929 19.7071L14.2929 17.7071C13.9024 17.3166 13.9024 16.6834 14.2929 16.2929Z"
                fill="#007E7A"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div class="card-divider"></div>
    <div class="card-content">
      <div nz-row>
        <div nz-col [nzSpan]="8" class="scroll-container">
          <div
            class="titleSection"
            nz-row
            nzAlign="middle"
            *ngFor="let item of sectionsTitles"
          >
            <a class="linkSection" (click)="scrollTo(item)">{{ item }} </a>
          </div>
          <br />
        </div>
        <div
          #content
          nz-col
          [nzSpan]="16"
          style="width: 100%"
          class="scroll-container"
          style="padding-left: 10px;"
        >
          <div *ngFor="let item of sections" [id]="item.title">
            <div nz-row class="titleSection" nzAlign="middle">
              <div nz-col [nzSpan]="20" class="text-title">{{ item.title }}</div>
              <div
                nz-col
                [nzSpan]="4"
                style="text-align: right; padding-right: 16px"
              >
                <span (click)="delete(item)" class="icontrash">
                  <svg
                    class="icontrash-a"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.58579 0.585786C5.96086 0.210714 6.46957 0 7 0H13C13.5304 0 14.0391 0.210714 14.4142 0.585786C14.7893 0.960859 15 1.46957 15 2V4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6H17V18C17 18.5304 16.7893 19.0391 16.4142 19.4142C16.0391 19.7893 15.5304 20 15 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H5V2C5 1.46957 5.21071 0.960859 5.58579 0.585786ZM7 4H13V2H7V4ZM5 6V18H15V6H5Z"
                      fill="#007E7A"
                    />
                  </svg>
                </span>
                <span (click)="newSection('edit', item)">
                  <svg
                    class="icontrash-a"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_765_2437)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1397 3.69638L16.3036 5.86034C16.5695 5.48321 16.707 5.15032 16.751 4.87173C16.8116 4.48799 16.7132 4.10607 16.3036 3.69643C15.8939 3.28679 15.512 3.18845 15.1283 3.24903C14.8497 3.29302 14.5168 3.43054 14.1397 3.69638ZM15.0714 7.19258L12.8074 4.92857L3.96199 13.774L3.96199 16.038L6.22599 16.038L15.0714 7.19258ZM14.8455 1.4579C15.8654 1.29685 16.8134 1.64179 17.5858 2.41422C18.3582 3.18665 18.7032 4.13456 18.5421 5.15454C18.3897 6.11976 17.8041 7.02436 16.9947 7.83368L7.24265 17.5858C7.07262 17.7558 6.84201 17.8513 6.60154 17.8513L3.05532 17.8513C2.81486 17.8513 2.58425 17.7558 2.41422 17.5858C2.24419 17.4158 2.14866 17.1851 2.14866 16.9447L2.14866 13.3985C2.14866 13.158 2.24419 12.9274 2.41422 12.7574L12.1663 3.00525C12.9756 2.19593 13.8802 1.6103 14.8455 1.4579Z"
                        fill="#007E7A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_765_2437">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
            <div nz-row nzAlign="middle">
              <div class="description">
                {{ item.description }}
              </div>
            </div>

            <div class="card-divider-2">
              <span class="line"></span>
              <span (click)="newSection('position', item)" class="icon-wrapper">
                <svg
                  class="icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle
                    class="icon-circle"
                    cx="10"
                    cy="10"
                    r="9"
                    stroke="#D9D9D9"
                    stroke-width="2"
                  />
                  <path
                    class="icon-path"
                    d="M6 10L14 10"
                    stroke="#D9D9D9"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                  <path
                    class="icon-path"
                    d="M10 6L10 14"
                    stroke="#D9D9D9"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
              <span class="line"></span>
            </div>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="2" nzOffset="20">
          <button
            button
            nz-button
            (click)="newSection('new')"
            class="btn-sl btn-sl-green btn-save botaoNovaSecao"
          >
            + Nova Seção
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
