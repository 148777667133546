import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Zorro Models
import { NzInputModule } from 'ng-zorro-antd/input';

// Components
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { InputTextareaCountComponent } from './input-textarea-count/input-textarea-count.component';



const COMPONENTS = [
  InputTextareaComponent,
  InputTextareaCountComponent
]

const MODELS = [
  NzInputModule
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ...MODELS
  ]
})
export class InputsLicensingModule { }
