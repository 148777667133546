import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {COMMUNICATIONS_API} from "../../modules/projects/constants/apiEndpointsConstants";
import {CommunicationListPageDto, CommunicationsListResponseDto} from "../dto/communications-list-response.dto";
import {CommunicationByIdResponse} from "../dto/communication-by-id-response.dto";

@Injectable({
  providedIn: 'root'
})
export class CommunicationsServices {
  constructor(private readonly http: HttpClient) {
  }

  createCommunication(payload: any): Observable<any> {
    return this.http.post<any>(`${COMMUNICATIONS_API}`, payload);
  }

  updateCommunication(payload: any,communicationId: number): Observable<any> {
    return this.http.patch<any>(`${COMMUNICATIONS_API}/${communicationId}`, payload);
  }

  getCommunicationList(projectId: number): Observable<CommunicationsListResponseDto[]> {
    return this.http.get<CommunicationsListResponseDto[]>(`${COMMUNICATIONS_API}/list-by-project/${projectId}`);
  }

  getCommunicationListByProcessId(processId: number): Observable<CommunicationListPageDto> {
    return this.http.get<CommunicationListPageDto>(`${COMMUNICATIONS_API}/list-by-process/${processId}`);
  }

  getCommunicationById(communicationId: number): Observable<CommunicationByIdResponse> {
    return this.http.get<CommunicationByIdResponse>(`${COMMUNICATIONS_API}/${communicationId}`);
  }

  getCommunicationByType(communicationType: string, processId: number): Observable<CommunicationsListResponseDto[]> {
    return this.http.get<CommunicationsListResponseDto[]>(`${COMMUNICATIONS_API}/by-type-and-process/${communicationType}/${processId}`);
  }
}
