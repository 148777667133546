import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbLevels } from 'src/app/core/shell/navigation.interface';

@Injectable({
  providedIn: 'root',
})
export class TopBreadcrumbService {
  breadcrumbInit = [
    {
      level: 0,
      title: 'Página inicial',
      route: '/projects',
    },
  ];

  private breadcrumbLevels = new BehaviorSubject(this.breadcrumbInit);
  private handleLevels: Array<BreadcrumbLevels> = [];

  constructor() { }

  initBreadcrumb() {
    this.setLevel(this.breadcrumbInit);
  }

  setLevel(level) {
    this.breadcrumbLevels.next(level);
    this.updateLevels();
  }

  getLevels() {
    return this.breadcrumbLevels.asObservable();
  }

  updateLevels() {
    this.breadcrumbLevels.subscribe((item) => {
      this.handleLevels = item;
    });
  }

  reorgLevels(newLevel) {
    if (this.handleLevels.length === 0) {
      this.initBreadcrumb();
    }

    let finalBreadcrumb = this.handleLevels;

    if (newLevel.level || newLevel.level === 0) {
      finalBreadcrumb = this.handleLevels.filter(function (item) {
        if (item.level < newLevel.level) {
          return item;
        }
        return;
      });
      finalBreadcrumb.push(newLevel);
    } else {
      const levelSum = this.handleLevels[this.handleLevels.length - 1].level + 1;
      newLevel.level = levelSum;
      finalBreadcrumb.push(newLevel);
    }
    this.breadcrumbLevels.next(finalBreadcrumb);
  }

  recreateLevels(array) {
    this.breadcrumbLevels.next(array);
  }
}
