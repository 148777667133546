<section class="attachments">
  <header class="attachments__header">
    <div class="h3-size" [ngClass]="{ 'empty-placeholder': disabledBlock }">
      {{ "attachments.title" | translate }}
    </div>
  </header>
  <div class="empty-placeholder" *ngIf="disabledBlock">
    <div class="spacer-placeholder disabled-text">
      <img src="/assets/icons/empty_box-icon.svg" alt="Sem dados" />
      <span>{{ "attachments.empty-placeholder" | translate }}</span>
    </div>
  </div>

  <div *ngIf="!disabledBlock">
    <div
      class="sl-upload"
      [ngClass]="displayType === 'COLUMN' ? 'column-drag' : 'horizontal-drag'"
    >
      <nz-upload
        *ngIf="!hideDrag"
        id="btnUploadFile"
        [nzBeforeUpload]="beforeUpload"
        nzType="drag"
        [nzMultiple]="true"
        [(nzFileList)]="fileList"
        nzListType="picture"
        (nzChange)="onChangeFile($event)"
      >
        <p class="ant-upload-drag-icon" #uploadInput>
          <img src="assets/icons/upload-v2-icon.svg" alt="upload" />
        </p>
        <p class="ant-upload-hint">
          <span class="text-upload">{{
            "projects.assessment.drag-drop" | translate
          }}</span>
        </p>
      </nz-upload>

      <div class="recoveredFiles">
        <div class="loading-spinner" *ngIf="loading"></div>
        <div class="file-row" *ngFor="let doc of data">
          <div class="file-name">
            <app-short-text-hint
              [originalText]="doc.name"
              [maxChars]="37"
            ></app-short-text-hint>
          </div>
          <div class="file-actions">
            <app-svg-icon
              icon="download_document-icon"
              [current]="true"
              (click)="downloadFile(doc.id, doc.name)"
            ></app-svg-icon>
            <app-svg-icon
              icon="trash_document-icon"
              [current]="true"
              (click)="deleteFile(doc.id)"
            ></app-svg-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-spinner" *ngIf="loading"></div>
  </div>
</section>
