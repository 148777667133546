<div nz-row class="mb-20 mt-10">
  <div nz-col [nzSpan]="15">
    <nz-input-group
      [nzPrefix]="prefixIconSearch"
      nzSize="large"
      class="search-input"
    >
      <input
        id="txtPesquisar"
        nz-input
        placeholder="Pesquisar..."
        [(ngModel)]="stringToSearch"
        (input)="searchFilter()"
        disabled="true"
      />
    </nz-input-group>
    <ng-template #prefixIconSearch>
      <span nz-icon nzType="search"></span>
    </ng-template>
  </div>

  <div nz-col [nzSpan]="9" class="optionSelects">
    <div class="select-title">Projeto</div>
    <nz-select
      nzAllowClear
      [nzBorderless]="true"
      [(ngModel)]="filterByProject"
      name="project"
      id="project"
      class="project"
      (ngModelChange)="filterByProjectElements()"
    >
      <nz-option
        nzValue="{{ project.projectId }}"
        nzLabel="{{ project.projectName }}"
        *ngFor="let project of originalList"
      ></nz-option>
    </nz-select>
  </div>
</div>
