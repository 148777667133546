<tbody class="app-historic-element">
  <tr *ngFor="let historic of selectHistory">
    <td width="6%"
      [nzChecked]="checked"
      (nzCheckedChange)="sendOnItemCheking(historic.id, $event, historic)"
    ></td>
    <td style="width: 27.8% !important;">
      <div>
        <img
          class="demand-file-icon"
          src=" {{ getIconFromFileName(historic.description) }}"
          alt="some file"
        />

        <span id="txtProjectName {{ historic?.id }}" class="tableData" href="">
          {{ historic.name.length > 50 ? (historic.name | slice:0:45) + '...' + historic.name.split('.').pop() : historic.name }}
        </span>
      </div>
    </td>
    <td style="width: 10.5% !important;">
      <span
        class="cells-text-normal"
        style="max-width: 5% !important;"
        id="txtProjectName {{ historic?.id }}"
        href=""
      >
        {{ historic?.version }}
      </span>
    </td>
    <td style="width: 10% !important;">
      <span
        class="cells-text-normal"
        id="txtProjectName {{ historic?.id }}"
        href=""
      >
        {{ historic?.comments || '-'  }}
      </span>
    </td>
    <td style="width: 10.3% !important;">
      <span class="cells-text-normal" id="txtUpdateDate {{ historic?.date }}">{{
        historic?.date | date : "dd/MM/yyyy"
      }} às {{
        historic?.date | date : "HH:mm"
      }}</span>
    </td>
    <td style="width: 10.3% !important;">
      <span
        class="cells-text-normal"
        id="txtResponsible{{ historic?.user?.id }}"
        >{{ historic?.user?.name }}</span
      >
    </td>

    <!-- MENU LATERAL -->
    <td style="padding: 0 !important" width="2%">
      <div>
        <button
          class="buttonMenuFlow"
          nz-button
          nz-dropdown
          [nzDropdownMenu]="menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <ellipse
              cx="10.5"
              cy="10.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-90 10.5 10.5)"
              fill="#007E7A"
            />
            <ellipse
              cx="10.5"
              cy="4.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-90 10.5 4.5)"
              fill="#007E7A"
            />
            <ellipse
              cx="10.5"
              cy="16.5"
              rx="1.5"
              ry="1.5"
              transform="rotate(-90 10.5 16.5)"
              fill="#007E7A"
            />
          </svg>
        </button>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            class="itemMenuFlow"
            (click)="commentOnFile(historic.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.54 10.82L13.3333 14L10.0333 12.3467C9.37662 12.5597 8.69039 12.6677 8 12.6667C4.66667 12.6667 2 10.28 2 7.33333C2 4.38667 4.66667 2 8 2C11.3333 2 14 4.38667 14 7.33333C13.989 8.64197 13.4648 9.89401 12.54 10.82Z"
                stroke="#007E7A"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.9668 7.33337H8.03346"
                stroke="#007E7A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2666 7.33337H5.33327"
                stroke="#007E7A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.6665 7.33337H10.7332"
                stroke="#007E7A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Comentar
          </li>
          <li *ngIf="fileRestore"
            nz-menu-item
            class="itemMenuFlow"
            (click)="restoreFile(historic.id)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2 6.00001H11C11.3942 5.99913 11.7847 6.07613 12.1491 6.22658C12.5135 6.37703 12.8445 6.59798 13.1233 6.87673C13.402 7.15548 13.623 7.48654 13.7734 7.85092C13.9239 8.21529 14.0009 8.6058 14 9.00001V9.00001C14.0009 9.39422 13.9239 9.78472 13.7734 10.1491C13.623 10.5135 13.402 10.8445 13.1233 11.1233C12.8445 11.402 12.5135 11.623 12.1491 11.7734C11.7847 11.9239 11.3942 12.0009 11 12H8.66667"
                stroke="#007E7A"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4 8L2 6L4 4"
                stroke="#007E7A"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Restaurar
          </li>
          <li *ngIf="fileRestore"
            nz-menu-item
            class="itemMenuFlow"
            (click)="deleteFile(historic.id, historic.name)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.46843 0.468629C4.76849 0.168571 5.17546 0 5.5998 0H10.3998C10.8242 0 11.2311 0.168571 11.5312 0.468629C11.8312 0.768687 11.9998 1.17565 11.9998 1.6V3.2H14.3998C14.8416 3.2 15.1998 3.55817 15.1998 4C15.1998 4.44183 14.8416 4.8 14.3998 4.8H13.5998V14.4C13.5998 14.8243 13.4312 15.2313 13.1312 15.5314C12.8311 15.8314 12.4241 16 11.9998 16H3.9998C3.57546 16 3.16849 15.8314 2.86843 15.5314C2.56838 15.2313 2.3998 14.8243 2.3998 14.4V4.8H1.5998C1.15798 4.8 0.799805 4.44183 0.799805 4C0.799805 3.55817 1.15798 3.2 1.5998 3.2H3.9998V1.6C3.9998 1.17565 4.16838 0.768687 4.46843 0.468629ZM5.5998 3.2H10.3998V1.6H5.5998V3.2ZM3.9998 4.8V14.4H11.9998V4.8H3.9998Z"
                fill="#007E7A"
              />
            </svg>
            Excluir
          </li>
        </ul>
      </nz-dropdown-menu>
    </td>
  </tr>
</tbody>
