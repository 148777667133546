import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  NLP_PRODUCT_API
} from 'src/app/modules/projects/constants/apiEndpointsConstants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NlpContentTermService {

  baseUrl: string = environment.apiUrl
  constructor(private readonly http: HttpClient) {
  }

  getListContentTerm(id?:number): Observable<any> {
    return  this.http.get<any>(`${NLP_PRODUCT_API}/${id}`)
  }
}
