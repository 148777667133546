import { Component, Input, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DocumentListService } from '../../documents-list/services/documents-list.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-restore-file-modal',
  templateUrl: './restore-file-modal.component.html',
  styleUrls: ['./restore-file-modal.component.scss'],
})
export class RestoreFileModalComponent {
  readonly #modal = inject(NzModalRef);

  @Input() fileId;

  constructor(
    private documentsService: DocumentListService,
    private message: NzMessageService
  ) {}

  submitForm() {
    this.documentsService.restoreVersionFile(this.fileId).subscribe(
      (result) => {
        if (!result) {
          this.message.success('Documento restaurado!', {
            nzDuration: 1500,
          });
        } else {
          this.message.error('Erro ao restaurar arquivo!', {
            nzDuration: 1500,
          });
        }
      }
    );
    this.close();
  }

  private close() {
    this.#modal.close(true);
  }

  handleCancel(): void {
    this.close();
  }
}
