import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {NlpResultResponseDto} from "./result-body/dto/nlp-result-response.dto";
import {environment} from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { PRODUCT_NLP_ATTACHAMENTS_URLS, NLP_DOCUMENT_SECTION_REF, NLP_QUEUE_ANALYSIS, NLP_PRODUCT_API } from '../../projects/constants/apiEndpointsConstants';
import {SectionCommentsDto} from "./result-body/dto/section-comments.dto";

@Injectable({
  providedIn: 'root'
})
export class NlpAnalysisResultService {
  private resultNlp: BehaviorSubject<NlpResultResponseDto> = new BehaviorSubject<NlpResultResponseDto>({});
  resultNlpData$ = this.resultNlp.asObservable();
  private showRefreshButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showRefreshButtonData$ = this.showRefreshButton.asObservable();

  constructor(private readonly http: HttpClient) {
  }

  updateProductNlp(newResultNlp: NlpResultResponseDto) {
    this.resultNlp.next(newResultNlp);
  }

  updateRefreshButton(newStatus: boolean) {
    this.showRefreshButton.next(newStatus);
  }

  getNlpProductAttachmentsUrls(paramId) {
    return this.http.get<any>(`${PRODUCT_NLP_ATTACHAMENTS_URLS}/${paramId}`);
  }

  getNlpTermSectionAndReference(paramId):Observable<any> {
    return this.http.get<any>(`${NLP_DOCUMENT_SECTION_REF}/${paramId}`);
  }

  createNlpAnalysis(body){
    return this.http.post<any>(`${NLP_QUEUE_ANALYSIS}`, body );
  }

  getProductNlp(id){
    return this.http.get<any>(`${NLP_PRODUCT_API}/${id}`);
  }

  getNlpAnalysisResult(projectTempId: number | undefined): Observable<NlpResultResponseDto> {
    return this.http.get<NlpResultResponseDto>(`${environment.apiUrl}/nlp-result/project-temp/${projectTempId}`);
  }

  saveNlpResultInProductDemand(productNlpId: number | undefined, productDemandId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/product-demand/save-nlp-result`,
      {
        productDemandId: productDemandId,
        productNlpId: productNlpId,
      });
  }

  getSectionComments(resultSectionId: number | undefined): Observable<SectionCommentsDto[]>{
    return this.http.get<any>(`${environment.apiUrl}/sections-comments/section/${resultSectionId}`).pipe(
      map(response => {
        return response.data;
      })
    );
  }
}
