import { Component, inject, Input, OnInit} from '@angular/core'
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms'
import { NzMessageService } from 'ng-zorro-antd/message'
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { DisciplineAssessmentDto } from 'src/app/shared/dto/discipline-assessment.dto'
import { MessageService } from 'src/app/shared/common/services/message.service'
import { NlpModalSelectTermReferenceService } from './service/nlp-modal-select-term-reference.service'
import { NlpTermReferenceModel } from 'src/app/shared/models/nlp-term-reference.model';
import { SelectTermReferenceDto } from 'src/app/shared/dto/select-term-reference.dto';
import {UserService} from "../../../../shared/services/user.service";

@Component({
  selector: 'app-nlp-modal-select-term-reference',
  templateUrl: './nlp-modal-select-term-reference.component.html',
  styleUrls: ['./nlp-modal-select-term-reference.component.scss']
})

export class NlpModalSelectTermReferenceComponent implements OnInit {

  terms: NlpTermReferenceModel[] = [];

  readonly #modal = inject(NzModalRef);

  @Input() selectedAssessment?: DisciplineAssessmentDto
  form!: UntypedFormGroup;

  isProductNlpEmpty: boolean = false;
  productTempId?: number;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private nlpModalSelectTermReferenceService: NlpModalSelectTermReferenceService,
    private readonly msg: NzMessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getSelectedProductNlpId();
  }

  initForm(): void {
    let termReference: any;

    this.form = this.fb.group({
      termReference: [termReference, [Validators.required]]
    });

    this.nlpModalSelectTermReferenceService.getTermReferenceList().subscribe(
      terms => {
        this.terms = terms;
      }
    );
  }

  private buildPayload(): SelectTermReferenceDto {

    let termReferenceName = (document.getElementById("selTermReference") as HTMLInputElement).innerText;

    return {
      projectId: this.productTempId,
      description: termReferenceName
    };
  }

  private getSelectedProductNlpId(): void {
    this.userService.productNlpObservable$.subscribe(productNlp => {
      if (Object.keys(productNlp).length === 0) {
        this.isProductNlpEmpty = true;
      } else {
        this.isProductNlpEmpty = false;
        this.productTempId = productNlp.id;
      }
    });
  }

  submitForm(): void {
    const selectTermReference: SelectTermReferenceDto = this.buildPayload();

    this.nlpModalSelectTermReferenceService.postSelectTermReference(selectTermReference)
      .subscribe(result => {
        result

        this.close();
      }, error => {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        this.msg.error(`Erro ao selecionar termo de referência: ${error.message}`)
      });
  }

  showCancelConfirm(): void {
    if(this.form.dirty){
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => this.close(),
      });
    } else {
      this.close();
    }
  }

  private close() {
   this.#modal.close(true);
  }

  handleCancel(): void {
    this.#modal.triggerCancel();
  }

  updateFlagSubject(flag:boolean) {
    this.nlpModalSelectTermReferenceService.documentFlagSubject.next(flag);
  }
}
