<div class="filter-content ">
  <div nz-row>
    <div nz-col
         [nzXs]="24"
         [nzSm]="24"
         [nzMd]="24"
         [nzLg]="12"
         [nzXl]="12">
      <div class="input-group">
        <ng-template #prefixIconSearch>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 17L13.1422 13.1422M15.2222 8.11111C15.2222 9.51756 14.8052 10.8924 14.0238 12.0618C13.2424
      13.2312 12.1318 14.1427 10.8324 14.6809C9.53303 15.2191 8.10322 15.36 6.7238 15.0856C5.34438 14.8112
      4.07731 14.1339 3.0828 13.1394C2.08829 12.1449 1.41102 10.8778 1.13664 9.49842C0.862257 8.119 1.00308
      6.68919 1.5413 5.38981C2.07953 4.09042 2.99098 2.97982 4.16039 2.19844C5.32981 1.41706 6.70467 1 8.11111
      1C9.9971 1 11.8058 1.7492 13.1394 3.0828C14.473 4.41639 15.2222 6.22513 15.2222 8.11111Z" stroke="#007E7A"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </ng-template>
        <nz-input-group
          class="search-input"
          [nzPrefix]="prefixIconSearch">
          <input type="text"
                 id="txtSearch"
                 nz-input
                 placeholder="Pesquisar..."
                 [(ngModel)]="filterText"
                 (input)="searchFilter()"/>
        </nz-input-group>
      </div>
    </div>

    <div nz-col
         [nzXs]="24"
         [nzSm]="24"
         [nzMd]="24"
         [nzLg]="6"
         [nzXl]="6">
      <div class="filter-select">
        <label>Seções</label>
        <nz-select
          [(ngModel)]="filterSection"
          (ngModelChange)="searchFilter()"
          nzBorderless>
          <nz-option
            nzLabel="Todas as seções"
            nzValue=""
          ></nz-option>
          <nz-option
            *ngFor="let nlpResult of resultAnalysisNlpOriginal?.resultSectionNlpAndTrResponseList"
            nzLabel="{{ nlpResult.documentSection.title }}"
            nzValue="{{ nlpResult.documentSection.title }}"
          ></nz-option>
        </nz-select>
      </div>
    </div>

    <div nz-col
         [nzXs]="24"
         [nzSm]="24"
         [nzMd]="24"
         [nzLg]="6"
         [nzXl]="6">
      <div class="filter-select">
        <label>Aderência ao TR</label>
        <nz-select
          nzBorderless
          [(ngModel)]="filterAderencia"
          (ngModelChange)="searchFilter()">
          <nz-option nzValue="" nzLabel="Todas"></nz-option>
          <nz-option nzValue="alta" nzLabel="Alta aderência"></nz-option>
          <nz-option nzValue="media" nzLabel="Média aderência"></nz-option>
          <nz-option nzValue="baixa" nzLabel="Baixa aderência"></nz-option>
          <nz-option nzValue="naoencontrada" nzLabel="Seção não encontrada"></nz-option>
        </nz-select>
      </div>
    </div>
  </div>
</div>

<div class="result-body">
  <nz-card class="nlp-result-body" *ngIf="resultAnalysisNlp">
    <div nz-row>
      <div nz-col nzSpan="12">
        <h2 class="mb-0">Conteúdo do Estudo</h2>
        <div>
          <span>Quantidade de seções: <b>{{resultAnalysisNlp.nlpSectionCount}}</b></span>
        </div>
      </div>
      <div nz-col nzSpan="12">
        <h2 class="mb-0">Conteúdo do Termo de Referência</h2>
        <div>
          <span>Quantidade de seções: <b>{{resultAnalysisNlp.trSectionCount}}</b></span>
        </div>
      </div>
    </div>

    <nz-divider class="mt-10 mb-10"></nz-divider>

    <nz-collapse nzGhost>
      <nz-collapse-panel
        *ngFor="let section of resultAnalysisNlp?.resultSectionNlpAndTrResponseList"
        [nzHeader]="panelTitle"
        [nzActive]="getActiveSection(section)"
        [nzDisabled]="false"
        [class]="section.resultSections ? section.resultSections.statusColor : 'secaoNaoEncontrada'">
        <ng-template #panelTitle>
          <div nz-row style="width: 100%" class="sectionTitle">
            <div nz-col nzSpan="12" *ngIf="!section.resultSections || section.resultSections.statusColor === 'secaoNaoEncontrada'">
              <span class="tituloSecao">Seção não encontrada</span>
            </div>
            <div nz-col nzSpan="12" *ngIf="section.resultSections && section.resultSections.statusColor !== 'secaoNaoEncontrada'">
              <span class="tituloSecao mr-10">{{ section.resultSections.sectionTitle }}</span>
              <span nz-icon
                    nzType="info-circle"
                    nzTheme="outline"
                    [nzTooltipTitle]="toolTipTitleTemplate"
                    nzTooltipPlacement="bottom"
                    nz-tooltip></span>
              <ng-template #toolTipTitleTemplate let-thing>
                <app-items-to-verify [resultSectionId]="section.resultSections.id"></app-items-to-verify>
              </ng-template>
            </div>
            <div nz-col nzSpan="12" *ngIf="section.documentSection" class="tituloSecao">{{ section?.documentSection?.title }}</div>
          </div>
        </ng-template>
        <div nz-row style="width: 100%; margin-left: 10px;">
          <div nz-col nzSpan="12" class="textBody pr-10" *ngIf="section.resultSections && section.resultSections.statusColor !== 'secaoNaoEncontrada'">
            {{ section.resultSections.sectionDescription }}
            <nz-divider></nz-divider>
            <div class="sectionAnalysis">
              <b class="mb-10">Analise da Seção</b>
              <div class="actionButtons">
                <a>
                  <img ngSrc="../../../../../assets/icons/icon_unlike.svg"
                        height="20"
                        width="20"
                        alt="Desaprovar"
                        class="mr-10"
                        (click)="evaluateSection(section.resultSections.id, false)">
                </a>
                <a>
                  <img ngSrc="../../../../../assets/icons/icon_like.svg"
                        height="20"
                        width="20"
                        alt="Aprovar"
                        (click)="evaluateSection(section.resultSections.id, true)">
                </a>
              </div>
            </div>
            <p class="statusSection" *ngIf="!section.resultSections.sectionAnalysis">Status: <b>Não Analizada</b></p>
            <div *ngIf="section.resultSections.sectionAnalysis" class="mt-10">
              <div nz-row>
                <div nz-col nzSpan="8">
                  <p class="statusSection">Responsável: <b>{{section.resultSections.sectionAnalysis.userName}}</b>
                  </p>
                </div>
                <div nz-col nzSpan="8">
                  <p class="statusSection">Data/Hora:
                    <b>{{ section.resultSections.sectionAnalysis.analysisDate+"Z" | date: 'dd/MM/yyyy HH:mm' : '-0300'}}</b>
                  </p>
                </div>
                <div nz-col nzSpan="8">
                  <p class="statusSection">Status: <b
                    class="{{section.resultSections.sectionAnalysis.isApproved|lowercase}}">
                    {{section.resultSections.sectionAnalysis.isApproved|titlecase}}</b>
                  </p>
                </div>
              </div>
              <div nz-row>
                <div nz-col nzSpan="24">
                  <p class="statusSection" style="margin-bottom: 0;">Observações:</p>
                  <p>{{section.resultSections.sectionAnalysis.comments}}</p>
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzSpan="12" class="textBody pr-10" *ngIf="!section.resultSections || section.resultSections.statusColor === 'secaoNaoEncontrada'">
            <p class="statusSection">Status: <b>Não encontrada</b></p>
          </div>
          <div nz-col nzSpan="12" class="textBody" *ngIf="section.documentSection">
            {{ section.documentSection.description }}
          </div>
        </div>

      </nz-collapse-panel>
    </nz-collapse>

  </nz-card>
  <div class="analysisLegend mt-20">
    <div class="mr-10"><span class="legendaAltaAderencia mr-10 borderRadius5"></span> Alta aderência do TR</div>
    <div class="mr-10"><span class="legendaMediaAderencia mr-10 borderRadius5"></span> Média aderência do TR</div>
    <div class="mr-10"><span class="legendaBaixaAderencia mr-10 borderRadius5"></span> Baixa aderência do TR</div>
    <div class="mr-10"><span class="legendaSecaoNaoEncontrada mr-10 borderRadius5"></span> Seção não encontrada</div>
  </div>
</div>
