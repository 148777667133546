import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconCustomComponent } from './svg-icon-custom/svg-icon-custom.component';


const COMPONENTS = [
  SvgIconCustomComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule
  ]
})
export class IconsLicensingModule { }
