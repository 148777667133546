import { Component } from "@angular/core";
import { Alert, AlertMessageService } from "src/app/shared/services/alert-message.service";

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent {
  public alerts: Alert[] = []

  constructor(private alertService: AlertMessageService) {
    alertService.alertSent$.subscribe(item => this.alertSent(item));
  }
  alertSent(alert: Alert) {
    this.removeMarginToHeader();
    alert.id = Date.now().toString();
    this.alerts.push(alert);

    setTimeout(() => {
      this.alerts.shift()
      this.addMarginToHeader();
    }, 4000)
  }

  removeAlert(id: string | undefined) {
    if (id) {
      this.alerts = this.alerts.filter(alert => alert.id !== id);
      this.addMarginToHeader();
    }
  }


  addMarginToHeader() {
    const header: any = document.querySelector(".ant-layout-header");
    if (header) {
      header.style.marginBottom = '8px';
    }
  }

  removeMarginToHeader() {
    const header: any = document.querySelector(".ant-layout-header");
    if (header) {
      header.style.marginBottom = '0px';
    }
  }
 
}