import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {OptionsDto} from "../dto/options.dto";
import {GET_IMPACTS, GET_REASONS} from "../constants/project/projectApiEndpointsConstants";

@Injectable({
  providedIn: 'root'
})
export class ImpactsService {

  constructor(private readonly http: HttpClient) { }

  getImpactsListByType(type: string): Observable<OptionsDto[]> {
    return this.http.get<OptionsDto[]>(`${GET_IMPACTS}?type=${type}`);
  }
}
