import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ImenuConfig {
  title: string;
  eventType: string;
  icon: string;
}

@Component({
  selector: 'app-button-options',
  templateUrl: './button-options.component.html',
  styleUrl: './button-options.component.scss'
})
export class ButtonOptionsComponent {

  @Input() title: string = 'Ações';
  @Input() popoverPlacement: 'bottomRight' | 'bottomLeft';
  @Input() styleCss: string = '';
  @Input() menuConfig: ImenuConfig[] = [];
  
  @Output() clickActionMenu: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onClickActionMenu(eventType: string): void {
    this.clickActionMenu.emit(eventType);
  }
  
  getIconPath(iconName: string): string {
    return `./assets/icons/${iconName}.svg`;
  }

}
