import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import { ProjectsService } from '../../projects.service'
import { ProjectModel } from 'src/app/shared/models/project.model'
import { CompetentOrgan } from 'src/app/shared/models/competent-organ.model'

@Injectable({
  providedIn: 'root'
})
export class ProjectsRiplaReportService {
  baseUrl: string = environment.apiUrl
  id: number = 0
  project!: ProjectModel;
  constructor(private readonly http: HttpClient,
    private projectService: ProjectsService) { }

  getCompetentOrgans(): Observable<CompetentOrgan[]>{
    return this.http.get<any>(`${this.baseUrl}/competent-organs`)
  }

}
