import {Component, inject, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {AttachmentsService} from "../../../../shared/services/attachments.service";
import {CommentRequestDto} from "./CommentRequestDto";
import { MessageService } from 'src/app/shared/common/services/message.service';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements OnInit {
  isSaving: boolean = false;
  form!: UntypedFormGroup;
  @Input() attachmentId;
  @Input() comments = '';
  readonly #modal = inject(NzModalRef);

  constructor(private readonly fb: UntypedFormBuilder,
              private readonly attachmentsService: AttachmentsService,
              private readonly modal: NzModalService,
              private readonly message: MessageService,) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    let comments = this.comments;

    this.form = this.fb.group({
      comments: [comments, [Validators.required]]
    });
  }

  handleCancel() {
    this.#modal.destroy()
  }

  showCancelConfirm(): void {
    if (this.form.dirty) {
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => this.handleCancel(),
      });
    } else {
      this.handleCancel();
    }
  }

  submitForm() {
    if (this.form.valid) {

      let commentRequestDto: CommentRequestDto =  this.buildPayload();

      this.attachmentsService.updateComment(this.attachmentId, commentRequestDto).subscribe({
        next: () => this.successMessage("Comentário salvo!"),
        error: (err) => this.errorMessage("Não foi possível salvar o comentário.")
      });

    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  private errorMessage(message: string){
    this.message.showErrorMessage(message);
    this.handleCancel();
  }

  private successMessage(message: string){
    this.message.showSucessMessage(message);
    this.handleCancel();
  }

  private buildPayload(): CommentRequestDto {
    return {
      comments: this.form?.value?.comments,
    };
  }
}
