import {Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {models} from 'powerbi-client';
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../../../../core/auth/service/auth.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-dashboard-licensing',
  templateUrl: './dashboard-licensing.component.html',
  styleUrls: ['./dashboard-licensing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardLicensingComponent implements OnInit {

  embedConfig: any;
  @ViewChild('powerBiFrame') powerBiFrame: ElementRef<HTMLIFrameElement>;

  workspace: string = environment.workspace
  dashLicensing: string = environment.dashLicensing

  constructor(
    private renderer: Renderer2,
    private readonly http: HttpClient,
    private readonly authService: AuthService
  ) {
  }

  ngOnInit(): void {

    this.authService.GetServicePrincipalAccessToken(this.dashLicensing, this.workspace).subscribe({
      next: data => {
        if (data) {
          this.embedConfig = {
            type: "report",
            id: this.dashLicensing,
            embedUrl: "https://app.powerbi.com/reportEmbed?reportId=44c33467-fbc0-4a20-8d7f-bdb924e4f896&groupId=5e45386e-6583-49fc-9989-a6577193f157",
            accessToken: data.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
              layoutType: models.LayoutType.Master,
              zoomLevel: 0.75,
              customLayout: {
                pageSize: {type: models.PageSizeType.Cortana},
                displayOption: models.DisplayOption.FitToWidth,
                reportAlignment: models.ReportAlignment.Center
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false
                }
              },
              background: models.BackgroundType.Transparent,
            }
          }
        }
      }
    })
  }
}
