import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterLastDaysHelper {

constructor() { }

filterTopDays(array, topDays){
  const today = new Date();
  const sevenDaysFromNow = new Date(today);
  sevenDaysFromNow.setDate(today.getDate() - topDays);

  const topDaysFiltered = array.filter((item) => new Date(item.dateTimeNotification) > sevenDaysFromNow);
  const offsetDaysFiltered = array.filter((item) => new Date(item.dateTimeNotification) <= sevenDaysFromNow);


  return [topDaysFiltered, offsetDaysFiltered];
}

}
