import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { FilterLastDaysHelper } from 'src/app/shared/helpers/filter-last-days/filter-last-days.helper';
import {UtilService} from "../../../shared/util.service";

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnChanges, AfterViewInit {
  @Input() listItems: any[] = []; 
  @Output() loadMore = new EventEmitter<void>(); 
  
  top7Days: any[] = [];
  offsetDays: any[] = [];
  
  constructor(
    private readonly filter: FilterLastDaysHelper,
    private readonly utilService: UtilService
  ) { }

  ngOnChanges(){
    this.filterDays();
  }
  
  ngAfterViewInit() {
    this.onScroll();
  }
  
  filterDays(){
    const groupDays = this.filter.filterTopDays(this.listItems, 7);
    this.top7Days = groupDays[0];
    this.offsetDays =  groupDays[1];
  }

  sanitizeInputAllowOnlyItalicTag(text: string): string{
    return this.utilService.sanitizeInputAllowOnlyItalicTag(text);
  }
  
  onScroll() {
    const container = document.querySelector('.your-scrollable-container-class');
    if (container) {
      container.addEventListener('scroll', () => {
        const isBottom = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 1;
        if (isBottom) {
          this.loadMore.emit();
        }
      });
    }
  }
}
  
