import {Injectable} from "@angular/core";
import {TopBreadcrumbService} from "../../../shared/common/services/top-breadcrumb/top-breadcrumb.service";
import {SidebarService} from "../nlp-sidebar/service/sidebar.service";

@Injectable({
  providedIn: 'root'
})
export class BreadCumbNlpService {
  projectName: string | null = '';
  projectId: string | null = '';
  licenseId: string | null = '';
  licenseName: string | null = '';
  demandId: string | null = '';
  productNlpIdLocal: string | null = '';

  constructor(private topBreadcrumb: TopBreadcrumbService,
              private sideBarService: SidebarService) {

    this.productNlpIdLocal = localStorage.getItem('productNlpId');
    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');
    this.licenseId = localStorage.getItem('licenseId');
    this.demandId = localStorage.getItem('demandId');
    this.licenseName = localStorage.getItem('licenseName');

    if (this.productNlpIdLocal) {
      this.sideBarService.selectedProjectId = this.projectId ? +this.projectId : 0;
    }
  }

  updateBreadCumb() {
    this.topBreadcrumb.reorgLevels({
      level: 1,
      title: `${this.projectName}`,
      route: `projects/${this.projectId}/licensing`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 3,
      title: 'Licenciamentos',
      route: `projects/${this.projectId}/licensing/`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 4,
      title: this.licenseName,
      route: `projects/${this.projectId}/licencing-home/${this.licenseId}`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 5,
      title: 'EIA',
      route: `projects/${this.projectId}/demands/demands-eia/${this.demandId}`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 6,
      title: 'Análise NLP',
      route: ``,
    });
  }

}
