import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzSpinModule } from 'ng-zorro-antd/spin';

import { HttpLoaderFactory } from '../../app.module';
import { CommunicationsRoutingModule } from './communications-routing.module';

import { CommunicationsFormComponent } from './communications-form/communications-form.component';
import { CommunicationsListComponent } from './communications-list/communications-list.component';
import { CommunicationNewComponent } from './communications-form/communication-new/communication-new.component';
import { CommunicationSummaryComponent } from './communications-form/communication-summary/communication-summary.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CommunicationNewComponent,
    CommunicationsListComponent,
    CommunicationsFormComponent,
    CommunicationSummaryComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NzFormModule,
    NzGridModule,
    NzModalModule,
    NzRadioModule,
    NzInputModule,
    NzTableModule,
    NzUploadModule,
    NzSelectModule,
    NzLayoutModule,
    NzPopoverModule,
    NzToolTipModule,
    NzDividerModule,
    NzDropDownModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzTreeSelectModule,
    NzSpinModule,
    ReactiveFormsModule,
    CommunicationsRoutingModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class CommunicationsModule { }
