import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { Activity } from 'src/app/shared/dto/activities.dto';
import { LicensingService } from '../../../service/licensing.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { buildRoute, RoutesEnum } from 'src/app/shared/enum/routes.enum';

@Component({
  selector: 'app-activities-container',
  templateUrl: './activities-container.component.html',
  styleUrls: ['./activities-container.component.scss'],
})
export class ActivitiesContainerComponent implements OnChanges {
  @Input() id: number;
  @Input() isEIA?: boolean;
  @Input() parentId?: number;
  @Input() projectId?: number;
  @Input() disabledBlock?: boolean;
  @Input() activitySubId: number = 0;
  @Input() addingProcess: boolean = false;
  @Input() dinamicTitle: 'edit-processv2' | 'project-view';

  @Output() hasActivity: EventEmitter<boolean> = new EventEmitter<boolean>();

  type: 'ACT' | 'SUB';
  data: Activity[] = [];
  eiaStudys: Activity[] = [];
  cadActivityInsert: boolean = false;
  componentTitle = 'activities.sub-activities-title';
  emptyPlaceholder = 'activities.empty-sub-activities-placeholder';

  constructor(
    private readonly _router: Router,
    private readonly _message: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _licensingService: LicensingService
  ) {}

  ngOnChanges(): void {
    if (this.disabledBlock) {
      return;
    }
    this.checkComponentType();
  }

  onReload(event): void {
    if (event) {
      this.checkComponentType();
    }
    this.getActivities(this.id, this.parentId);
  }

  checkComponentType() {
    if (this.parentId) {
      this.componentTitle = 'activities.sub-activities-title';
      this.emptyPlaceholder = 'activities.empty-activity-placeholder';
      this.type = 'SUB';
    } else {
      this.componentTitle = 'activities.activities-title';
      this.emptyPlaceholder = 'activities.empty-activity-placeholder';
      this.type = 'ACT';
    }
    this.getActivities(this.id, this.parentId);
  }

  getActivities(id: number, parentId: any) {
    this._licensingService.getSubActivitiesList(id, parentId).subscribe({
      next: (response) => {
        this.eiaStudys = [];
        this.data = [];
        response.forEach((item) => {
          if (item.product === 'EIA') {
            this.eiaStudys.push(item);
          } else {
            this.data.push(item);
          }
        });

        this.hasActivity.emit(response.length > 0);
      },
      error: (err) => {
        this._message.showErrorMessage(err.error.errors.details);
      },
    });
  }

  toNewActivity() {

    const routenNewActivity = buildRoute(RoutesEnum.NEW_ACTIVITY, {
      projectId: `${this.projectId}`,
      processId: `${this.id}`,
    });

    const routeProject = buildRoute(RoutesEnum.PROJECT_BY_ID, {
      projectId: `${this.projectId}`
    });

    let activityId = this.parentId
      ? this.parentId
      : Number(this._activatedRoute?.snapshot.params['activitySubId']);

      if (activityId) {
      const routenNewSubActivity = buildRoute(RoutesEnum.NEW_SUB_ACTIVITY, {
        projectId: `${this.projectId}`,
        processId: `${this.id}`,
        activitySubId: `${activityId}`,
        product: 'new',
      });

      this._router.navigate([ `/${routeProject}/${routenNewSubActivity}`]);
    } else {
      this._router.navigate([`${routeProject}/${routenNewActivity}`]);
    }
  }
}
