import {Component, inject, Input} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-success-confirmation',
  templateUrl: './success-confirmation.component.html',
  styleUrls: ['./success-confirmation.component.scss']
})
export class SuccessConfirmationComponent  {
  readonly #modal = inject(NzModalRef);
  @Input() title: string = '';
  @Input() subTitle: string = '';

  constructor() { }

  handleOk() {
    this.#modal.destroy();
  }
}
