import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ATTACHMENT_API, NLP_PRODUCT_API } from 'src/app/modules/projects/constants/apiEndpointsConstants';
import {PRODUCT_NLP_ENTITY_NAME, PRODUCT_NLP_REPORT_ENTITY_NAME} from "../../constants/nlpConstants";
import {GeneralInformationResponse} from "../../interface/nlp-interface";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  selectedProjectId: number = 0

  baseUrl: string = environment.apiUrl
  constructor(private readonly http: HttpClient) {
  }

  getGeneralInformationSidebar(id:number): Observable<GeneralInformationResponse> {
    return this.http.get<GeneralInformationResponse>(`${NLP_PRODUCT_API}/${id}/info`)
  }

  getGeneralInformationSidebarAnexo(id:number): Observable<any> {
    return this.http.get<any>(`${ATTACHMENT_API}/${id}/${PRODUCT_NLP_ENTITY_NAME}`)
  }

  getGeneralInformationReportsSaved(id:number): Observable<any> {
    return this.http.get<any>(`${ATTACHMENT_API}/${id}/${PRODUCT_NLP_REPORT_ENTITY_NAME}`)
  }
}
