import { environment } from 'src/environments/environment';

export const baseUrl = environment.apiUrl;

export const GET_SCOPE_CATEGORY = `${baseUrl}/requisition-reasons?type=SCOPE_CHANGE`;
export const GET_SCOPE_CHANGE = `${baseUrl}/scope-changes-project`;
export const POST_SCOPE_CHANGE = `${baseUrl}/scope-changes-project`;
export const GET_SCOPE_ASSOCIATE_IMPACT = `${baseUrl}/requisition-impacts?type=SCOPE_CHANGE`;
export const PATCH_SCOPE_CHANGE = `${baseUrl}/scope-changes-project/send-final-analysis`;
export const PATCH_SCOPE_CHANGE_ANALISIS = `${baseUrl}/scope-changes-project/user-analisys`;
