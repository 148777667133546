<form nz-form [formGroup]="form" (ngSubmit)="showCancelConfirm()">

  <nz-form-item class="sl-form-item">
    <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="projectClass" [nzNoColon]="true">
      <span class="sl-form-label">Existe um projeto cadastrado? </span>
    </nz-form-label>
    <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor selecione uma classificação do projeto">
      <nz-radio-group [nzDisabled]="editMode" formControlName="projectExists" [(ngModel)]="rbProjectExists">
        <label nz-radio [nzValue]="true" id="rdbSim" (click)="chooseProjectSelectOption(true)">Sim</label>
        <label nz-radio [nzValue]="false" id="rdbNao" (click)="chooseProjectSelectOption(false)">Não</label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12" class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="tempProjectName">
          <span class="sl-form-label">Nome do projeto</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira o nome do projeto" style="">
          <input nz-input
                 id="txtProjectName"
                 formControlName="tempProjectName"
                 nzBorderless
                 *ngIf="!rbProjectExists"
                 type="text"
                 [ngModel]="setTempProjectName"/>
          <nz-select id="selTempProjectName"
                     nzBorderless
                     nzShowSearch
                     nzAllowClear
                     nzPlaceHolder="Selecione o projeto"
                     formControlName="tempProjectId"
                     [nzSize]="'small'"
                     [nzLoading]="isProjectTypesLoading"
                     *ngIf="rbProjectExists "
                     (ngModelChange)="fillSelectsExistingTempProject($event)">
            <nz-option *ngFor="let tempProject of tempProjects" nzLabel="{{ tempProject.description }}"
                       nzValue="{{ tempProject.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="competentOrganId">
          <span id="tt" class="sl-form-label temp-project-infos">Órgão competente</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira o órgão">
          <nz-select id="selTipoRipla"
                     nzBorderless
                     nzShowSearch
                     nzAllowClear
                     nzPlaceHolder="Selecione o órgão"
                     formControlName="competentOrganId"
                     [nzSize]="'small'"
                     [nzLoading]="isProjectTypesLoading"
                     [nzDisabled]="rbProjectExists || editMode"
                     ngModel="{{ setOrganId }}">
            <nz-option *ngFor="let organ of organs"
                       nzLabel="{{ organ.code !== 'Prefeitura' ? organ.code + ' - ' + organ.description : organ.code.concat(' - ', organ.stateCode) }}"
                       nzValue="{{ organ.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="12" class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="studyTypeId">
          <span class="sl-form-label temp-project-infos">Tipo de Estudo</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira o tipo">
          <nz-select id="selStudyType"
                     nzBorderless
                     nzShowSearch
                     nzAllowClear
                     nzPlaceHolder="Selecione o tipo"
                     formControlName="studyTypeId"
                     [nzSize]="'small'"
                     [nzLoading]="isProjectTypesLoading"
                     [nzDisabled]="rbProjectExists || editMode"
                     ngModel="{{ setStudyTypeId }}">
            <nz-option *ngFor="let type of studyTypes" nzLabel="{{ type.description }}"
                       nzValue="{{ type.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="userResponsibleId">
          <span class="sl-form-label temp-project-infos">Responsável pelo estudo</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor insira responsável pelo estudo">
          <nz-select id="selResponsavel"
                     nzBorderless
                     nzShowSearch
                     nzAllowClear
                     nzPlaceHolder="Selecione um responsável"
                     formControlName="userResponsibleId"
                     [nzSize]="'small'"
                     [nzLoading]="isUsersLoading"
                     [nzDisabled]="rbProjectExists  || editMode"
                     ngModel="{{ setUserResponsibleId }}">
            <nz-option *ngFor="let user of users" nzLabel="{{ user.name }}" nzValue="{{ user.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="form-col">
      <nz-upload id="btnUpArquivo"
                 nzType="drag"
                 [nzMultiple]="true"
                 [nzBeforeUpload]="beforeUpload"
                 [(nzFileList)]="fileList"
                 [nzRemove]="removeFiles"
                 nzListType="picture"
                 class="uploadProjectFiles">
        <p class="ant-upload-drag-icon" #uploadInput>
          <img src="assets/icons/upload-icon.svg" alt="upload"/>
        </p>
        <p class="ant-upload-hint">
          Arraste e solte seu arquivo aqui ou clique aqui para fazer upload
        </p>
      </nz-upload>
      <span *ngIf="showAttachmentWarning" class="errorHint">Realize o upload de um documento</span>
    </div>
  </div>

</form>
<div class="ant-modal-footer">
  <div nz-row>
    <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
      <button type="button" class="btn-sl btn-sl-green btn-select-files" (click)="onBrowserFilesToUpload()">Selecionar
        os arquivos
      </button>
    </div>
    <div nz-col nzXs="12" nzSm="12" nzMd="12" nzLg="12" nzXl="12">
      <button class="btn-sl btn-sl-grey btn-cancel" (click)="handleCancel()">Cancelar</button>
      <button type="button"
              class="btn-sl btn-sl-green btn-save"
              [disabled]="(!form.valid) || (fileList.length ===0) "
              (click)="submitForm()">Salvar</button>
    </div>
  </div>
</div>
