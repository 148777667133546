
<ng-container *ngIf="flagTopMenu">
  <app-nlp-topmenu></app-nlp-topmenu>
</ng-container>

<nz-layout>
  <nz-content>
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>



