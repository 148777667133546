<form
  nz-form
  [formGroup]="form">
  <div nz-row>
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      [nzLg]="24"
      [nzXl]="24"
      class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label
          [nzSm]="24"
          [nzXs]="24"
          nzFor="content"
          nzRequired>
          <span class="sl-form-label">Observações:</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="2000">
            <textarea
              rows="15"
              formControlName="comments"
              nz-input
            ></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

</form>

<div class="ant-modal-footer">
  <div nz-row>
    <div nz-col
         [nzXs]="18"
         [nzSm]="18"
         [nzMd]="18"
         [nzLg]="18"
         [nzXl]="18"
         [nzSpan]="18" [nzPush]="6">
      <button class="btn-sl btn-sl-grey btn-cancel"
              (click)="showCancelConfirm()">Cancelar
      </button>
      <button
        type="button"
        class="btn-sl btn-sl-green btn-save"
        [disabled]="form.invalid"
        (click)="submitForm()">Salvar
      </button>
    </div>
    <div nz-col [nzSpan]="6" [nzPull]="18"></div>
  </div>
</div>

