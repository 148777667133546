<section
  nz-col
  nzXs="24"
  nzSm="24"
  nzMd="24"
  nzLg="24"
  nzXl="24"
  class="communicationList__section"
>
  <header class="communicationList__header">
    <h2
      class="communicationList__header__title {{
        processId !== null ? 'color-black' : 'color-grey'
      }}"
    >
      {{ "projects.communication.list-title" | translate }}
    </h2>
    <button
      nz-button
      type="button"
      class="btn"
      *ngIf="cadCommunicationsInsert && processId !== null && !addingProcess"
      nz-popover
      nzPopoverPlacement="leftBottom"
      nzPopoverTrigger="click"
      [nzPopoverContent]="popOverAddRiplaContent"
      [nzPopoverOverlayStyle]="customStyle"
      [(nzPopoverVisible)]="popOverAddRipla2"
    >
      <img
        class="plus__ico"
        src="/assets/icons/icon_plus_circle.svg"
        alt="{{ 'projects.view.add-ripla' }}"
      />
    </button>

    <ng-template #popOverAddRiplaContent class="popOverMargin">
      <div class="btnNewCommunication" (click)="newCommunication('ENV')">
        Comunicação enviada
      </div>
      <div class="btnNewCommunication" (click)="newCommunication('REC')">
        Comunicação recebida
      </div>
    </ng-template>
  </header>
  <div>
    <nz-table
      nzSize="small"
      nzTemplateMode
      nzTableLayout="fixed"
      class="communicationList_table"
      *ngIf="communicationsList.length > 0"
    >
      <thead>
        <tr id="communicationListTable">
          <th id="title" class="communicationList__table__th">
            <span class="communicationsList__table__th__span">
              <!-- Título -->
              {{ "activities.title" | translate }}
            </span>
          </th>
          <th
            id="responsible"
            class="communicationList__table__th"
            *ngIf="showResponsibleColumn"
          >
            <!-- Responsável -->
            {{ "activities.responsible" | translate }}
          </th>
          <!-- Data de recebimento/envio -->
          <th id="date" class="communicationList__table__th">
            {{ "activities.dates" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let communication of communicationsList"
          (click)="editCommunication(communication)"
        >
          <td>
            {{ communication.title }}
          </td>
          <td *ngIf="showResponsibleColumn">
            {{ communication.responsibleUser.name }}
          </td>
          <td>{{ communication.date | date : "dd/MM/yyyy" }}</td>
        </tr>
      </tbody>
    </nz-table>
    <div class="communicationList__noData" *ngIf="emptyList">
      <img
        src="/assets/icons/empty_box-icon.svg"
        alt="Nenhuma comunicação registrada"
      />
      <span class="communicationList__noData__span">
        {{ "licensing-process.no-communication-registered" | translate }}
      </span>
    </div>
  </div>
</section>
