import {
  DocumentItemOrAttachments,
  DocumentstListAttachments,
  DocumentstListItemModel,
  HistoricDocuments,
} from '../../../shared/models/documents-list-item.model';
import { DocumentListService } from './services/documents-list.service';
import { UtilService } from 'src/app/shared/util.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router } from '@angular/router';
import { CopyLinkModalComponent } from '../shared/copy-link-modal/copy-link-modal.component';
import { environment } from 'src/environments/environment';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { TopBreadcrumbService } from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import { finalize, Subscription, tap } from 'rxjs';

export interface Breadcrumb {
  label: string;
  id: number;
  userid: number;
}

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, AfterViewInit {
  @ViewChild('documentsTable', { static: false, read: ElementRef }) tableElement!: ElementRef;

  baseUrl: string = environment.apiUrl;

  isAddProjectVisible: boolean = false;
  value!: string;
  dataList: DocumentItemOrAttachments[] = [];
  documentsList: DocumentItemOrAttachments[] = [];
  documentsListOfProjectPage: DocumentItemOrAttachments[] = [];
  dataTableGroup: DocumentItemOrAttachments[] = [];
  temporayDocumentListOfAttachments!:
    | DocumentItemOrAttachments
    | HistoricDocuments;
  index = 0;
  levelGroup: boolean = false;
  complexDisabled: boolean = false;
  showBookmarksElements: boolean = false;

  checked = false;
  listOfData: DocumentItemOrAttachments[] = [];
  filterResponsible: [] = [];
  filterComplex: [] = [];
  filterByResponsible: string = '';
  filterByComplex: string = '';
  isExistFile = false;
  groupedBy: string = '';
  groupedTable: boolean = false;
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  checkedAttachmentInsideFolder = new Set<number>();
  setOfCheckedFolderId = new Set<number>();
  setOfCheckedSubFolderId = new Set<number>();
  listOfFolderHierarchy: number[] = [];
  listOfCurrentPageData: readonly DocumentItemOrAttachments[] = [];
  selectHistoric: HistoricDocuments[] = [];
  resizeQuadro: boolean = true

  enteredFolder: number
  screenWidth: number = 0;
  currentPage = 0;
  totalPages = 1;
  isLoading = false;

  listOfColumn = [
    {
      title: 'Projeto',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.name.localeCompare(b.name),
    },
    {
      title: 'Complexo',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.complexName.localeCompare(b.complexName),
    },
    {
      title: 'Data / Hora da última atualização',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.updateDate.localeCompare(b.updateDate),
    },
    {
      title: 'Data / Hora da criação',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.creationDate.localeCompare(b.creationDate),
    },
    {
      title: 'Responsável',
      compare: (a: DocumentstListItemModel, b: DocumentstListItemModel) =>
        a.user.name.localeCompare(b.user.name),
    },
  ];

  listOfColumnFiles = [
    {
      title: 'Arquivos',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.name.localeCompare(b.name),
      priority: 1,
    },
    {
      title: 'Versão',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        parseInt(a.version)
          .toString()
          .localeCompare(parseInt(b.version).toString()),
      priority: 2,
    },
    {
      title: 'Comentários',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.comments !== null && a.comments !== undefined
          ? a.comments.localeCompare(b.comments)
          : -1,
      priority: 3,
    },
    {
      title: 'Data/Hora da criação',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.date !== null && a.date !== undefined
          ? a.date.localeCompare(b.date)
          : -1,
      priority: 4,
    },
    {
      title: 'Responsável',
      compare: (a: DocumentstListAttachments, b: DocumentstListAttachments) =>
        a.user.name.localeCompare(b.user.name),
      priority: 5,
    },
  ];
  breadcrumb: Breadcrumb[] = [{ label: 'Todos', id: 0, userid: 0 }];
  documentListOriginal: DocumentItemOrAttachments[] = [];
  folderAttachments: DocumentstListAttachments[] | null = null;
  currentFolderId: number | null = null;
  userLogged: number;
  constructor(
    private modal: NzModalService,
    private readonly util: UtilService,
    private service: DocumentListService,
    private router: Router,
    private readonly viewContainerRef: ViewContainerRef,
    private topBreadcrumb: TopBreadcrumbService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {

    //RECUPERA ID DO USUARIO LOGADO
    let userLogged = sessionStorage.getItem("loggedUser")
    this.userLogged = userLogged ?  JSON.parse(userLogged).id : null;

    if(sessionStorage.getItem('documents')) {
      this.getDocumentListOfProjectPage();

    } else {
      this.getDocumentsList(0);
    }

    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Documentos',
      route: '/documents',
    });
  }

  ngAfterViewInit() {
    this.addScrollListener();
  }

  addScrollListener() {
    const scrollableDiv = this.tableElement.nativeElement.querySelector('.ant-table-body');

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', () => this.onScroll(scrollableDiv));
    }
  }

  getDocumentListOfProjectPage(){
    this.service.getDocumentsList(this.userLogged, 0, 100).subscribe((data) => {
      this.documentsListOfProjectPage = data.content;
      let verificaAcesso = (sessionStorage.getItem('documents'))
      let projectName = verificaAcesso ?  JSON.parse(verificaAcesso).projectName : null;
      let complexName = verificaAcesso ?  JSON.parse(verificaAcesso).complexName : null;
      const folderId = this.documentsListOfProjectPage.filter((data => data.complexName === complexName && data.name === projectName))
      this.service.getDocumentsListNivel(Number(folderId[0]['id']), Number(this.userLogged)).subscribe((data) => {
        let bread: Breadcrumb = {
          id: data.id,
          label: data.name,
          userid: data?.user?.id,
        };
        this.folderAttachments = data.attachments;
        this.levelGroup = bread.label === 'Todos' ? false : true;
        this.breadcrumb.find((data) => data.id === bread.id)
          ? null
          : this.breadcrumb.push(bread);
        if (Array.isArray(data.subfolders) && Array.isArray(data.attachments)) {
          this.documentsList = this.combineArrays(
            data.subfolders,
            data.attachments
          ).map((objeto) => ({ ...objeto, expand: false }));
          data.attachments.length === 0 && data.subfolders.length === 0
            ? (this.isExistFile = false)
            : (this.isExistFile = true);

          this.dataList = this.documentsList;
          this.listOfData = this.documentsList;
        }
      });
      sessionStorage.removeItem('documents')
    });
  }

  checkIfNameIsFromFile(name: string): boolean {
    const separetedName: string[] = name.split('.')
    if (separetedName !== null && separetedName.length > 0) {
      const extension: string = separetedName[separetedName.length - 1];

      return /^docx$|^doc$|^pdf$|^json$/.test(extension)
    } else {
      return false;
    }
  }

  updateCheckedFolderSet(id: number, checked: boolean): void {
    if (this.setOfCheckedSubFolderId.has(id)) {
      this.updateCheckedSubFolderSet(id, checked);
      return;
    }
    if (checked) {
      this.setOfCheckedFolderId.add(id);
    } else {
      this.setOfCheckedFolderId.delete(id);
    }
  }

  updateCheckedSubFolderSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedSubFolderId.add(id);
    } else {
      this.setOfCheckedSubFolderId.delete(id);
    }
  }

  updateCheckedAttachInFolderSet(id: number, checked: boolean): void {
    if (checked) {
      this.checkedAttachmentInsideFolder.add(id);
    } else {
      this.checkedAttachmentInsideFolder.delete(id);
      if (this.currentFolderId && this.setOfCheckedFolderId.has(this.currentFolderId)) {
        this.updateCheckedFolderSet(this.currentFolderId, false);
        if (this.folderAttachments) {
          let isFromFolder = this.folderAttachments.filter(attach => attach.id == id);
          if (isFromFolder.length > 0) {
            this.checkedAttachmentInsideFolder.forEach(attach => {
              this.updateCheckedSet(attach, true);
              this.updateCheckedAttachInFolderSet(attach, false);
            })
          }
        }
      }
    }
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(
    id: number,
    checked: boolean,
    data: DocumentItemOrAttachments | HistoricDocuments
  ): void {
    if (!data.entityName) {
      this.updateCheckedFolderSet(id, checked);
      this.getAttachmentsOfFolder(id, true, checked);
    } else {
      this.updateCheckedSet(id, checked);
      this.updateCheckedAttachInFolderSet(id, checked);
    }
    if (!checked) {
      this.removeParentFoldersCheck();
    }
    this.temporayDocumentListOfAttachments = data;
    this.refreshCheckedStatus();
  }

  private removeParentFoldersCheck() {
    this.listOfFolderHierarchy.forEach(folderIndex => {
      if (this.setOfCheckedFolderId.has(folderIndex)) {
        this.updateCheckedFolderSet(folderIndex, false);
      }

      if (this.setOfCheckedSubFolderId.has(folderIndex)) {
        this.updateCheckedFolderSet(folderIndex, false);
      }
    })
  }

  onItemCheckedInput(data: {
    id: number;
    checked: boolean;
    data: DocumentItemOrAttachments | HistoricDocuments;
  }) {
    this.temporayDocumentListOfAttachments = data.data;
    this.updateCheckedSet(data.id, data.checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach((item) =>
      this.updateCheckedSet(item.id, value)
    );
    this.refreshCheckedStatus();
  }

  onCurrentPageDataChange(
    $event: readonly DocumentItemOrAttachments[]
  ): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every((item) =>
      this.setOfCheckedId.has(item.id) || this.setOfCheckedFolderId.has(item.id)
    );
    this.indeterminate =
      this.listOfCurrentPageData.some((item) =>
        this.setOfCheckedId.has(item.id)
      ) && !this.checked;

    if (this.checked) {
      if (this.listOfFolderHierarchy.length > 0) {
        this.updateCheckedFolderSet(this.listOfFolderHierarchy[this.listOfFolderHierarchy.length - 1], true);

        this.listOfCurrentPageData.forEach((item) => {
          if (!item.entityName) {
            this.setOfCheckedSubFolderId.add(item.id);
            this.updateCheckedFolderSet(item.id, false);
            this.getAttachmentsOfFolder(item.id, true, true);
          } else {
            this.checkedAttachmentInsideFolder.add(item.id);
            this.updateCheckedSet(item.id, false);
          }
        })

      }
    }
  }

  filterByGroupElements() {
    if (!this.groupedBy || this.groupedBy === null) {
      this.dataList = this.documentsList;
      this.groupedTable = false;
      return;
    }
    this.filterByComplex = '';
    this.filterByResponsible = '';
    this.getDocumentsList(0);
    this.dataTableGroup = this.dataList;
    const groupedArray = this.groupBy(this.dataList, this.groupedBy);
    this.dataTableGroup = groupedArray;
    this.groupedTable = true;
  }

  groupBy(array, key) {
    return array.reduce((hash, obj) => {
      if (key === 'user') {
        return Object.assign(hash, {
          [obj.user.name]: (hash[obj.user.name] || []).concat(obj),
        });
      } else {
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }
    }, {});
  }

  filterByResponsibleElements() {
    if (!this.filterByResponsible || this.filterByResponsible === null) {
      this.dataList = this.documentsList;
      return;
    }
    this.filterByComplex = '';
    this.groupedTable = false;
    this.dataList = this.documentsList.filter(
      (result) => result.user.name === this.filterByResponsible
    );
  }

  filterByComplexElements() {
    if (!this.filterByComplex || this.filterByComplex === null) {
      this.dataList = this.documentsList;
      return;
    }
    this.filterByResponsible = '';
    this.groupedTable = false;
    this.dataList = this.documentListOriginal.filter(
      (result) => result.complexName === this.filterByComplex
    );
  }

  private getDocumentsList(page: number) {
    if (this.isLoading || this.currentPage >= this.totalPages  || this.currentFolderId) return;
    this.isLoading = true;

    this.service.getDocumentsList(this.userLogged, page, 10).pipe(
      tap((response) => {
        this.totalPages = response.totalPages;
        this.documentsList = [...this.documentsList, ...response.content];
        this.dataList = this.documentsList;
      }),
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

  getAttachmentsOfFolder(folderId, bookmarkAttachments: boolean = false, checked: boolean = true): Subscription {
    return this.service.getDocumentsListNivel(Number(folderId), Number(this.userLogged)).subscribe({
      next: (data) => {
        if (data) {
          this.folderAttachments = data.attachments;
          if (bookmarkAttachments) {
            this.folderAttachments.forEach(attachament => {
              this.updateCheckedAttachInFolderSet(attachament.id, checked);
            })
          }
          if (data.subfolders && data.subfolders.length > 0) {
            data.subfolders.forEach(subFolder => {
              this.updateCheckedSubFolderSet(subFolder.id, checked);
              this.getAttachmentsOfFolder(subFolder.id, true, checked);
            })
          }
        } else {
          this.folderAttachments = null;
        }
      }
    })
  }

  getEnterFolder(
    pasta: DocumentItemOrAttachments | Breadcrumb,
    complexName
  ) {
    this.listOfFolderHierarchy.push(pasta?.id);
    this.currentFolderId = Number(pasta?.id);
    const newSearch = pasta?.id ? pasta?.id : pasta;

    this.service.getDocumentsListNivel(Number(newSearch), Number(this.userLogged)).subscribe((data) => {
      let bread: Breadcrumb = {
        id: data.id,
        label: data.name,
        userid: data?.user?.id,
      };
      this.enteredFolder = Number(newSearch)
      this.folderAttachments = data.attachments;
      this.levelGroup = bread.label === 'Todos' ? false : true;
      if (bread.label === 'Todos') {
        this.filterByComplexElements;
        this.getFilterResponsible();
        this.filterByResponsible = '';
        this.filterByComplex = '';
        this.groupedBy = '';
      }
      this.filterByResponsible = '';
      this.filterByComplex = '';
      this.breadcrumb.find((data) => data.id === bread.id)
        ? null
        : this.breadcrumb.push(bread);
      if (Array.isArray(data.subfolders) && Array.isArray(data.attachments)) {
        this.documentsList = this.combineArrays(
          data.subfolders,
          data.attachments
        ).map((objeto) => ({ ...objeto, expand: false }));
        data.attachments.length === 0 && data.subfolders.length === 0
          ? (this.isExistFile = false)
          : (this.isExistFile = true);

          setTimeout(() => {
            this.dataList = this.documentsList;
            this.listOfData = this.documentsList;
          }, 100);
      }
      this.filterByVersion();
      this.filterByGroupElements;
      this.getFilterResponsible();
      this.getFilterComplex();
    });
  }

  filterByVersion() {
      this.dataList.forEach((element, i) => {
        let sameDocs = this.dataList.filter(doc => doc.name === element.name)
         if(sameDocs.length > 1){
           let maxVersion = 0;
            sameDocs.forEach(element => {
              if(element.version && +element.version > maxVersion){
                maxVersion = +element.version;
              }
            })
            sameDocs.forEach(element => {
              if(element.version && +element.version < maxVersion){
                this.dataList.splice(this.dataList.indexOf(element), 1);
              }
            })
         }
      })
  }

  private combineArrays(
    files: DocumentstListItemModel[],
    attachements: DocumentstListAttachments[]
  ): DocumentItemOrAttachments[] {
    const combinedList: DocumentItemOrAttachments[] = [
      ...files,
      ...attachements,
    ];
    return combinedList;
  }

  private getFilterResponsible() {
    let json_data = this.dataList;
    let arrayFilter = Object.values(json_data);
    let uniqueChars: any = [];
    arrayFilter.forEach((element) => {
      if (!uniqueChars.includes(element.user.name)) {
        uniqueChars.push(element.user.name);
      }
    });
    this.filterResponsible = uniqueChars;
  }

  private getFilterComplex() {
    let json_data = this.dataList;
    let arrayFilter = Object.values(json_data);
    let uniqueChars: any = [];
    arrayFilter.forEach((element) => {
      if (!uniqueChars.includes(element.complexName)) {
        uniqueChars.push(element.complexName);
      }
    });
    this.filterComplex = uniqueChars.length === 1 ? null : uniqueChars;
    this.complexDisabled = uniqueChars.length === 1 ? true : false;
  }

  searchFilter(): void {
    this.dataList = this.documentsList.filter((element) => {
      const name = element.name || '';
      const complex = element.complexName || '';
      const userName = element.user?.name || '';
      const creationDate = element.creationDate || '';
      const updateDate = element.updateDate || '';
      const version = element.version || '';

      return (
        name.toLowerCase().includes(this.value.toLowerCase()) ||
        complex.toLowerCase().includes(this.value.toLowerCase()) ||
        userName.toLowerCase().includes(this.value.toLowerCase()) ||
        creationDate.toLowerCase().includes(this.value.toLowerCase()) ||
        updateDate.toLowerCase().includes(this.value.toLowerCase())
      );
    });
  }

  showAddProjectModal(): void {
    this.isAddProjectVisible = true;
  }

  getIconFromFileName(filename: string) {
    return this.util.getIconFromFileName(filename);
  }

  handleError() {
    this.modal.error({
      nzTitle: 'Erro ao realizar download dos arquivos',
    });
  }

  downloadFile(data: Blob, name: string) {
    const blobs = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blobs);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove;
  }

  getBreadcrumb(test: Breadcrumb, index: number) {
    if (test.label === 'Todos') {
      this.currentFolderId = null;
      this.levelGroup = false;
      this.filterByComplexElements;
      this.getFilterResponsible();
      this.setOfCheckedFolderId = new Set();
      this.filterByResponsible = '';
      this.filterByComplex = '';
      this.groupedBy = '';
      this.listOfFolderHierarchy = []
    }

    if (index === this.breadcrumb.length - 1) {
      return;
    }
    if (index <= this.breadcrumb.length - 1) {
      this.breadcrumb.splice(index + 1);
    }

    if (index === 0) {
      this.getDocumentsList(0);
      return;
    } else {
      this.getEnterFolder(test, '');
    }

    this.listOfFolderHierarchy = this.listOfFolderHierarchy.slice(0, index);
  }

  downloadList() {
    const ids = Array.from(this.setOfCheckedId);
    const folderIds = Array.from(this.setOfCheckedFolderId);
    let name;
    if(this.temporayDocumentListOfAttachments){
      let ext = this.temporayDocumentListOfAttachments.name.split('.');
      if(ext[1] && ext[1].length >= 3 && ids.length === 1){
        name = this.temporayDocumentListOfAttachments.name
      } else {
        name = (this.temporayDocumentListOfAttachments.name + '.zip');
      }
    } else {
      name = 'documentos.zip';
    }
    if (ids.length >= 1 && folderIds.length === 0) {
      this.service.downloads(ids).subscribe({
         next: (resp) => this.downloadFile(resp, name),
         error: () => this.handleError(),
       });
    }
    if (folderIds.length > 0) {
      this.service.downloadFolders(folderIds).subscribe({
        next: (resp) => this.downloadFile(resp, name),
        error: () => this.handleError(),
      });
    }
  }

  getHistoric(document: DocumentItemOrAttachments) {
    const entityId = document.entityId || 0;
    const entityName = document.entityName || '';
    const name = document.name || '';
    this.service.getHistoric(entityId, entityName, name).subscribe((data) => {
      this.selectHistoric = data;
    });
  }

  handleCheckClick(dataTaDocumentTabled: any) {
    this.getEnterFolder(dataTaDocumentTabled, '');
    this.groupedTable = false;
  }

  bookmarkedError() {
    this.modal.error({
      nzTitle: 'Erro ao favoritar',
    });
  }

  showBookmarks() {
    this.showBookmarksElements = !this.showBookmarksElements;
    if (this.showBookmarksElements) {
      this.dataList = this.documentsList.filter((element) => {
        return element.isBookmarked === true;
      });
    }
    if (!this.showBookmarksElements) {
      this.dataList = this.documentsList;
    }
  }

  makeBookmark(userid: number, attachmentId: number) {
    this.service.setBookmarkedFile(this.userLogged, attachmentId).subscribe(
      (result) => {
        this.documentsList.forEach((element, i) => {
          if (element.id === attachmentId) {
            this.documentsList[i].isBookmarked = true;
          }
        });
      },
      (error) => {
        this.bookmarkedError();
      }
    );
  }

  makeBookmarkFolder(userid: number, folderId: number) {
    const favorite = true
    this.service.setBookmarkedFolder(this.userLogged, folderId, favorite).subscribe(
      (result) => {
        this.documentsList.forEach((element, i) => {
          if (element.id === folderId) {
            this.documentsList[i].isBookmarked = true;
          }
        });
      },
      (error) => {
        this.bookmarkedError();
      }
    );
  }

  // REMOVER FAVORITO
  unmakeBookmark(userid: number, attachmentId: number) {
    this.service.setBookmarkedFile(this.userLogged, attachmentId).subscribe(
      (result) => {
        this.documentsList.forEach((element, i) => {
          if (element.id === attachmentId) {
            this.documentsList[i].isBookmarked = !this.documentsList[i].isBookmarked;
          }
        });
      },
      (error) => {
        this.bookmarkedError();
      }
    );
  }

  unmakeBookmarkFolder(userid: number, folderId: number) {
    const favorite = false
    this.service.setBookmarkedFolder(this.userLogged, folderId, favorite).subscribe(
      (result) => {
        this.documentsList.forEach((element, i) => {
          if (element.id === folderId) {
            this.documentsList[i].isBookmarked = false;
          }
        });
      },
      (error) => {
        this.bookmarkedError();
      }
    );
  }

  accessFlow(attachmentId: number) {
    this.service.getUrlFlux(attachmentId).subscribe(
      (result) => {
        this.router.navigate([result]);
              },
      () => {
        this.accessError();
      }
    );
  }

  accessError() {
    this.modal.error({
      nzTitle: 'Erro ao acessar',
    });
  }

  copyLink(attachmentId) {
    this.service.getUrlFlux(attachmentId).subscribe(
      (result) => {
        if (result === '') {
          this.accessError();
        } else {
          const urlRoot = window.location.origin;
          let urlAttachment = `${urlRoot}${result}`;
          this.openModalCopyLink(attachmentId, urlAttachment);
        }
      },
      () => {
        this.accessError();
      }
    );
  }

  openModalCopyLink(attachmentId, attachmentUrl) {
    let modal = this.modal.create<CopyLinkModalComponent>({
      nzTitle: 'Copiar Link',
      nzWidth: 600,
      nzContent: CopyLinkModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCentered: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
      },
    });

    const instance = modal.getContentComponent();
    instance.fileId = attachmentId;
    instance.fileName = attachmentUrl;

    modal.afterClose.subscribe(() => {
      modal.destroy();
    });
  }

  refreshDisplay(idDeleteFile){
    this.dataList = this.dataList.filter(objeto => objeto.id !== idDeleteFile)
  }

  refreshDisplayAfterRestore(enteredFolder){
    setTimeout(() => {
      this.service.getDocumentsListNivel(Number(enteredFolder), Number(this.userLogged)).subscribe((data: any) => {
        let bread: Breadcrumb = {
          id: data.id,
          label: data.name,
          userid: data?.user?.id,
        };
        this.breadcrumb.find((data) => data.id === bread.id)
          ? null
          : this.breadcrumb.push(bread);
        this.folderAttachments = data.attachments;
        if (Array.isArray(data.subfolders) && Array.isArray(data.attachments)) {
          this.documentsList = this.combineArrays(
            data.subfolders,
            data.attachments
          ).map((objeto) => ({ ...objeto, expand: false }));
          this.dataList = this.documentsList;
          this.listOfData = this.documentsList;
          this.cdr.detectChanges()
        }
      })
    }, 100);
  }

  returnHeightOnResolution(): string {
    let height = window.innerHeight;
    if(height > 900){
      return '60vh';
    } else if (height > 600){
      return '50vh';
    } else {
      return '40vh';
    }
  }

  onScroll(scrollableDiv: HTMLElement) {
    if (this.isLoading || this.currentPage >= this.totalPages  || this.currentFolderId) return;
    const isBottom = Math.abs(scrollableDiv.scrollHeight - scrollableDiv.scrollTop - scrollableDiv.clientHeight) < 1;
    if (isBottom) {
      this.currentPage++;
      this.getDocumentsList(this.currentPage);
    }
  }
}
