<ng-template #welcome>
    <div class="font-1">Olá! Esta é a ferramenta de análise de estudos do Smart Licensing!</div>

    <div  class="font-2">
        Para iniciar a sua análise, siga as etapas a seguir:<br><br>

    1- Clique no botão abaixo para fazer o upload dos arquivos do estudo e cadastrar informações complementares;<br><br>

    2- Cadastre as sessões do Termo de Referência ou carregue uma versão pré-cadastrada, conforme instruções ao lado;<br><br>

    3- Em seguida, acesse a aba ”Análise dos Resultados” para verificar o feedback da análise realizada pela ferramenta.
    </div>

    <button nz-button class="buttonClass" [nzSize]="size" nzShape="round" (click)="showAddProjInformationsModal()">
        + Informações para análise
    </button>
    <nz-divider></nz-divider>
    <button nzType="primary" (click)="close()" nz-button>Confirm</button>
</ng-template>

<ng-template #content>
      <app-card-sidebar [generalInformation]="projectInfo" [anexo]="anexo" [reportsFiles]="reportsSaved"></app-card-sidebar>
</ng-template>

<ng-template [ngTemplateOutlet]="isDataEmpty ? welcome : content"></ng-template>
