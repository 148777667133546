import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { NlpComponent } from "./modules/nlp/nlp.component";
import { TasksComponent } from './modules/tasks/tasks.component';
import { DocumentsComponent } from './modules/documents/documents.component';
import { DashboardsComponent } from './modules/dashboards/dashboards.component';
import { NlpReportComponent } from "./modules/nlp/nlp-report/nlp-report.component";
import { NlpWelcomeComponent } from './modules/nlp/nlp-welcome/nlp-welcome.component';
import { ProductsListComponent } from './modules/nlp/products-list/products-list.component';
import { ProjectV2SuspensionComponent } from './modules/projects-v2/project-v2-suspension/project-v2-suspension.component';
import { NlpReferenceTermComponent } from "./modules/nlp/nlp-reference-term/nlp-reference-term.component";
import { NlpAnalysisResultComponent } from "./modules/nlp/nlp-analysis-result/nlp-analysis-result.component";
import { HelpCenterComponent } from "./modules/help-center/help-center.component";
import {AuthGuard} from "./core/auth/guards/auth.guard";
import { LicensingProcessComponent } from './modules/projects-v2/licensing-process/licensing-process.component';
import { RoutesEnum } from './shared/enum/routes.enum';



const routes: Routes = [
  { path: '', redirectTo: `/${RoutesEnum.TASKS}`, pathMatch: 'full' },
  {
    path: RoutesEnum.DASHBOARDS_TYPE, canActivate: [AuthGuard],
    component: DashboardsComponent,
  },
  {
    path: RoutesEnum.TASKS, canActivate: [AuthGuard],
    component: TasksComponent,
  },
  {
    path: RoutesEnum.SUSPENSION, canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: RoutesEnum.CANCELLATION, canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: 'licensing-process/:projectId',
    component: LicensingProcessComponent,
  },
  {
    path: RoutesEnum.NLP, canActivate: [AuthGuard],
    component: NlpComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      {
        path: 'welcome',
        component: NlpWelcomeComponent,
      },
      {
        path: 'termo-referencia',
        component: NlpReferenceTermComponent,
      },
      {
        path: 'analise-resultados',
        component: NlpAnalysisResultComponent,
      },
      {
        path: 'relatorio',
        component: NlpReportComponent,
      },
      {
        path: 'list',
        component: ProductsListComponent,
      },
    ],
  },
  {
    path: RoutesEnum.DOCUMENTS, canActivate: [AuthGuard],
    component: DocumentsComponent,
    children: [{ path: '', redirectTo: RoutesEnum.DOCUMENTS, pathMatch: 'full' }],
  },
  {
    path: RoutesEnum.PROJECT,
    loadChildren: () => import('./modules/projects-v2/projects.module').then((m) => m.ProjectsModule),
  },
  {
    path: RoutesEnum.COMMUNICATION,
    loadChildren: () => import('./modules/communications/communications.module').then((m) => m.CommunicationsModule),
  },
  {
    path: RoutesEnum.SCOPE_CHANGE, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/scope-change/scope-change.module').then((module) => module.ScopeChangeModule)
  },
  {
    path: RoutesEnum.SMART_DOCS, canActivate: [AuthGuard],
    loadChildren: () => import('./modules/smart-docs/smart-docs.module').then((module) => module.SmartDocsModule)
  },
  {
    path: RoutesEnum.HELP_CENTER, canActivate: [AuthGuard],
    component: HelpCenterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }
